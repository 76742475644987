import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { StyledButton, Search, Pagination, Spinner, Icon } from '../../components/UI';
import PageHeader from '../../components/PageHeader';

import { stageName, requestTypeName, ticketStatus } from '../../additional';
import UserListHeader from '../../components/user/UserListHeader';
import UserListItem from '../../components/user/UserListItem';

import { fetchUsers } from '../../store/userSlice';
import { getTicketsForDistribution, saveTicketsResponsibele } from '../../store/ticketSlice';

const DistributionPage = () => {
    const dispatch = useDispatch();
    const { list: users } = useSelector(state => state.users);
    const { list: tickets } = useSelector(state => state.tickets);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const downloadData = async () => {
            await dispatch(fetchUsers());
            await dispatch(getTicketsForDistribution());
        };
        setError(null);
        setIsLoading(true);

        downloadData()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setError("Ошибка загрузки данных для распределения!");
                setIsLoading(false);
            });

    }, []);

    const onSelectHandler = (item) => {
        if (selectedTickets.find(x => x.id === item.id)) {
            setSelectedTickets(state => {
                const newState = state.filter(x => x.id !== item.id);
                return newState;
            });
        } else {
            setSelectedTickets(state => {
                let newState = [...state];
                newState.push(item);
                return newState;
            });
        };
    };

    const setSelectedUserHandler = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            setSelectedUser(null);
        } else {
            setSelectedUser(user);
        }
    };

    const setTicketsResponsibleHandler = () => {
        setIsLoading(true);
        dispatch(
            saveTicketsResponsibele({
                userId: selectedUser.id, list: selectedTickets.map(item => {
                    return { clientId: item.clientId, ticketId: item.id }
                })
            })
        ).then(() => {
            dispatch(getTicketsForDistribution()).then(() => {
                dispatch(fetchUsers());
                setShowModal(false);
                setSelectedTickets([]);
                setSelectedUser(null);
                setIsLoading(false);
            });   
        }).catch(() => {
            setIsLoading(false);
        });

    };
    //console.log("distribution page ", tickets);
    return (
        <div>
            <PageHeader
                name="Распределение заявок"
            >
                {selectedTickets.length > 0 &&
                    <StyledButton name=<div className='icon-text'><Icon name='pencil' />Назначить ответственного</div> onClickHandler={() => setShowModal(!showModal)} />}
            </PageHeader>
            <div className="ticket-table-container">
            {isLoading === true
                ?
                <Spinner />
                :
                <>
                    <div className="ticket-table-header">
                        <div className='id'>ID</div>
                        <div className='type'>Вид запроса</div>
                        <div className='date-created'>Дата запроса</div>
                        <div className='description'>Описание</div>
                        <div className='status'>Статус</div>
                        <div className='user'>Ответственный</div>
                    </div>
                    {tickets && tickets.map((item) => {
                        const { id, requestTypeId, dateCreated, ticketStage, description, responsible } = item;
                        //console.log(item);
                        return (
                            <div
                                className={"ticket-table-row " + (selectedTickets?.find(x => x.id === id) ? "selected" : "")}
                                onClick={() => onSelectHandler(item)}
                                //onDoubleClick={() => onEditHandler(item)}
                                key={id}
                            >
                                <div className='id'>{id}</div>
                                <div className='type'>{requestTypeName(requestTypeId)}</div>
                                <div className='date-created'>{(new Date(dateCreated)).toLocaleDateString("ru-RU")}</div>
                                <div className='description'>{description}</div>
                                <div className='status'>{ticketStatus(ticketStage)}</div>
                                <div className='user'>{responsible ? (responsible.name?.length > 0 || responsible.surname?.length > 0) ? (responsible.name + " " + responsible.surname) : responsible.email : 'Нет'}</div>
                            </div>
                        );
                    })}
                    <Pagination
                        //totalRecords={10}
                        pageLimit={25}
                        currentPage={currentPage}
                        pageNeighbours={5}
                        totalPages={100}
                        onPageChanged={setCurrentPage}
                    />
                </>
            }
            </div>
            <Modal
                className={"modal-window"}
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                //style={{position: "relative"}}
            >
                <ModalHeader className={"modal-header"} toggle={() => setShowModal(false)}>
                    Выберите ответственного
                </ModalHeader>
                <ModalBody>
                    <div className="distribution-users-header">
                        <div className="distribution-user-name">Менеджер</div>
                        <div className="distribution-user-counter">Кол-во в работе</div>
                    </div>
                    {users.map((user) => {
                        return (
                            <div className={"distribution-users-row " + (user.id === selectedUser?.id ? "selected" : "" )} key={user.id} onClick={() => setSelectedUserHandler(user)}>
                                <div className='distribution-user-name'>{(user.name?.length > 0 || user.surname?.length > 0) ? (user.name + " " + user.surname) : user.email}</div>
                                <div className='distribution-user-counter'>{user.ticketsInWorkCount}</div>
                            </div>
                        )
                    }) }
                </ModalBody>
                <ModalFooter>
                    <div style={{ position: "relative", display: "flex", flexDirection: "row" }}>
                        {selectedUser && !isLoading && <StyledButton name="Назначить" onClickHandler={setTicketsResponsibleHandler} />}
                        {isLoading && < Spinner />}
                    </div>

                    <StyledButton style="danger" name="Отмена" onClickHandler={() => {
                        setSelectedUser(null);
                        setShowModal(false);
                    }} />
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DistributionPage;

            //<UserListHeader />
            //{list.lenght > 0
            //    ?
            //    list.map(user => {
            //        return (
            //            <UserListItem />
            //        )
            //    })
            //    :
            //}