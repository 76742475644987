import React, { useState, useEffect, useCallback } from 'react';
import { Input, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import password from 'secure-random-password';

import { StyledButton } from '../../components/UI';


const RegionItemModal = ({ region = null, showModal, setShowModal, onSaveChangesHandler = () => { } }) => {

    const initialState = {
        id: null,
        name: ''
    };

    const [regionItem, setRegionItem] = useState(initialState);
    const isEditMode = region ? true : false;

    useEffect(() => {
        setRegionItem(region ? region : { ...initialState });
    }, [region]);

    const onChangeNameHandler = ({ value }) => {
        setRegionItem(state => {
            const newState = { ...state };
            newState.name = value;
            return newState;
        });
    };

    const onSaveHandler = () => {
        onSaveChangesHandler(regionItem);
        setRegionItem(initialState);
        setShowModal(false);

    };

    const onCancelHandler = () => {
        setRegionItem({ ...initialState });
        setShowModal(false);
    };

    return (
        <Modal
            className={"modal-window"}
            isOpen={showModal}
            toggle={() => setShowModal(false)}
        >
            <ModalHeader toggle={() => setShowModal(false)}>
                {isEditMode ? "Редактировать регион" : "Добавить регион"}
            </ModalHeader>
            <ModalBody>

                <div className="modal-row">
                    <div className='row-name'>Наименование региона:</div>
                    <div className='row-input'><Input value={regionItem.name} onChange={e => onChangeNameHandler(e.target)} /></div>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={onSaveHandler}
                >
                    Сохранить
                    </Button>
                {' '}
                <Button onClick={onCancelHandler}>
                    Отменить
                    </Button>
            </ModalFooter>
        </Modal>
    );
};

export default RegionItemModal;