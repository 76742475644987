import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import PageHeader from '../components/PageHeader';
import { UserListHeader, UserListItem, UserItemModal } from '../components/user/index';
import { Spinner, StyledButton } from '../components/UI';
import { Icon } from '../components/UI';
import { fetchUsers, addUser, updateUser, deleteUser } from '../store/userSlice';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';

const UsersPage = () => {
    const dispatch = useDispatch();
    const { list, isLoading, error } = useSelector(state => state.users);
    const { user } = useSelector(state => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [userForEdit, setUserForEdit] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemForDelete, setItemForDelete] = useState(null); //{entityId: 12}
    const [isAdmin, setIsAdmin] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (user) => {
        if (selectedItem && selectedItem === user) {
            setSelectedItem(null);
        } else {
            setSelectedItem(user);
        };
    };

    useEffect(() => {
        //console.log(user);
        if (user) {
            const { accessToken } = user;
            const decoded = jwt_decode(accessToken);
            const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? 0 : 1;
            setIsAdmin(parseInt(role));
        }
    }, [user]);


    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    const onAddUserHandler = (user) => {
        setUserForEdit(null);
        setShowModal(true);
    };

    const onShowDeleteModalHandler = (id) => {
        setItemForDelete({ entityName: list.find(p => p.id === id).email, entityId: id })
        setShowDeleteModal(true);
    }

    const onDeleteHandler = (request) => {
        if (request?.answer === true)
            dispatch(deleteUser(request?.entityId));
    };

    const onEditHandler = (userId) => {
        const selectedUser = list.find(user => user.id === userId);
        setUserForEdit({ ...selectedUser });
        setShowModal(true);
    };

    const onSaveChangesHandler = (userItem) => {
        if (userItem.id === null) {
            dispatch(addUser(userItem));
        } else {
            dispatch(updateUser(userItem));
        };
        setUserForEdit(null);
    };

    return (
        <>
            <div className='banks-container'>
                <div className='container-header'>
                    <div className='container-header-name'>Справочник пользователей</div>
                    {/* {!isLoading && <div className='container-button'><StyledButton name=<div className='plus-add-name '><Icon name = "plus"/>Добавить</div> onClickHandler={onAddUserHandler} /></div>} */}
                    {selectedItem ?
                    <>
                    <div className='container-btn'>
                        <StyledButton className={'btn-red'} name= <div className='icon-text'><Icon name = 'delete'/>Удалить</div> onClickHandler={() => onShowDeleteModalHandler(selectedItem)}/>
                        <StyledButton name= <div className='icon-text'><Icon name = 'pencil'/>Редактировать</div> onClickHandler={() => onEditHandler(selectedItem)} />
                        </div>
                    </>
                    
                    :
                    <>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div>onClickHandler={onAddUserHandler}/>
                    </>
                }
                </div>
                <div className='banks-table'>
                    {isLoading && <Spinner />}

                    <UserListHeader />

                    {list.length > 0 ?
                        list.map(user => {
                            const { id } = user;

                            return (
                                <UserListItem
                                    key={id}
                                    user={user}
                                    selectedItem={selectedItem}
                                    //setSelectedItem={setSelectedItem}
                                    onArchiveHandler={onShowDeleteModalHandler}
                                    onEditHandler={onEditHandler}
                                    //onClick={() => onSelectHandler(selectedItem)}
                                    onSelectHandler={onSelectHandler}
                                />
                            );
                        })
                        :
                        <div className='no-items'>Список пока пуст.</div>
                    }
                </div>
            </div>
            <UserItemModal
                setShowModal={setShowModal}
                showModal={showModal}
                user={userForEdit}
                onSaveChangesHandler={onSaveChangesHandler}
            />
            <ConfirmDeleteModal
                entityName={'пльзователя "' + itemForDelete?.entityName + '"'}
                entityId={itemForDelete?.entityId}
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                onDeleteHandler={onDeleteHandler}
            />
        </>
    );
};

export default UsersPage;

