import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { StyledButton, Search } from '../../components/UI';
import { Pagination } from '../../components/UI';

import { fetchClients } from '../../store/clientSlice';

import './ClientsPage.modules.css';

const ClientsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const { list } = useSelector(store => store.clients);

    useEffect(() => {
        //console.log(filter);
        //console.log('FILTER CHANGED',{ filter: { ...filter.filterValue, searchText: filter.search } });
        //Загрузка списка тикетов при первом открытии страницы
        //дальше данные будут обновляться через сокеты
        dispatch(fetchClients());
    }, []);
    //console.log(list);
    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    const onEditHandler = (item) => {
        navigate(`/client/${item.id}`, item);
    };

    return (
        <>
            <PageHeader
                name="Клиенты"
                //showSearch
                //showFilter
            >
                {selectedItem ?
                    <>
                        <StyledButton name="Редактровать" onClickHandler={() => onEditHandler(selectedItem)} />
                    </>
                    :
                    <>
                        <StyledButton name="Добавить" />
                    </>
                }
            </PageHeader>
            <div className="ticket-table-container">
                <div className="ticket-table-header">
                    <div className='id-clients'>ID</div>
                    <div className='client-fio'>ФИО</div>
                    <div className='client-username'>Имя пользователя</div>
                    <div className='client-phone'>Номер телефона</div>
                    <div className='client-tickets'>Кол-во тикетов</div>
                    <div className='client-unread'>Новые сообщения</div>
                </div>
                {list && list.map((item) => {
                    const { id, firstName, lastName, username, phone, friendsCount, ticketsCount, insurancesCount, unreadMessages } = item;
                   
                    return (
                        <div
                            className={"ticket-table-row " + (selectedItem && selectedItem.id === id ? "selected" : "")}
                            onClick={() => onSelectHandler(item)}
                            onDoubleClick={() => onEditHandler(item)}
                            key={id}
                        >
                            <div className='id-clients'>{id}</div>
                            <div className='client-fio'>{firstName} {lastName}</div>
                            <div className='client-username'>{username}</div>
                            <div className='client-phone'>{phone}</div>
                            <div className='client-tickets'>{ticketsCount}</div>
                            <div className='client-unread'>{unreadMessages > 0 && unreadMessages}</div>
                        </div>
                    )
                })}
                <Pagination
                    //totalRecords={10}
                    pageLimit={25}
                    currentPage={currentPage}
                    pageNeighbours={5}
                    totalPages={100}
                    onPageChanged={setCurrentPage}
                />
            </div>
        </>
    );
};

export default ClientsPage;