import React, { useState, createContext, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavMenu } from '../';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

import { fetchUsers } from '../../store/userSlice';
import { sendMessage } from '../../store/signalSlice';

import { fetchDictionaries } from '../../store/dictionarySlice';
//import { connection, userData } from '../../helpers/withSignalR';
import { connection } from '../../store/index.js';
import './Layout.modules.css';
//import '../../custom.css';
import { HubConnectionState } from "@microsoft/signalr";

//import { connection } from "../redux/helpers/createSignalMiddleware";
//require('@microsoft/signalr')

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();
    const [collapsed, setCollapsed] = useState(true);
    const { user } = useSelector(state => state.auth);
    //const [connection, setConnection] = useState(null);

    useEffect(() => {
        //console.log(user.accessToken);
        //userData = "Test";
        if (connection.state !== HubConnectionState.Connected) {
            connection

                .start(user)
                .then(() => console.log("Connection started"))
                .catch((err) => console.error(err.toString()));
        }
    }, [connection]);
    //let connection = new HubConnectionBuilder()
    //    .withUrl("/hubs/notifications")
    //    .build();

    //connection.on("send", data => {
    //    console.log(data);
    //});

    //connection.start()
    //    .then(() => connection.invoke("send", "Hello"));
    //useEffect(() => {
    //    const setupConnection = async () => {

    //        await dispatch(checkAuth(user));

    //    };
    //    setupConnection();
    //}, [dispatch]);

    //useEffect(() => {
    //    //console.log(user?.accessToken);
    //    if (user?.accessToken != "" && user?.accessToken !== null) {
    //        const connect = new HubConnectionBuilder()
    //            .withUrl(`https://localhost:44412/hubs/notifications`, { accessTokenFactory: () => user?.accessToken })
    //            .withAutomaticReconnect()
    //            .build();

    //        console.log("connect");
    //        setConnection(connect);
    //    }

    //}, [user]);

    //useEffect(() => {
    //    if (connection) {
    //        console.log("connected");
    //        connection
    //            .start()
    //            .then(() => {
    //                connection.on("ReceiveMessage", (message) => {
    //                    console.log("recived", message);
    //                    console.log(message)
    //                    //message.open({
    //                    //    message: "New Notification",
    //                    //    description: message,
    //                    //});
    //                });
    //                connection.on("Send", (message) => {
    //                    console.log("recived", message);
    //                    console.log(message)
    //                    //message.open({
    //                    //    message: "New Notification",
    //                    //    description: message,
    //                    //});
    //                });
    //            })
    //            .catch((error) => console.log(error));
    //    }
    //}, [connection]);

    //console.log(connection?.state);
    //const sendMessage = () => {
    //    console.log(connection.state);

    //    if (connection) {
    //        connection.send("Send", "kjghdfkgh");
    //        console.log("sended");

    //    };
    //    //setInputText("");
    //};


    useEffect(() => {
        //dispatch(signalActions.startConnecting());
        dispatch(fetchUsers());
        dispatch(fetchDictionaries());
        
    }, []);
    //console.log(connection);

    return (
        <div className='layout' style={{ height: height, maxHeight: height }}>
            <NavMenu collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={'page-container ' + (!collapsed ? 'collapsed' : '')} style={{ maxHeight: height }}>
                {/*<button onClick={async () => {*/}
                {/*    //console.log("send message");*/}
                {/*    await connection.send("Send", "HELLO")*/}
                {/*    //dispatch(sendMessage("============"));*/}
                {/*}}>test</button>*/}
                {children}

            </div>
        </div>
    );
};

export default Layout;