import React, { useState, useEffect } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';

import BanksPage from './BanksPage';
import InsuranceCompaniesPage from './InsuranceCompaniesPage';
import UsersPage from './UsersPage';

const AdminPage = () => {
    const { pathname } = useLocation();
    //const [selectedSection, setSelectedSection] = useState(pathname);

    //useEffect(() => {
    //    setSelectedSection(pathname);
    //}, [pathname]);

    const PageComponent = () => {
        switch (pathname) {
            case '/settings-banks-page': {
                return <BanksPage />
            };
            case '/settings-insurance-companies-page': {
                return <InsuranceCompaniesPage />
            };
            case '/settings-users-page': {
                return <UsersPage />
            };
            default:
                return <UsersPage />
        }
    };

    return (
        <div className='lef-menu-wrapper'>
            <div className='left-menu' >
                <ul className="navbar-nav flex-grow">
                    <NavItem className={(pathname === "/settings-users-page") ? "selectedNavItem" : ""}>
                        <NavLink tag={Link} className="text-dark" to="/settings-users-page">Пользователи</NavLink>
                    </NavItem>
                    <NavItem className={pathname.includes("/settings-insurance-companies-page") ? "selectedNavItem" : ""}>
                        <NavLink tag={Link} className="text-dark" to="/settings-insurance-companies-page">Страховые компании</NavLink>
                    </NavItem>
                    <NavItem className={(pathname === "/settings-banks-page") ? "selectedNavItem" : ""}>
                        <NavLink tag={Link} className="text-dark" to="/settings-banks-page">Банки</NavLink>
                    </NavItem>
                </ul>
            </div>
            <PageComponent />
        </div>
    );
};


export default AdminPage;
