import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
//import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Select from 'react-select';

import { StyledButton } from '../UI';

import ru from 'date-fns/locale/ru';

import { FilterContext } from '../../App';
//import { fetchUsers } from '../../store/userSlice';
//import { fetchDictionaries } from '../../store/dictionarySlice';

import "react-datepicker/dist/react-datepicker.css";
import './Filter.modules.css';

registerLocale('ru', ru);
setDefaultLocale("ru");

const Filter = () => {
    //const dispatch = useDispatch();
    const [filter, setFilter] = useContext(FilterContext);

    const users = useSelector(state => state.users.list).map(user => {
        return {
            value: user.id,
            label: user.email
        }
    });

    const requestTypes = useSelector(state => state.dictionaries.requestTypes).map(requestType => {
        return {
            value: requestType.requestTypeId,
            label: requestType.description
        }
    });

    useEffect(() => {

        //const fetchData = async () => {
        //    dispatch(fetchUsers());
        //    dispatch(fetchDictionaries());
        //};

        //fetchData()
        //    .then(() => {
        let initialState = { ...currentFilter };

        initialState.users = filter.filterValue.userIds.length > 0 ?
            filter.filterValue.userIds.map((item) => {
                return users.find(user => user.id === item.id);
            })
            :
            [{ value: -1, label: "Все" }];

        initialState.requestTypes = filter.filterValue.requestTypeIds.length > 0 ?
            filter.filterValue.requestTypeIds.map((item) => {
                return requestTypes.find(type => type.requestTypeId === item.id);
            })
            :
            [{ value: -1, label: "Все" }];


        initialState.startDate = filter.filterValue.startDate;
        initialState.endDate = filter.filterValue.endDate;

        setCurrentFilter(initialState);
        //    })
        //    .catch(error => console.error(error));

    }, []);

    const initialState = () => {
        return {
            users: [{ value: -1, label: "Все" }],
            statuses: [{ value: -1, label: "Все" }],
            startDate: null,
            endDate: null,
            requestTypes: [{ value: -1, label: "Все" }]
        }
    };

    const [currentFilter, setCurrentFilter] = useState(initialState());

    useEffect(() => {
        if (currentFilter.endDate !== null && currentFilter.startDate > currentFilter.endDate)
            setCurrentFilter(state => {
                return { ...state, endDate: state.startDate }
            });
    }, [currentFilter.startDate, currentFilter.endDate]);

    const onChangeStartDateHandler = (date) => {
        setCurrentFilter(state => {
            return { ...state, startDate: date }
        });
    };

    const onChangeEndDateHandler = (date) => {
        setCurrentFilter(state => {
            return { ...state, endDate: date }
        });
    };

    const onChangeRequestTypesHandler = (value) => {
        //console.log(value);
        if (value.length === 0)
            setCurrentFilter(state => {
                return { ...state, requestTypes: [{ value: -1, label: "Все" }] }
            });
        //else if ()
        else
            setCurrentFilter(state => {
                return { ...state, requestTypes: value.filter(x => x.value !== -1) }
            });
    };

    const onChangeStatusesHandler = () => {

    };

    const onChangeUsersHandler = (value) => {
        if (value.length === 0)
            setCurrentFilter(state => {
                return { ...state, users: [{ value: -1, label: "Все" }] }
            });
        //else if ()
        else
            setCurrentFilter(state => {
                return { ...state, users: value.filter(x => x.value !== -1) }
            });
    };

    const onApplyFilter = () => {
        //onChangeFilterData(filter);
        setFilter(state => {
            return {
                ...state,
                filterValue: {
                    ...state.filterValue,
                    requestTypeIds: currentFilter.requestTypes.filter(item => item.value !== -1).map(item => item.value),
                    userIds: currentFilter.users.filter(item => item.value !== -1).map(item => item.value),
                    startDate: currentFilter.startDate,
                    endDate: currentFilter.endDate
                }
            }
        });
    };

    const onResetFilter = () => {
        setFilter(state => {
            return {
                ...state,
                filterValue: {
                    userIds: [],
                    statuses: [],
                    startDate: null,
                    endDate: null,
                    requestTypeIds: []
                }

            }
        });

        setCurrentFilter(initialState());
    };

    return (
        <div className='filter-container'>
            <div className='filters-header'>Фильтры</div>
            <div className="filter-info-block">
            <div className='filter period'>
                <div className='filter-name'>Период</div>
                <div className='filter-select-period'>
                    <DatePicker
                        selected={currentFilter.startDate}
                        placeholderText='c'
                        onChange={onChangeStartDateHandler}
                        dateFormat="dd.MM.yyyy"
                        isClearable
                        className='filter-item'
                    />
                    <DatePicker
                        selected={currentFilter.endDate}
                        placeholderText='по'
                        onChange={onChangeEndDateHandler}
                        dateFormat="dd.MM.yyyy"
                        minDate={currentFilter.startDate}
                        isClearable
                        className='filter-item'
                    />
                </div>
            </div>
            <div className='filter-data'>
                <div>
                    <div className='filter-name'>Ответственный</div>
                        <Select
                        isMulti
                      //  isSearchable
                        value={currentFilter.users}
                        options={[{ value: -1, label: "Все" }, { value: 0, label: "Отсутствует" }, ...users]}
                        defaultValue={[{ value: -1, label: "Все" }]}
                        onChange={onChangeUsersHandler}
                    />
                </div>
                <div>
                    <div className='filter-name'>Статусы</div>
                    <Select
                        isMulti
                     //   isSearchable
                        options={[{ value: -1, label: "Все" },]}
                        defaultValue={[{ value: -1, label: "Все" }]}
                    />
                </div>
                <div>
                    <div className='filter-name'>Виды запросов</div>
                    <Select
                        isMulti
                      //  isSearchable
                        options={[{ value: -1, label: "Все" }, ...requestTypes]}
                        //defaultValue={}
                        value={currentFilter.requestTypes}
                        onChange={onChangeRequestTypesHandler}
                    />
                </div>
                </div>
            </div>
            <div className='filter-buttons'>
                <div className='button-cancel' onClick={onResetFilter}>Сбросить</div>
                <StyledButton onClickHandler={onApplyFilter}>Применить</StyledButton>
                </div>
            
        </div>
    );
};

export default Filter;