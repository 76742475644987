import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { MdEditNote, MdDone, MdOutlineCancel } from 'react-icons/md';

import { getNextId, ticketOfferStatusName } from '../../additional';
import { saveTicketCommercialOffer } from '../../store/ticketSlice';

import { InsuranceOfferItem, KaskoOfferItemSaved, OsagoOfferItemSaved, IpotekaOfferItemSaved, IflOfferItemSaved } from './';
//import { StyledButton } from '../../components/UI';
import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';
import { ReactComponent as AddOfferIcon } from './add-offer.svg';
import { ButtonSelector } from '../../components';


const Button = ({ name = "", onClick = null, leftIconName = null, type = "primary", busy = false }) => {
    return (
        <div className={'_button ' + type + (busy ? " busy" : "")} onClick={onClick ? (busy ? () => { } : onClick) : () => { }}>
            {leftIconName &&
                <Icon name={leftIconName} />
            }
            <div>{name}</div>
            {busy &&
                <div className="_button-busy" />
            }
        </div>
    );
};

const TicketCommercialOffers = ({ data }) => {

    useEffect(() => {
        console.log(data);
    }, [data]);
    
    //console.log(data);
    //if(data.)
    //const initialStateOffer = {
    //    id: 0,
    //    insuranceCompanyId: 0,
    //    theft: false,
    //    damage: false,
    //    complete: false,
    //    summ: 0,
    //    haveFranchize: false,
    //    franchizeOffers: []
    //};

    const initialStateKaskoOffer = {
        id: 0,
        insuranceCompanyId: 0,
        theft: false,
        damage: false,
        complete: false,
        summ: 0,
        haveFranchize: false,
        franchizeOffers: []
    }

    const initialStateOsagoOffer = {
        id: 0,
        insuranceCompanyId: 0,
        summ: 0
    }

    const initialStateIpotekaOffer = {
        id: 0,
        insuranceCompanyId: 0,
        summ: 0
    }

    const initialStateIflOffer = {
        id: 0,
        insuranceCompanyId: 0,
        houseInsuranceAmount: {
            constructiveSumm: null,
            finishingSumm: null,
            movablePropertySumm: null
        },
        apartmentInsuranceAmount: {
            constructiveSumm: null,
            finishingSumm: null,
            movablePropertySumm: null
        },
        bathInsuranceAmount: {
            constructiveSumm: null,
            finishingSumm: null,
            movablePropertySumm: null
        },
        garageInsuranceAmount: {
            constructiveSumm: null,
            finishingSumm: null,
            movablePropertySumm: null
        },
        summ: 0
    }

    //const [requestTypesList, setRequestTypesList] = useState([]);

    //useEffect(() => {
    //    // fetch data
    //    const dataFetch = async () => {

    //        try {
    //            //setError(null);
    //            //setIsLoading(true);

    //            const requestTypes = await (
    //                await fetch(
    //                    `${process.env.REACT_APP_PUBLIC_URL}/api/dictionary/requestTypes`, {
    //                    method: 'GET',
    //                    headers: {
    //                        "ngrok-skip-browser-warning": "69420",
    //                    }
    //                }
    //                )
    //            ).json();

    //            setRequestTypesList(requestTypes);

    //        } catch (e) {
    //            //setError(e);
    //        };

    //        //setIsLoading(false);
    //    };

    //    dataFetch();
    //}, []);

    const initialStateOffer = (requestTypeId) => {
        switch (requestTypeId) {
            case 0://kasko
                return { ...initialStateKaskoOffer };
            case 1://osago
                return { ...initialStateOsagoOffer };
            case 2://ifl
                return { ...initialStateIflOffer };
            case 3://ipoteka
                return { ...initialStateIpotekaOffer };
            default:
                return null;
        }
    }

    const initialStateInsuranceCompany = {
        id: 0,
        insuranceCompany: null,
        offers: [] //initialStateOffer[]
    };


    const initialStateOffers = {
        status: 0,
        insuranceCompanies: [], //initialStateInsuranceCompany[]
        type: null,
        ticketId: null,
        firstDescription: "",
        secondDescription: ""
    };

    const dispatch = useDispatch();
    const { kaskoCommercialOffers, osagoCommercialOffers, ipotekaCommercialOffers, iflCommercialOffers } = useSelector(store => store.tickets.item);
    const { insurances, requestTypes } = useSelector(store => store.dictionaries);
    const [newOffer, setNewOffer] = useState(false);

    console.log(
        requestTypes.find(x => x.requestTypeId === data.requestTypeId)?.firstDescription ?? ""
    );

    const [offers, setOffers] = useState({
        ...initialStateOffers,
        type: data.requestTypeId,
        ticketId: data.id,
        firstDescription: requestTypes.find(x => x.requestTypeId === data.requestTypeId)?.firstDescription ?? "",
        secondDescription: requestTypes.find(x => x.requestTypeId === data.requestTypeId)?.secondDescription ?? ""
    });

    const [avialableInsurances, setAvialableInsurances] = useState([]);

    useEffect(() => {
        setAvialableInsurances(state => {
            let avialable = [];
            insurances?.filter((item) => {
                //console.log(item, item.requestTypes.findIndex(x => x.requestTypeId === data.requestTypeId));
                if (item.requestTypes.findIndex(x => x.requestTypeId === data.requestTypeId) !== -1)
                    avialable.push({ ...item, isSet: false });
            });
            return avialable;
        });
    }, [insurances]);

    //console.log(insurances);

    const onSelectInsuranceHandler = ({ item, isSet }) => {
        //console.log({ offerId, item });

        setAvialableInsurances(state => {
            let newState = [...state];
            const insuranceIndex = newState.findIndex(x => x.id === item.id);
            newState[insuranceIndex].isSet = isSet;

            return newState;
        });

        setOffers(state => {
            let newState = { ...state };
            //console.log(item, isSet, newState.insuranceCompanies.findIndex(x => x.id === item.id));
            if (isSet) {
                const offerIndex = newState.insuranceCompanies.findIndex(x => x.id === item.id);
                if (offerIndex) {
                    newState.insuranceCompanies.push({ insuranceCompany: item, id: item.id, offers: [{ ...initialStateOffer(data.requestTypeId), insuranceCompanyId: item.id, id: getNextId() }] });
                };
            }
            else {
                newState.insuranceCompanies = state.insuranceCompanies.filter(x => x.id !== item.id);
            }
            //console.log(newState.insuranceCompanies[offerIndex].insuranceCompany);
            return newState;
        });
    };

    const onChangeFirstDescription = (value) => {
        setOffers(state => {
            let newState = { ...state };
            newState.firstDescription = value;

            return newState;
        });
    };

    const onChangeSecondDescription = (value) => {
        setOffers(state => {
            let newState = { ...state };
            newState.secondDescription = value;

            return newState;
        });
    };

    const onAddOffer = (insurenceId) => {

        setOffers(state => {
            let newState = { ...state };
            const offerIndex = newState.insuranceCompanies.findIndex(x => x.id === insurenceId);
            if (offerIndex >= 0) {
                newState.insuranceCompanies[offerIndex].offers.push({ ...initialStateOffer(data.requestTypeId), insuranceCompanyId: insurenceId, id: getNextId(state.insuranceCompanies[offerIndex].offers) });
            };

            return newState;
        });

    };

    const onRemoveOffer = (offerId, insurenceId) => {

        setOffers(state => {
            let newState = { ...state };
            const offerIndex = newState.insuranceCompanies.findIndex(x => x.id === insurenceId);

            if (offerIndex >= 0) {
                newState.insuranceCompanies[offerIndex].offers = state.insuranceCompanies[offerIndex].offers.filter(x => x.id !== offerId);
            };
            return newState;
        });

    };

    const onChangeOffer = (offer) => {
        //console.log('OFFER CHANGED', offer);

        setOffers(state => {
            let newState = { ...state };
            const insuranceIndex = newState.insuranceCompanies.findIndex(x => x.id === offer.insuranceId);
            if (insuranceIndex >= 0) {
                //console.log(offer.currenOffer.id, newState.insuranceCompanies[insuranceIndex].offers.findIndex(x => x.id === offer.currenOffer.id));
                const offerIndex = newState.insuranceCompanies[insuranceIndex].offers.findIndex(x => x.id === offer.currenOffer.id);

                if (offerIndex >= 0) {
                    newState.insuranceCompanies[insuranceIndex].offers[offerIndex] = { ...offer.currenOffer };
                } else {
                    console.error("Не найден оффер у страховой компании, не удалось добавить оффер!")
                };

                //newState.insuranceCompanies[insuranceIndex].offers.push({ ...initialStateOffer, id: getNextId(state.insuranceCompanies[insuranceIndex].offers) });
            } else {
                console.error("Не найдена страховая компания, не удалось добавить оффер!")
            };

            return newState;
        });
    };

    //console.log(insurances);

    const onAddCommercialOffer = () => {
        setNewOffer(true);
        //setOffers(state => {
        //    let newState = { ...state };
        //    newState.insuranceCompanies.push({ ...initialStateInsuranceCompany, id: getNextId(state.insuranceCompanies) });

        //    return newState;
        //});
    };

    useEffect(() => { console.log("CO: ", offers, ticketOfferStatusName(offers.status)) }, [offers]);

    const onEditCommercialOffer = () => {

    };

    const onDeleteCommercialOffer = () => {

    };

    const onSaveAsTemplateHandler = () => {
        dispatch(saveTicketCommercialOffer(offers))
            .then(() => {
                //console.log("saved");
                setNewOffer(false);
            });
    };

    const onSaveAndStartOffer = () => {

    };

    const onCancelHandler = () => {
        setNewOffer(false);
        //console.log(initialStateOffers);
        setOffers({ ...initialStateOffers, insuranceCompanies: [], type: data.requestTypeId, ticketId: data.id });
        setAvialableInsurances(state => {
            let avialable = [];
            insurances?.filter((item) => {
                if (item.requestTypes.findIndex(x => x.requestTypeId))
                    avialable.push({ ...item, isSet: false });
            });
            return avialable;
        });

    };

    return (
        <>
            {newOffer === false &&
                <div>
                    {data.kaskoCommercialOffers.length === 0 &&
                        <div>Коммерческие предложения еще не добавлены</div>
                    }

                    <div className='add-commercial-offer' onClick={onAddCommercialOffer}>
                        <AddOfferIcon />
                        <div>Добавить коммерческое предложение</div>
                    </div>
                    {/*<StyledButton name='Добавить' onClickHandler={onAddCommercialOffer} />*/}
                </div>
            }
            {newOffer === true &&
                <>
                    <ButtonSelector
                        name='Доступные страховые компаниии'
                        list={avialableInsurances}
                        onClick={onSelectInsuranceHandler}
                    />
                    {offers?.insuranceCompanies.length > 0 &&
                        <div className='insurance-selector-name'>
                            Конструктор предложений
                        </div>
                }

                <div>
                    <p><label htmlFor="fistDescription">Основной комментарий:</label></p>
                    <textarea id="fistDescription" name="fistDescription" rows="4" cols="60" value={offers.firstDescription} onChange={(e) => onChangeFirstDescription(e.target.value)} />
                </div>

                {offers && <InsuranceOfferItem
                    offers={offers}
                    type={data.requestTypeId}
                    onAddOffer={onAddOffer}
                    onRemoveInsurance={onSelectInsuranceHandler}
                    onRemoveOffer={onRemoveOffer}
                    onChangeOffer={onChangeOffer}
                    isNewOffer
                />}
                <div>
                    <p><label htmlFor="secondDescription">Дополнительный комментарий:</label></p>
                    <textarea id="secondDescription" name="secondDescription" rows="4" cols="60" value={offers.secondDescription} onChange={(e) => onChangeSecondDescription(e.target.value)} />
                </div>

                    <div className='kp-buttons-container'>
                    <div className="kp-button-group">
                        <Button name="Скачать PDF" type="secondary" leftIconName="download" />
                        {/*<Button name="Сохранить как шаблон" onClick={() => { } } />*/}
                        </div>
                        <div className="kp-button-group">
                            Сохранить и отправить:
                            <Button name="На почту" type="secondary" leftIconName="mail" />
                            <Button name="В Telegram" type="secondary" leftIconName="telegram" onClick={onSaveAsTemplateHandler} />
                            <Button name="Отмена" type="danger" onClick={onCancelHandler} />
                        </div>
                    </div>
                </>
            }

            {newOffer === false &&
                <div>
                    
                    {kaskoCommercialOffers.map((item) => {
                        //console.log('KASKO OFFERS', item);
                        return (
                            <KaskoOfferItemSaved

                                //        key={item.id}
                                offer={item}
                                selectedOffer={data.selectedOffer}
                            />
                        )
                        //return (
                        //    <InsuranceOfferItemSaved
                        //        key={item.id}
                        //        offer={item}
                        //    />
                        //)
                    })}

                    {osagoCommercialOffers.map((item) => {
                        //console.log('OSAGO OFFERS', item);
                        return (
                            <OsagoOfferItemSaved
                                key={item.id}
                                insuranceItem={item}
                                selectedOffer={data.selectedOffer}

                            />
                        )
                    })}
                    {ipotekaCommercialOffers.map((item) => {
                        //console.log('IPOTEKA OFFERS', item);
                        return (
                            <IpotekaOfferItemSaved
                                key={item.id}
                                insuranceItem={item}
                                selectedOffer={data.selectedOffer}

                            />
                        )
                    })}
                    {iflCommercialOffers.map((item) => {
                        //console.log('IFL OFFERS', item);
                        return (
                            <IflOfferItemSaved
                                key={item.id}
                                insuranceItem={item}
                                selectedOffer={data.selectedOffer}

                            />
                        )
                    })}
                </div>

            }
        </>
    );
};

export default TicketCommercialOffers;