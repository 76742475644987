import React from 'react';

const RegionListHeader = () => {
    return (
        <div className='banks-header'>
            <div className='bank-id'>ID</div>
            <div className='bank-name'>Наименование</div>
        </div>
    );
};

export default RegionListHeader;