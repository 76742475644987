import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import Select from 'react-select';

import { addNewTicketNote, cancelAddTicketNote } from '../../store/ticketSlice';
import { fetchUsers } from '../../store/userSlice';
import { saveTicketResponsibele } from '../../store/ticketSlice';

import { stageName, requestTypeName, ticketOfferStatusName, getNextId, ticketStatus } from '../../additional';
import Icon from '../../components/UI/Icon/Icon';

import { TicketDataItem } from './';
import Note from './Note';

const TicketData = ({ data }) => {
    const dispatch = useDispatch();
    const responsible = useSelector(state => state.users.list).find(x => x.id === data.responsibleId);
    const currentResponsibleId = useSelector(state => state.auth.user.id);
    const { user } = useSelector(state => state.auth);
    const [isAdmin, setIsAdmin] = useState(false);

    const [noteEditMode, setNoteEditMode] = useState(false);
    const [noteForEdit, setNoteForEdit] = useState(false);

    const tree = [];

    data.ticketDatas.forEach(item => {
        const { key, secondaryKey } = item;

        //console.log(key, secondaryKey);

        if (!secondaryKey.includes("#")) {
            if (!tree.find(o => o.key === secondaryKey)) {
                const allElements = data.ticketDatas.filter(e => e.secondaryKey === secondaryKey);
                //console.log('Единичное поле', allElements.length === 1,  secondaryKey);
                if (allElements.length === 1) {
                    //это единичное поле
                    tree.push({
                        id: item.id,
                        key: secondaryKey,
                        data: item.data
                    });
                } else {
                    //это группа полей
                    tree.push({
                        key: secondaryKey,
                        data: allElements.map(i => {
                            return {
                                id: i.id,
                                key: i.key,
                                data: i.data
                            };
                        })
                    });
                };
            }
        } else {
            const subKey = secondaryKey.split('#');
            //Тут проверить что нету больше данных только 1 поле
            //тогда вывести не как группу потому что не корректно строится дерево
            //    console.log('Единичное поле', allElements.length === 1,  secondaryKey);
            //
            if (!tree.find(o => o.key === subKey[0].trim())) {
                //Отберем различные данные
                const allElements = data.ticketDatas.filter(e => e.secondaryKey.split('#')[0] === subKey[0]);

                //if (allElements.length === 1) {
                //console.log('Группа', allElements.length, item.secondaryKey.split('#')[0], subKey[0]);
                //}

                const subGroups = allElements.reduce((groups, item) => {
                    const group = (groups[item.secondaryKey.split('#')[1]] || []);
                    group.push({
                        id: item.id,
                        key: item.key,
                        data: item.data
                    });
                    const stringName = item.secondaryKey.split('#')[1];
                    groups[stringName] = group;
                    return groups;
                }, {});
                tree.push({
                    //id: item.id,
                    key: subKey[0],
                    data: subGroups
                });
            }
            //else
            //{
            //    const allElements = data.ticketDatas.filter(e => e.secondaryKey.split('#')[0] === subKey[0]);
            //    console.log('Elem', allElements.length, item.secondaryKey.split('#')[0], subKey[0]);
            //    tree.push({
            //        id: item.id,
            //        key: secondaryKey,
            //        data: item.data
            //    });

            //}
        };
    });

    const Elements = ({ items }) => {
        try {
            if (!Array.isArray(items)) {
                const entries = Object.entries(items);

                return (
                    <>
                        {entries.map(([key, values]) => {
                            //console.log(value.key);
                            return (
                                <div key={key}>
                                    <div key={key} className='group-container'>
                                        <div className='object-name'>{key}</div>
                                        <Elements items={values} />
                                    </div>
                                </div>
                            )
                        })}
                    </>
                );
            } else {
                return (
                    <>
                        {items.map((item) => {
                            if (typeof item.data === "string") {
                                return (
                                    <TicketDataItem key={item.id} item={item} />
                                );
                            } else if (typeof item.data === "array") {
                                return (
                                    <div className='array-container' key={item.key}>
                                        <div className='group-container'>
                                            <div className='object-name'>{item.key}</div>
                                            <Elements items={item.data} />
                                        </div>
                                    </div>
                                );
                            } else if (typeof item.data === "object") {
                                return (
                                    <div className='object-container' key={item.key}>
                                        <div className='object-name'>{item.key}</div>
                                        <Elements items={item.data} />
                                    </div>
                                )
                            }
                        })}
                    </>
                );
            }
        } catch (e) {
            console.log(e);
            console.log(items);
            return <>ERROR</>
        }

    };

    const newNoteHandler = ({ ticketId, responsibleId }) => {
        setNoteEditMode(true);
        dispatch(addNewTicketNote({
            id: null,
            ticketId,
            responsibleId: currentResponsibleId,
            noteText: '',
            //noteDate: null
        }));
    };

    const onCancelHandler = () => {
        setNoteEditMode(false);
        dispatch(cancelAddTicketNote());
    }

    useEffect(() => {
        if (user) {
            const { accessToken } = user;
            //console.log('TT', accessToken)

            const decoded = jwt_decode(accessToken);
            const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? true : false;
            console.log("IsAdmin: ", role);
            setIsAdmin(role);
        }
    }, [user]);

    const previosPolosFiles = data.files.filter(x => x.fileType === 18);
    console.log(data);
    return (
        <>
            <div className='ticket-data-info-container'>
                <div className='ticket-data-info'>

                    <div className='ticket-data-summary-info'>
                        <div className='ticket-data-summary-row'>
                            <div>Дата создания</div>
                            <div>{new Date(data.dateCreated).toLocaleString('ru-RU')}</div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Дата изменения</div>
                            <div>{new Date(data.dateModified).toLocaleString('ru-RU')}</div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Вид страхования</div>
                            <div>{requestTypeName(data.requestTypeId)}</div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Текущий этап</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 12 }}>
                                    {ticketStatus(data.ticketStage)}
                                </div>
                                <Icon name='edit' />
                            </div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Ответственный</div>
                            <div style={{ display: 'flex' }}>
                                <div className='responsibleSelected'>
                                    {isAdmin === true
                                        ?
                                        <ResponsibleSelect
                                            responsible={responsible}
                                            ticketId={data.id}
                                            clientId={data.clientId}
                                        />
                                        :
                                        <>{(responsible?.name?.length > 0 || responsible?.surname?.length > 0) ? (responsible?.name + " " + responsible?.surname) : responsible?.email}</>
                                    }
                                </div>
                                {/*<Icon name='edit' />*/}
                            </div>
                        </div>

                        <div className='ticket-data-summary-row-header'>Клиент</div>
                        <div className='ticket-data-summary-row'>
                            <div>ФИО клиента</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 12 }}>
                                    {data.client?.firstName} {data.client?.lastName}
                                </div>
                                <Icon name='edit' />
                            </div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Псевдоним</div>
                            <div>{data.client?.username}</div>
                        </div>
                        <div className='ticket-data-summary-row'>
                            <div>Номер телефона</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 12 }}>{data.client?.phone}</div>
                                <Icon name='edit' />
                            </div>
                        </div>
                        {!data?.description && previosPolosFiles.length > 0 &&
                            <>
                                <div className='ticket-data-summary-row-header'>
                                    Прикрепленные файлы предыдущего полиса {previosPolosFiles.length}
                            
                                </div>
                                <div className="previus-polis-files">
                                    {data?.files && previosPolosFiles.map((file, index) => {
                               
                                        return (
                                            <a key={file.id} href={"api/ticket/file/" + file.fileName} target="_self" >
                                                <img
                                                    style={{ width: 120, height: 120, border: "1px solid gray", borderRadius: 16, cursor: "pointer" }}
                                                    src={"api/additional/file/" + file.fileName}
                                                    key={file.id}
                                                    alt={"Файл предыдуущего полиса " + index + 1}           
                                                />
                                            </a>
                                        );
                                    })}
                                </div>
                                <div className="previus-polis-files-buttons">
                                    <a href={"/api/additional/DownloadFiles/" + data.id} target="_blank" rel="noopener noreferrer" download>
                                        <div className="previus-polis-files-button download">
                                            Скачать файлы
                                        </div>
                                    </a>
                                    <div className="previus-polis-files-button create">
                                        Заполнить заявку
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <Elements items={tree} />
                </div>
                <div className='ticket-data-notes'>
                    <div className='object-name'>
                        <div>Заметки</div>
                        {!noteEditMode && <Icon name='add' onClick={() => newNoteHandler({ responsibleId: data.responsibleId, ticketId: data.id })} />}
                    </div>
                    <div>
                        {data.notes.map(note => {
                            const { id, responsibleId, ticketId, noteText, noteDate } = note;
                            //console.log(note, !id ? true : false);
                            //const noteResponsible = getResponsible(responsibleId);
                            return (
                                <Note
                                    key={id ?? -1}
                                    editMode={!id ? true : false}
                                    noteText={noteText}
                                    noteDate={noteDate}
                                    ticketId={ticketId}
                                    responsibleId={responsibleId}
                                    onCancel={onCancelHandler}
                                />
                            )
                        })}
                    </div>
                </div>                
            </div>
        </>
    );
};

export default TicketData;

const ResponsibleSelect = ({ responsible, clientId, ticketId }) => {
    const dispatch = useDispatch();
    const { list: responsibles } = useSelector(store => store.users);

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    //console.log(responsible);

    return (
        <Select
            isSearchable
            value={responsible ? { value: responsible.id ?? 0, label: (responsible?.name?.length > 0 || responsible?.surname?.length > 0) ? (responsible?.name + " " + responsible?.surname) : responsible?.email } : { value: 0, label: "Отсутствует" }}
            options={[{ value: 0, label: "Отсутствует" }, ...responsibles.map(user => {
                return { value: user.id, label: ((user.name?.length > 0 || user.surname?.length > 0) ? (user.name + " " + user.surname) : user.email) };
            })]}
            //defaultValue={responsible.id ?? 0}
            onChange={(e) => dispatch(saveTicketResponsibele({ clientId: clientId, ticketId: ticketId, userId: e.value }))}
        />
    );
};
//{responsible ? (responsible.name?.length > 0 || responsible.surname?.length > 0) ? (responsible.name + " " + responsible.surname) : responsible.email : 'Нет'}