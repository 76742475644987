import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useTelegram } from "../../hooks/useTelegram";

import { toCurrency, stageName, requestTypeName } from '../../additional';

import TgHeader from '../../components/TgHeader';
import { Icon } from '../../components/UI';

import TgInsuranseSmallData from './TgInsuranseSmallData';
import { useDispatch, useSelector } from 'react-redux';

import { fetchFriendsData } from '../../store/profileSlice';
import { fetchInsurancesDictionary } from '../../store/dictionarySlice';

const TgTextButton = ({ name, onClick, rightIconName }) => {
    return (
        <div className='tg-text-button-container'>
            <div className="tg-text-button-name" onClick={onClick}>{name}{rightIconName && <Icon name={rightIconName} />}</div>
        </div>
        
    )
}

const Friend = ({ data }) => {

    const { insurances } = useSelector(store => store.dictionaries);

    const [showInsurances, setShowInsurances] = useState(false);
    const { clientName, cashBackSumm, friendInsurances } = data;

    return (
        <>
            <div className='friend-container'>
                <div className='friend-line bold'>{clientName}</div>
                <div className='friend-line'>
                    <div>Кол-во полисов</div>
                    <div>{friendInsurances.length}</div>
                </div>
                
                <div className='friend-line'>
                    <div>Общий кэшбек по другу</div>
                    <div>{toCurrency(cashBackSumm, 'RUB', 'Ru-ru')}</div>
                </div>

                {friendInsurances.length > 0 &&
                    <>
                        <div className="tg-delimitator" />

                        <TgTextButton name="Информация по страхованиям" rightIconName={showInsurances ? 'chevron-up' : 'chevron-down'} onClick={() => { setShowInsurances(!showInsurances) }} />

                        {showInsurances && friendInsurances.map(insurance => {
                            console.log("CBI: ", insurance);
                            return (
                                <TgInsuranseSmallData key={insurance.id} data={{
                                    insuranceType: requestTypeName(insurance?.requestTypeId),
                                    insuranceCompany: insurances?.find(x => x.id === insurance.insuranceCompanyId)?.name,
                                    period: "",
                                    summ: insurance.insuranceSumm,
                                    cashBack: insurance.cashBackSumm,
                                    number: insurance.insuranceNumber
                                }} />
                            )
                        })}
                    </>
                }

            </div>

        </>
    );
};

const MyFriends = () => {

    const dispatch = useDispatch();

    const params = useParams();
    const tg_user_id = params?.id;

    const { tg } = useTelegram();
    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();

    const { friendsData } = useSelector(state => state.profile);

    const onClickBackButtonHandler = () => {
        navigate(-1);
        //navigate(`/tg/Profile/${tg_user_id}`);
    };

    console.log(useSelector(state => state.profile));
    useEffect(() => {
        console.log("download friends ", tg_user_id);
        dispatch(fetchFriendsData(tg_user_id));
        dispatch(fetchInsurancesDictionary());
    }, [dispatch, tg_user_id]);

    useEffect(() => {
        tg.ready();
        tg.BackButton.show();

        tg.onEvent('backButtonClicked', onClickBackButtonHandler);
        return () => {
            tg.offEvent('backButtonClicked')
        }
    }, [tg]);

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <TgHeader name="Мои друзья" />

            {friendsData.map(friend => {
                return (
                    <Friend data={friend} key={friend.id} />
                );
            })}
          
        </div>
    );
};

export default MyFriends;


const dummyData = [
    {
        id: 1,
        friendName: "Антон",
        insurancesCount: 2,
        cashBack: 1300,
        insurances: [
            {
                id: 1,
                insuranceType: "Каско",
                insuranceCompany: "Ингосстрах",
                period: "01.12.2021 - 31.12.2021",
                summ: 2500,
                cashBack: 500
            },
            {
                id: 2,
                insuranceType: "Осаго",
                insuranceCompany: "Росгосстрах",
                period: "01.12.2023 - 31.12.2023",
                summ: 5000,
                cashBack: 800
            },
        ]
    },
    {
        id: 2,
        friendName: "Максим",
        insurancesCount: 1,
        cashBack: 700,
        insurances: [
            {
                id: 1,
                insuranceType: "Каско",
                insuranceCompany: "Ингосстрах",
                period: "01.12.2021 - 31.12.2021",
                summ: 3590,
                cashBack: 700
            },
        ]
    },
    {
        id: 3,
        friendName: "Юра",
        insurancesCount: 1,
        cashBack: 700,
        insurances: [
            {
                id: 1,
                insuranceType: "Каско",
                insuranceCompany: "Ингосстрах",
                period: "01.12.2021 - 31.12.2021",
                summ: 2500,
                cashBack: 700
            },
        ]
    },
];