import React, { useState, useEffect } from 'react';
//import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import Icon from '../UI/Icon';
import UnreadMessages from "../UI/UnreadMessages";

import { logout } from '../../store/authSlice';

import './NavMenu.modules.css';

export const mainMenu = [
    {
        id: 1,
        name: "Тикеты",
        link: "/",
        secondaryLink: 'ticket',
        leftIcon: "tickets",
        rightIcon: null,
        subItems: []
    },
    {
        id: 2,
        name: "Клиенты",
        link: "/client",
        secondaryLink: 'client',
        leftIcon: "star",
        rightIcon: null,
        rightComponent: <UnreadMessages />,
        subItems: []
    },
];

export const adminMenu = [
    {
        id: 100,
        name: "Управление",
        link: "/settings",
        secondaryLink: null,
        leftIcon: "settings",
        rightIcon: "",
        subItems: [
            {
                id: 101,
                name: "Распределение",
                link: "/settings/distribution-page",
                secondaryLink: null,
                leftIcon: "distribution",
                rightIcon: null,
            },
            {
                id: 102,
                name: "Пользователи",
                link: "/settings/users-page",
                secondaryLink: null,
                leftIcon: "nav-users",
                rightIcon: null,
            },
            {
                id: 103,
                name: "Страховые компании",
                link: "/settings/insurance-companies-page",
                secondaryLink: null,
                leftIcon: "shield-check",
                rightIcon: null,
            },
            {
                id: 104,
                name: "Банки",
                link: "/settings/banks-page",
                secondaryLink: null,
                leftIcon: "banknotes",
                rightIcon: null,
            },
            {
                id: 105,
                name: "Виды страхования",
                link: "/settings/request-tupes-page",
                secondaryLink: null,
                leftIcon: "grid",
                rightIcon: null,
            },
            {
                id: 106,
                name: "Регионы",
                link: "/settings/regions-page",
                secondaryLink: null,
                leftIcon: "grid",
                rightIcon: null,
            }
        ]
    },
]

const NavMenu = ({ collapsed = true, setCollapsed = () => { }}) => {



    const { pathname } = useLocation();
    //const [collapsed, setCollapsed] = useState(true);
    const [selectedSection, setSelectedSection] = useState(pathname);
    const { isAuth, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(null);//user && jwt_decode(user.accessToken).role;
    const [menu, setMenu] = useState([...mainMenu]);

    useEffect(() => {
        if (isAdmin === 0)
            setMenu([...mainMenu, ...adminMenu]);
        else
            setMenu([...mainMenu]);
    }, [isAdmin]);

    useEffect(() => {
        if (user) {
            const { accessToken } = user;
            //console.log('TT', accessToken)

            const decoded = jwt_decode(accessToken);
            const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? 0 : 1;
            setIsAdmin(parseInt(role));
        }
    }, [user]);

    useEffect(() => {
        //console.log(pathname);

        setSelectedSection(pathname);
    }, [pathname]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={'menu-container ' + (!collapsed ? 'collapsed' : '')} >
            <div className='menu-top'>
                <div className='menu-header-container'>
                    <Icon name='logo' className={'menu-logo ' + (!collapsed ? 'collapsed' : '')} />
                    {collapsed &&
                        <div>
                            <div className='menu-header'>СТРАХОВАНИЕ</div>
                            <div className='menu-description'>CRM система</div>
                        </div>
                    }
                </div>
                <ul>
                    {menu && menu.map((item) => {
                        const { id } = item;

                        return (
                            <MenuItem
                                selected={pathname}
                                key={id}
                                item={item}
                                setSelected={() => { }}
                                collapsed={collapsed}
                            />
                        );
                    })}
                </ul>
            </div>

            <div className='menu-bottom'>
                <UserMenuItem
                    name='Василий Иванов'
                    collapsed={collapsed}
                />
                <div className='menu-item-link' style={{ cursor: 'pointer' }} onClick={toggleNavbar}>
                    <span className='menu-left-icon'>
                        <Icon name={!collapsed ? 'arrow-right-black' : 'back'} color = "#000"/>
                    </span>
                    {collapsed &&
                        <span className='menu-item-name'>
                            Свернуть меню
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ item, selected, collapsed }) => {
    const [subMenuCollapsed, setSubMenuCollapsed] = useState(true);

    const { id, name, link, leftIcon, subItems } = item;
    const rightIcon = '';//если субменю сделать значек для раскрывания

    //let Icon;

    //switch (item.leftIcon) {
    //    case "tickets":
    //        Icon = <Icon name='tickets' />
    //        break;
    //    case 'settings':
    //        Icon = <Icon name='settings' />;
    //        break;
    //    default:
    //        Icon = <></>;
    //        break;
    //}

    const onClickSubMenuHandler = () => {
        setSubMenuCollapsed(state => !state);
    };

    const MenuItemLink = ({ item }) => {
        const { name, leftIcon, subItems } = item;

        return (
            <div className='menu-item-link'>
                <span className='menu-left-icon'>
                    <Icon name={leftIcon} />
                </span>
                {collapsed &&
                    <>
                        <span className='menu-item-name'>{name}</span>
                            {subItems && subItems.length > 0 &&
                            <span className='menu-item-right'>
                                <Icon name='arrow' />
                            </span>
                        }
                    </>
                }
                {item.rightComponent && item.rightComponent}
            </div>
        );
    };

    return (
        <>

            <li
                className={'menu-item ' + ((selected === item.link || selected.includes(item.secondaryLink)) ? "menu-item-active " : "")}
            >
                {subItems && subItems.length > 0 ?
                    <div className={'munu-link ' + (((selected.includes(item.link) || selected.includes(item.secondaryLink)) && selected !== item.link && selected.length > 3) ? "submenu-active" : "")} onClick={onClickSubMenuHandler} style={{ cursor: 'pointer' }}>
                        <MenuItemLink item={item} />
                    </div>
                    :
                    <Link className='munu-link' to={link}>
                        <MenuItemLink item={item} />
                    </Link>
                }
            </li>
            {subMenuCollapsed === false &&
                <li>
                    <ul>
                        {subItems && subItems.map((subItem) => {
                            const { id } = subItem;
                            return (
                                <MenuItem
                                    selected={selected}
                                    key={id}
                                    item={subItem}
                                    subItem
                                    collapsed={collapsed}
                                />
                            );
                        })}
                    </ul>
                </li>
            }
        </>
    )

};

const UserMenuItem = ({ name, collapsed }) => {
    const { isAuth, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutHandler = () => {
        navigate("/");
        dispatch(logout(user));
    };

    return (
        <div className='menu-item-link' style={{ borderBottom: '1px solid #EEF0F2', paddingBottom: 22 }} >
            <div className='menu-left-icon' style={{ display: 'flex' }}>
                <Icon name='user' />
            </div>
            {collapsed &&
                <>
                    <div className='menu-item-name' style={{ cursor: 'default' }}>
                        {user.email}
                    </div>
                    <div className='menu-item-right' style={{ display: 'flex', cursor: 'pointer' }}>
                        <Icon name='logout' onClick={logoutHandler} color='red' />
                    </div>
                </>
            }
        </div>
    );
};

export default NavMenu;