import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import password from 'secure-random-password';

import { StyledButton } from '../UI';


const UserItemModal = ({ user = null, showModal, setShowModal, onSaveChangesHandler = () => { } }) => {

    const initialState = {
        id: null,
        email: '',
        password: '',
        role: 1,      //0 - admin, 1 meneger
    };

    const allRoles = [{ value: 0, label: 'Администратор' }, { value: 1, label: 'Менеджер' }];

    const dispatch = useDispatch();
    const [userItem, setUserItem] = useState(initialState);
    const isEditMode = user ? true : false;

    useEffect(() => {
        setUserItem(user ? user : { ...initialState, password: password.randomPassword({ characters: [password.lower, password.upper, password.digits] }) });
    }, [user]);

    const onChangeEmailHandler = ({ value }) => {
        setUserItem(state => {
            const newState = { ...state };
            newState.email = value;
            return newState;
        });
    };

    const onChangeSurnameHandler = ({ value }) => {
        setUserItem(state => {
            const newState = { ...state };
            newState.surname = value;
            return newState;
        });
    };

    const onChangeNameHandler = ({ value }) => {
        setUserItem(state => {
            const newState = { ...state };
            newState.name = value;
            return newState;
        });
    };

    const onChangePasswordHandler = ({ value }) => {
        setUserItem(state => {
            const newState = { ...state };
            newState.password = value;
            return newState;
        });
    };

    const onGeneratePasswordHandler = () => {
        const newPassword = password.randomPassword({ characters: [password.lower, password.upper, password.digits] });
        onChangePasswordHandler({ value: newPassword });
    };

    const onChangeRoleHandler = (x) => {
        setUserItem(state => {
            const newState = { ...state };
            newState.role = x.value;
            return newState;
        });
    };

    const onSaveHandler = () => {
        onSaveChangesHandler(userItem);
        setUserItem(initialState);
        setShowModal(false);

    };

    const onCancelHandler = () => {
        setUserItem({ ...initialState });
        setShowModal(false);
    };

    return (
        <Modal
            className={"modal-window"}
            isOpen={showModal}
            toggle={() => setShowModal(false)}
        >
            <ModalHeader toggle={() => setShowModal(false)}>
                {isEditMode ? "Редактировать пользователя" : "Добавить пользователя"}
            </ModalHeader>
            <ModalBody>

                <div className="modal-row">
                    <div className='row-name'>Имя:</div>
                    <div className='row-input'><Input value={userItem.name} onChange={e => onChangeNameHandler(e.target)} /></div>
                </div>

                <div className="modal-row">
                    <div className='row-name'>Фамилия:</div>
                    <div className='row-input'><Input value={userItem.surname} onChange={e => onChangeSurnameHandler(e.target)} /></div>
                </div>

                <div className="modal-row">
                    <div className='row-name'>Email:</div>
                    <div className='row-input'><Input disabled={(userItem && userItem.email !== 'admin@system.ru') ? false : true} value={userItem.email} onChange={e => onChangeEmailHandler(e.target)} /></div>
                </div>

                <div className="modal-row">
                    <div className='row-name'>Пароль:</div>
                    <div className='row-input-small'><Input value={userItem.password} onChange={e => onChangePasswordHandler(e.target)} /></div>
                    <div className='row-button'>
                        <StyledButton className={"button-generate"} name='Сгенерировать пароль' onClickHandler={onGeneratePasswordHandler}/>
                    </div>                  
                </div>
                <div className="modal-row">
                    <div className='row-name'>Роль:</div>
                    <div className='row-input'>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={allRoles[0]}
                            value={allRoles.find(p => p.value == parseInt(userItem.role))}
                            isDisabled={(userItem && userItem.email !== 'admin@system.ru') ? false : true}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="userRole"
                            options={allRoles}
                            onChange={onChangeRoleHandler}
                        />
                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={onSaveHandler}
                >
                    Сохранить
                    </Button>
                {' '}
                <Button onClick={onCancelHandler}>
                    Отменить
                    </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserItemModal;