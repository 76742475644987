import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete, MdEditDocument, MdToggleOff, MdToggleOn, MdDone, MdOutlineCancel, MdAddCircleOutline, MdClear } from 'react-icons/md';

import ModalDialog from '../components/Modal';
import { Spinner, StyledButton, CheckBox, Icon } from '../components/UI';
//import LeftMenu from '../components/LeftMenu/LeftMenu';

import { checkAuth } from '../store/authSlice';
import Upload, { upload } from '../components/Upload/Upload';
import ToggleSwitch from '../components/UI/Toggle';
import { requestTypeName } from '../additional';

const InsuranceCompaniesPage = () => {
    const { user } = useSelector(state => state.auth);
    //const { } = useSelector(state => state.dictionaries);
    const dispatch = useDispatch();
    const uploadRef = useRef();
    const uploadCondotionRef = useRef([]);

    const [isLoading, setIsLoading] = useState(false);
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const [insuranceTypesList, setInsuranceTypesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    const cashBack = [
        {
            id: 0,
            value: 'Нет'
        },
        {
            id: 1,
            value: 'Сумма'
        },
        {
            id: 2,
            value: 'Процент'
        },
    ];

    const cashBackInitialData = {
        type: 0,
        value: 0
    };

    const insuranceInitialState = {
        id: null,
        name: '',
        requestTypes: [],
        cashBack: cashBackInitialData,
        haveFranchise: false,
        franchiseSizes: []
    };

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {

            try {
                setError(null);
                setIsLoading(true);

                const itemList = await (
                    await fetch(
                        `api/dictionary/insurances`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "69420",
                        }
                    }
                    )
                ).json();

                // set state when the data received
                setInsuranceCompanies(itemList);
                //console.log("insurances", itemList);

                const requestTypes = await (
                    await fetch(
                        `api/dictionary/requestTypes`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "69420",
                        }
                    })
                ).json();

                setInsuranceTypesList(requestTypes);
                //console.log()
            } catch (e) {
                setError(e);
            };

            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const onChangeNameHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, name: value };
            return item;
        });
    };

    const onSetRequestTypeHandler = (requestTypeId) => {
        setModalItem(state => {
            let item = { ...state };

            if (state.requestTypes?.find((i) => i.requestTypeId === requestTypeId)) {
                item.requestTypes = [...state.requestTypes?.filter((i) => i.requestTypeId !== requestTypeId)];
            } else {
                item.requestTypes.push({
                    ...insuranceTypesList.find(i => i.requestTypeId === requestTypeId),
                    cashBack: { type: 0, value: 0 },
                    haveFranchise: false,
                    franchiseSizes: []
                });
            }

            return item;
        });
    };

    const onSetCashBackType = ({ selectedTypeId, value }) => {
        setModalItem(state => {
            let item = { ...state };
            //console.log('RT: ', state.requestTypes?.find((i) => i.requestTypeId === selectedTypeId));
            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === selectedTypeId);
            item.requestTypes = [...state.requestTypes];
            item.requestTypes[index].cashBack.type = value;

            return item;
        });
    };

    const onChangeCashBackHandler = ({ selectedTypeId, value }) => {
        setModalItem(state => {
            let item = { ...state };
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === selectedTypeId);
            item.requestTypes = [...state.requestTypes];
            item.requestTypes[index].cashBack.value = v;

            return item;
        });
    };

    const onClickHaveFranchiseHandler = (selectedTypeId) => {

        setModalItem(state => {
            let item = { ...state };

            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === selectedTypeId);
            item.requestTypes = [...state.requestTypes];
            item.requestTypes[index].haveFranchise = !item.requestTypes[index].haveFranchise;
            if (item.requestTypes[index].haveFranchise === false)
                item.requestTypes[index].franchiseSizes = [];

            return item;
        });
    };

    const getNextId = (items) => {
        let maximum = 0;

        items.forEach((item) => {
            if (item.id > maximum)
                maximum = item.id;
        });

        return maximum + 1;
    };

    const onAddFranchizeHandler = (selectedTypeId) => {
        setModalItem(state => {
            let item = { ...state };
            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === selectedTypeId);
            item.requestTypes = [...state.requestTypes];
            item.requestTypes[index].franchiseSizes.push({
                id: getNextId(item.requestTypes[index].franchiseSizes),
                size: 0,
                edit: true
            });

            return item;
        });
        //setNewItem(state => [...state, { id: getNextId(state), size: 0, edit: true }]);
    };

    const onChangeFramnchizeHandler = ({ value, id, requestTypeId }) => {

        setModalItem(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            let item = { ...state };
            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === requestTypeId);
            item.requestTypes = [...state.requestTypes];
            item.requestTypes[index].franchiseSizes.find(x => x.id === id).size = v;

            return item;
        });

    };

    const cancelDeleteFranchizeHandler = ({id, requestTypeId}) => {

        setModalItem(state => {

            let item = { ...state };
            let index = item.requestTypes?.findIndex((i) => i.requestTypeId === requestTypeId);
            item.requestTypes = [...state.requestTypes];

            //console.log(item.requestTypes[index].franchiseSizes, item.requestTypes[index].franchiseSizes.filter(x => x.id !== id));

            item.requestTypes[index].franchiseSizes = item.requestTypes[index].franchiseSizes.filter(x => x.id !== id);

            return item;
        });

    };


    const onAddHandler = () => {
        setModalItem({ ...insuranceInitialState });
        setShowModal(true);
    };

    const saveInsuranceConditionFileName = (condition, data) => {
        console.log(condition, data);

        const saveFileName = async () => {
            await fetch(`api/additional/SaveInsuranceConditionFileName`, {
                method: 'POST',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify(condition)
            })
        } 

        saveFileName();
        //dispatch(({ ...condition, insuranceConditionFile: data.fileName }))
        

    };

    const onSaveHandler = () => {
        const bankId = null;

        const saveData = async () => {
            //console.log(modalItem);
            //console.log(JSON.stringify({ ...modalItem }));

            await dispatch(checkAuth(user));

            try {
                setError(null);
                setIsLoading(true);

                const updated = await (
                    await fetch(`api/insurance`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user.accessToken}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: JSON.stringify({ ...modalItem })
                    })
                ).json();

                setInsuranceCompanies(state => {
                    let finded = insuranceCompanies.find((item) => item.id === modalItem.id);
                    let item = null;

                    if (!finded) {
                        item = { ...modalItem };
                        item.id = updated.id;
                    } else {
                        item = { ...finded };
                        //item.requestTypes = [...modalItem.requestTypes];
                        item.requestTypes = modalItem.requestTypes.map(x => {
                            return {
                                ...x,
                                haveFranchise: x.franchiseSizes.length === 0 ? false : true,
                                franchiseSizes: x.franchiseSizes.map(f => {
                                    return { ...f, edit: false };
                                })
                            }
                        });
                        //item.haveFranchise = modalItem.haveFranchise;
                        //item.franchiseSizes = [...modalItem.franchiseSizes];
                    }

                    let newList = [item, ...insuranceCompanies.filter(item => item.id !== modalItem.id)];

                    newList.sort(function (a, b) {
                        if (a.name > b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });

                    return newList;
                });

            } catch (e) {
                setError(e);
            };

            setShowModal(false);
            setIsLoading(false);
            setModalItem(null);
        };

        saveData();
    };

    const noCancelHandler = () => {
        setModalItem(null);
        setShowModal(false);
    };

    const onEditHandler = (id) => {
        const item = { ...insuranceCompanies.find((item) => item.id === id) };

        //let item = { ...selectedItem };
        item.requestTypes = [...item.requestTypes];

        setModalItem(item);
        setShowModal(true);
    };

    const onDeleteHandler = (id) => {
        const deleteData = async () => {

            await dispatch(checkAuth(user));
            //console.log(modalItem);
            try {
                setError(null);
                setIsLoading(true);

                const result = await (
                    await fetch(`api/insurance/${id}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user.accessToken}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                ).json();

                if (result.status === "Успешно") {
                    setInsuranceCompanies(state => {
                        let newList = [...insuranceCompanies.filter(item => item.id !== id)];
                        return newList;
                    });
                } else {
                    setError(result);
                }

            } catch (e) {
                setError(e);
            };

            setIsLoading(false);
        };

        deleteData();
    };
    //useEffect(() => {
    //    console.log(modalItem);
    //}, [modalItem]);

    return (
        <>
            <ModalDialog
                title="Редактирование/Добавление страховой компании"
                onSave={onSaveHandler}
                onCancel={noCancelHandler}
                showModal={showModal}
                isLoading={isLoading}
            >
                <div className='modal-container'>
                    <div className='input-header'>
                        <div className='name-insurances-company'>
                            Название страховой компании
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Upload
                                key={modalItem?.id}
                                ref={(element) => { uploadRef.current = element }}
                                onUpload={(e) => {
                                    setModalItem(state => {
                                        let newState = { ...state, logo: e.fileName };
                                        return newState;
                                    })
                                    setInsuranceCompanies(state => {
                                        let newList = [...insuranceCompanies];
                                        let item = newList.find(item => item.id === modalItem?.id);
                                        item.logo = e.fileName;
                                        return newList;
                                    });
                                }} //setUrl //updateFileName(modalItem, e)
                                //fileType={console.log(modalItem?.requestTypeId)}
                                fileType={modalItem?.id}
                                uploadUrl="api/additional/UploadInsuranceLogo"
                            >
                                {<img onClick={() => uploadRef.current.upload()} style={{ width: 32, height: 32, marginRight: 16, cursor: "pointer" }} src={!modalItem?.logo ? "no_logo.png" : ("api/additional/file/" + modalItem?.logo)} alt="" />}
                            </Upload>
                            <Input
                                defaultValue={modalItem?.name ?? ""}
                                onChange={(element) => onChangeNameHandler(element.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='wrapper-header'>Виды страхования</div>
                <div className='type-wrapper'>
                    
                    {insuranceTypesList.map((item, index) => {
                        const { requestTypeId, description, haveFranchise } = item;
                        const selectedType = modalItem?.requestTypes?.find((i) => i.requestTypeId === requestTypeId);

                        return (
                            <div className='insurance-additional' key={requestTypeId}>
                                <div className='request-type-selector'>
                                    <div className='name-insurances'>{description}</div>
                                    
                                        {/* // <MdToggleOn size={28} color='#EC7236' onClick={() => onSetRequestTypeHandler(requestTypeId)} />
                                        // :
                                        // <MdToggleOff size={28} color='gray' onClick={() => onSetRequestTypeHandler(requestTypeId)} /> */}
                                        <ToggleSwitch
                                        value = {selectedType}
                                        onChange={() => onSetRequestTypeHandler(requestTypeId)}
                                        colorGreen={true}
                                        />
                                    
                                </div>
                                {selectedType &&
                                    <>
                                        <div className='cashback-config'>
                                            <div className='cashback-select'>
                                                <label htmlFor='cashBack'>Кэшбэк</label>
                                                <select name="cashBack" id="cashBack"
                                                    className='cashBack-select'
                                                    //value={bankBeneficiary ? banks.find((bank) => bank.id === bankBeneficiary).id : 'select'}
                                                    value={selectedType.cashBack.type}
                                                    onChange={(e) => onSetCashBackType({
                                                        selectedTypeId: requestTypeId,
                                                        value: parseInt(e.target.value)
                                                    })}
                                                >
                                                    {cashBack.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id}>{item.value}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {selectedType?.cashBack.type !== 0 &&
                                                <div className='cashback-data'>
                                                    <label htmlFor='cashback-data'>Значение</label>
                                                    <div className='input-cashback-data'>
                                                    <Input
                                                        type='number'
                                                        name='cashback-data'
                                                        value={selectedType?.cashBack.value === 0 ? "" : selectedType?.cashBack.value}
                                                        //{/*defaultValue={modalItem?.name ?? ""}*/}
                                                        onChange={(e) => onChangeCashBackHandler({
                                                            selectedTypeId: requestTypeId,
                                                            value: e.target.value
                                                        })}
                                                    />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <CheckBox id={requestTypeId} name="Есть франшиза" onChange={() => onClickHaveFranchiseHandler(requestTypeId)} value={selectedType?.haveFranchise} />
                                        {selectedType?.haveFranchise === true &&
                                            <FranchiseList
                                                list={selectedType?.franchiseSizes}
                                                requestTypeId={requestTypeId}
                                                onAddHandler={onAddFranchizeHandler}
                                                onChangeHandler={onChangeFramnchizeHandler}
                                                onDeleteHandler={cancelDeleteFranchizeHandler}
                                        />}

                                        {console.log("MM ",modalItem, selectedType.insuranceCondition)}

                                        <Upload
                                            key={requestTypeId}
                                            ref={(element) => { uploadCondotionRef.current[index] = element }}
                                            onUpload={(e) => {
                                                let condition = { ...selectedType.insuranceCondition };
                                                condition.insuranceCompanyId = modalItem.id;
                                                condition.requestTypeId = requestTypeId;
                                                condition.insuranceConditionFile = e.fileName;

                                                saveInsuranceConditionFileName(condition, e);
                                                //setModalItem(state => {
                                                //    let newState = { ...state, logo: e.fileName };
                                                //    return newState;
                                                //})
                                                //setInsuranceCompanies(state => {
                                                //    let newList = [...insuranceCompanies];
                                                //    let item = newList.find(item => item.id === modalItem?.id);
                                                //    item.logo = e.fileName;
                                                //    return newList;
                                                //});
                                            }} //setUrl //updateFileName(modalItem, e)
                                            //fileType={console.log(modalItem?.requestTypeId)}
                                            fileType={"insurance_conditions"}
                                            uploadUrl="api/additional/UploadAnyFile"
                                        >
                                        {
                                            <div
                                                style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: "100%", cursor: 'pointer', padding: 8 }}
                                                onClick={() => uploadCondotionRef.current[index].upload()}
                                            >
                                                <div style={{ position: 'absolute', width: "100%", height: "100%" }}>
                                                    {selectedType?.insuranceCondition?.insuranceConditionFile ?
                                                        <div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'green', borderRadius: 3, overflow: 'hidden' }} />
                                                        :
                                                        <>{item.required && < div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'red', borderRadius: 3, overflow: 'hidden' }} />}</>
                                                    }
                                                </div>
                                                <div className='icon-clip'><Icon name="clip" /></div>

                                                <div>Условия страхования</div>
                                            </div>
                                            //img onClick={() => uploadRef.current.upload()} style={{ width: 32, height: 32, marginRight: 16, cursor: "pointer" }} src={!modalItem?.logo ? "no_logo.png" : ("api/additional/file/" + modalItem?.logo)} alt="logo" />
                                        }
                                    </Upload>
                                    </>
                                }
                            </div>
                        );
                    })}
                </div>
            </ModalDialog>
            <div className='banks-container'>
                <div className='container-header'>
                    <div className='container-header-name'>Справочник страховых компаний</div>
                    {/* // <div className='container-button'><StyledButton name= <div className='plus-add-name'><Icon name = "plus"/>Добавить</div> onClickHandler={onAddHandler} /></div>} */}
                    {selectedItem ?
                    <>
                    <div className='container-btn'>
                        <StyledButton 
                            className={'btn-red'} 
                            onClickHandler={() => onDeleteHandler(selectedItem.id)} 
                            name= <div className='icon-text'><Icon name = 'delete'/>Удалить</div> 
                        /> 
                        <StyledButton onClickHandler={() => onEditHandler(selectedItem.id)} name= <div className='icon-text'><Icon name = 'pencil'/>Редактировать</div> />
                    </div>
                    </>
                    :
                    <>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div> onClickHandler={onAddHandler}/>
                    </>}
                </div>
                <div className='banks-table'>
                    {isLoading && <Spinner />}
                    <div className='insurance-header'>
                        <div className='insurance-id'>ID</div>
                        <div className='insurance-name'>Компания</div>
                        {/* <div className='actions'>Действия</div> */}
                    </div>
                    {insuranceCompanies.length === 0 ?
                        <div className='no-table-data'>Страховые компании еще не добавлены</div>
                        :
                        insuranceCompanies.map((item, index) => {
                            return (
                                <InsuranceRow
                                    key={index}
                                    item={item}
                                    onEdit={onEditHandler}
                                    onDeleteHandler={onDeleteHandler}
                                    onSelectHandler={onSelectHandler}
                                    selectedItem={selectedItem}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default InsuranceCompaniesPage;

const FranchiseList = ({ list, requestTypeId, onDeleteHandler = () => { }, onAddHandler = () => { }, onChangeHandler = () => { } }) => {

    return (<div className='big-container-franchise'>
        <div>Условия франшизы</div>
        <div className='franchise-container'>
            <div className='franchise-size-input-container'>
                {list?.length === 0 && <>Условия франшизы не добавлены</>}
                {list && list.map((item, index) => {
                    return (
                        <div className='franchise-size-input' key={item.id}>
                            {item.edit ?
                                <Input className='franchise-size-value' value={item.size}
                                    onChange={(element) => onChangeHandler({
                                        requestTypeId: requestTypeId,
                                        value: element.target.value,
                                        id: item.id
                                    })}
                                />
                                :
                                <div className='franchise-size-value'>
                                    {item.size}
                                </div>
                            }
                            <div className='franchise-actions'>
                                <MdClear size={22} color='red'
                                    onClick={() => onDeleteHandler({
                                        id: item.id,
                                        requestTypeId: requestTypeId
                                    })} />
                            </div>
                        </div>
                    );
                })}
            </div>
            
        </div>
        <div>
            <StyledButton className='text-button' name="Добавить условие" onClickHandler={() => onAddHandler(requestTypeId)} />
        </div>
        </div>
    );
};

const InsuranceRow = ({ item, onEdit, selectedItem, onSelectHandler }) => {
    const { id, name, additionalData, logo } = item;
    return (
        <div className={"banks-row " + (selectedItem && selectedItem.id === id ? "selected" : "")}
             onClick={() => onSelectHandler(item)} 
             onDoubleClick={() => onEdit(id)}
             key={item.id}>
            <div className='bank-id'>{id}</div>
            <div className='bank-id'>{<img style={{ width: 32, height: 32 }} src={!logo ? "no_logo.png" : ("api/additional/file/"+logo)} alt="" />}</div>
            <div className='bank-name'>
                <div className='name'>{name}</div>
                <div className=''>
                    {item.requestTypes?.map((type) => {
                        //console.log('TYpe', type);
                        return (
                            <div className='type' key={type.requestTypeId}>{type.description}
                            {/* <div className='cashback'> {cashBackTypeName(type.cashBack.type)}{type.cashBack.type === 0 ? "" : (" = " + type.cashBack.value)}</div> */}
                                <div className='cashback'>
                                    {cashBackTypeName(type.cashBack.type)}
                                    {type.cashBack.type === 0 ? "" : <span className={`cashback-value cashback-value-${type.cashBack.type}`}> = {type.cashBack.value}</span>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <div className='actions'>
                <MdEditDocument size={26} color='green' className='icon-action' onClick={() => onEdit(id)} />
                <MdDelete size={26} color='red' className='icon-action' onClick={() => onDelete(id)} />
            </div> */}
        </div>

    );
}

// const cashBackTypeName = (typeId) => {
//     switch (typeId) {
//         case 0:
//             return "Нет";
//         case 1:
//             return "Σ";
//         case 2:
//             return "%";
//         default:
//             return "";
//     }
// };
const cashBackTypeName = (typeId) => {
    switch (typeId) {
        case 0:
            return <span className="cashback-no">Нет</span>;
        case 1:
            return <span className="cashback-sigma">Σ</span>;
        case 2:
            return <span className="cashback-percentage">%</span>;
        default:
            return "";
    }
};

//<div className='insurance-row'>
//    <div className='insurance-data'>
//        <div className='insurance-id'>{id}</div>
//        <div className='insurance-name'>{name}</div>
//        <div className='actions'>
//            <MdEditDocument size={26} color='green' className='icon-action' onClick={() => onEdit(id)} />
//            <MdDelete size={26} color='red' className='icon-action' onClick={() => onDelete(id)} />
//        </div>
//    </div>
//    <div className='insurance-additional-data'>
//        <div>Тип страхования</div>
//        <div>Тип кэшбэка</div>
//        <div>Параметр кэшбэка</div>
//    </div>
//</div>