import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default useWindowDimensions;
//import { useSyncExternalStore } from 'react';

//export function useWindowDimensions() {
//    // the 3rd parameter is optional and only needed for server side rendering
//    //return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
//    return useSyncExternalStore(subscribe, getSnapshot);
//}

//function subscribe(callback) {
//    window.addEventListener('resize', callback);
//    return () => window.removeEventListener('resize', callback);
//}

//function getSnapshot() {
//    return { width: window.innerWidth, height: window.innerHeight };
//}

//function getServerSnapshot() {
//    return {
//        width: 0,
//        height: 0,
//    };
//}
