import React, { useState, useEffect } from 'react';

import PageHeader from '../../components/PageHeader';
import { Spinner } from '../../components/UI';
//import MyFriends from '../tg/MyFriends';
import { useNavigate, useParams } from 'react-router-dom';

import { toCurrency, stageName, requestTypeName } from '../../additional';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFriendsData } from '../../store/profileSlice';
import { fetchClients } from '../../store/clientSlice';
import TgInsuranseSmallData from '../tg/TgInsuranseSmallData';

import { ClientInfoPage, ClientTicketsPage, ClientChatPage } from './';

import './ClientPage.modules.css';
import { fetchInsurancesDictionary } from '../../store/dictionarySlice';

const clientPages = [
    {
        id: 1,
        name: "Общая информация",
    },
    {
        id: 2,
        name: "Тикеты",
    },
    {
        id: 3,
        name: "Друзья",
    },
    {
        id: 4,
        name: "Текущие кэшбеки"
    },
    {
        id: 5,
        name: "История выплат"
    },
    {
        id: 6,
        name: "Чаты"
    },
];

const ClientFriends = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const { friendsData } = useSelector(state => state.profile);
   
    const { list } = useSelector(store => store.clients);
    const item = list.find(x => x.id === parseInt(id));

    useEffect(() => {
        if (item) {
            //console.log("download friends ", item?.telegramId);
            dispatch(fetchFriendsData(item?.telegramId));
            dispatch(fetchInsurancesDictionary());
        }

    }, [dispatch, item]);



    useEffect(() => {
        if (list && list.length === 0) {
            setIsLoading(true);
            dispatch(fetchClients())
                .then(() => {
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    console.error(e);
                });
        };
    }, [list]);
    console.log("CLIENT ID ", friendsData);

    return (
        <>
            {friendsData.map(friend => {
                return (
                    <Friend data={friend} key={friend.id} />
                );
            })}
        </>    
    );
}

const Friend = ({ data }) => {

    const [showInsurances, setShowInsurances] = useState(false);
    const { clientName, cashBackSumm, friendInsurances } = data;
    const { insurances } = useSelector(store => store.dictionaries);

    return (
        <>
            <div className='friend-container'>

                <div style={{ fontSize: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 16, alignItems: 'center' }}>
                    <div><b>{clientName}</b></div>
                    <div>Кол-во полисов: <b>{friendInsurances.length}</b></div>
                    <div>Общий кэшбек по другу: <b>{toCurrency(cashBackSumm, 'RUB', 'Ru-ru')}</b></div>
                </div>



                {friendInsurances.length > 0 &&
                    <>
                        <div className="tg-delimitator" />

                    <div>Информация по страхованиям</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {friendInsurances.map(insurance => {
                            //console.log("CBI: ", insurance);
                            return (
                                <TgInsuranseSmallData key={insurance.id} data={{
                                    insuranceType: requestTypeName(insurance.requestTypeId),
                                    insuranceCompany: insurances?.find(x => x.id === insurance.insuranceCompanyId)?.name,
                                    period: "",
                                    summ: insurance.insuranceSumm,
                                    cashBack: insurance.cashBackSumm,
                                    number: insurance.insuranceNumber
                                }} />
                            )
                        })}
                    </div>

                    </>
                }

            </div>

        </>
    );
};


const SubPageComponent = ({ pageId, data = null }) => {
    //return <></>
    switch (pageId) {
        case 1:
            return <ClientInfoPage />
        case 2:
            return <ClientTicketsPage />
        case 3:
            return <ClientFriends />
        case 6:
            return <ClientChatPage />
        default:
            return <>{pageId}</>
    };
};

const ClientPage = () => {

    const [currentPageId, setCurrenPageId] = useState(clientPages[0].id);

    return (
        <>
            <PageHeader
                name="Данные клиента"
            />
            <div className='ticket-page-selector'>
                {clientPages.map((page) => {
                    return (
                        <div
                            key={page.id}
                            className={'sub-page-name ' + (currentPageId === page.id ? "active" : "")}
                            onClick={() => setCurrenPageId(page.id)}
                        >
                            {page.name}
                        </div>
                    );
                })}
            </div>

            <SubPageComponent pageId={currentPageId} />
        </>
    );
};

export default ClientPage;