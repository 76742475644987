import React, { useState } from 'react';
import { Icon } from '../../components/UI';

const OfferComment = ({ text, secondary }) => {
    const [collapsed, setCollapsed] = useState(true);
    //console.log("text", text.trim().length, text.length);
    if (!text || text.trim().length === 0)
        return <></>;

    return (
        <div className={"offerComment " + (secondary ? "commentSecondary" : "commentFirst")}>
            
            {!secondary &&
                <>
                    <Icon name="message-circle-lines" className="commentIcon"/>
                    <div className={"offerCommentHeader " + (secondary ? "commentSecondary" : "commentFirst")}>
                        Комментарий от менеджера
                    </div>
                </>
            }
            {text &&
                <div className={"offerCommentText " + (collapsed ? "collapsed" : "")}>
                    {text}
                </div>
            }
            <div onClick={() => setCollapsed(!collapsed)} className={"offerCommentButton " + (secondary ? "commentSecondary" : "commentFirst")}>
                {collapsed ? <>Развернуть<Icon name="chevron-down" /></> : <>Свернуть<Icon name="chevron-up" /></>}
                
            </div>
        </div>
    );
};

export default OfferComment;