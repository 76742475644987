import ClientPage from './ClientPage';
import ClientInfoPage from './ClientInfoPage';
import ClientTicketsPage from './ClientTicketsPage';
import ClientChatPage from './ClientChatPage';

export default ClientPage;

export {
    ClientPage,
    ClientInfoPage,
    ClientTicketsPage,
    ClientChatPage
}