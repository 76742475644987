import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom";
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

//import { Input } from 'reactstrap';

import { StyledButton, CheckBox, Spinner, Input } from '../components/UI';
import { login, checkAuth, setIsLoadingFalse, setRememberMe } from '../store/authSlice';


import { ReactComponent as Logo } from '../assets/logo.svg';

const LoginPage = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isAuth, isLoading, rememberMe } = useSelector(state => state.auth);
    const { pathname } = useLocation();

    //console.log(useSelector(state => state.auth));

    const onAuthHandler = () => {
        dispatch(login({ email, password }));
    };

    useEffect(() => {
        if (sessionStorage.getItem("insurance_auth_data")) {
            dispatch(checkAuth(JSON.parse(sessionStorage.getItem("insurance_auth_data"))));
        } else if (localStorage.getItem("insurance_auth_data")) {
            dispatch(checkAuth(JSON.parse(localStorage.getItem("insurance_auth_data"))));
        } else {
            dispatch(setIsLoadingFalse());
        };
    }, [dispatch]);

    if (isAuth === true && pathname === "/login") {
        return (
            <Navigate to="/" replace={true} />
        )
    }

    return (
        <>
            {(!isLoading && !isAuth && !localStorage.getItem("insurance_auth_data")) ?

                <div className="parent">
                    <div className="block">
                        <>
                            <div className='login-page-header-container'>
                                <Logo className='logo' />
                                <div className='login-header-text'>СТРАХОВАНИЕ</div>
                            </div>

                            <Input id='email' name='E-mail' type='email' placeholder='Введите e-mail' value={email} onChange={e => setEmail(e.target.value)} />
                            <Input id='password' name='Пароль' type='password' placeholder='Введите пароль' value={password} onChange={e => setPassword(e.target.value)} />

                            <div className='login-additional'>
                                <CheckBox
                                    id='remember-me'
                                    value={rememberMe}
                                    name='Запомнить меня'
                                    onChange={() => dispatch(setRememberMe())}
                                />
                                <div className='forgot-password'>Забыли пароль?</div>
                            </div>
                            <StyledButton className="auth-button" name='Войти' onClickHandler={onAuthHandler} />
                        </>

                    </div>
                </div>
                :
                <Spinner />
            }
        </>
    );
};

export default LoginPage;
