import { useSelector } from 'react-redux';
import { KaskoOfferItem, OsagoOfferItem, IpotekaOfferItem, IflOfferItem } from './';

const InsuranceOfferItem = ({
    type,
    offers,
    onAddOffer,
    onRemoveInsurance,
    onRemoveOffer,
    onChangeOffer,
    isNewOffer
}) => {

    const { ticketDatas } = useSelector(store => store.tickets.item);

    //console.log("!!!", ticketDatas);

    switch (type) {
        case 0:
            return (
                <>
                    {offers?.insuranceCompanies.map((item) =>
                        <KaskoOfferItem
                            key={item.id}
                            insuranceItem={item}
                            onAddOffer={onAddOffer}
                            onRemoveInsurance={onRemoveInsurance}
                            onRemoveOffer={onRemoveOffer}
                            onChangeOffer={onChangeOffer}
                            isNewOffer={isNewOffer}
                        />
                    )}
                </>
            );
        case 1:
            return (
                <div className="company-offers small">
                    {offers?.insuranceCompanies.map((item) =>
                        <OsagoOfferItem
                            key={item.id}
                            insuranceItem={item}
                            onAddOffer={onAddOffer}
                            onRemoveInsurance={onRemoveInsurance}
                            onRemoveOffer={onRemoveOffer}
                            onChangeOffer={onChangeOffer}
                            isNewOffer
                        />
                    )}
                </div>
            );
        case 2:
            return (
                <div className="company-offers small">
                    {offers?.insuranceCompanies.map((item) => {
                        let iflOfferItem = item;
                        //console.log("iflOfferItem: ", iflOfferItem);

                        const houseData = ticketDatas.filter(e => e.secondaryKey.startsWith("Дом#") === true);
                        if (houseData.length > 0) {
                            const houseConstructiveSumm = houseData.find(e => e.key === "Конструктив" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (houseConstructiveSumm)
                                iflOfferItem.offers[0].houseInsuranceAmount.constructiveSumm = iflOfferItem.offers[0].houseInsuranceAmount.constructiveSumm ?? houseConstructiveSumm.data;
                            const houseFinishingSumm = houseData.find(e => e.key === "Отделка" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (houseFinishingSumm)
                                iflOfferItem.offers[0].houseInsuranceAmount.finishingSumm = iflOfferItem.offers[0].houseInsuranceAmount.finishingSumm ?? houseFinishingSumm.data;
                            const houseMovablePropertySumm = houseData.find(e => e.key === "Движимое имущество" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (houseMovablePropertySumm)
                                iflOfferItem.offers[0].houseInsuranceAmount.movablePropertySumm = iflOfferItem.offers[0].houseInsuranceAmount.movablePropertySumm ?? houseMovablePropertySumm.data;
                        } else iflOfferItem.offers[0].houseInsuranceAmount = null;

                        const apartmentData = ticketDatas.filter(e => e.secondaryKey.startsWith("Квартира#") === true);
                        if (apartmentData.length > 0) {
                            const apartmentConstructiveSumm = apartmentData.find(e => e.key === "Конструктив" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (apartmentConstructiveSumm)
                                iflOfferItem.offers[0].apartmentInsuranceAmount.constructiveSumm = iflOfferItem.offers[0].apartmentInsuranceAmount.constructiveSumm ?? apartmentConstructiveSumm.data;
                            const apartmentFinishingSumm = apartmentData.find(e => e.key === "Отделка" && e.secondaryKey.endsWith("#Страховая сумма"))
                            if (apartmentFinishingSumm)
                                iflOfferItem.offers[0].apartmentInsuranceAmount.finishingSumm = iflOfferItem.offers[0].apartmentInsuranceAmount.finishingSumm ?? apartmentFinishingSumm.data;
                            const apartmentMovablePropertySumm = apartmentData.find(e => e.key === "Движимое имущество" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (apartmentMovablePropertySumm)
                                iflOfferItem.offers[0].apartmentInsuranceAmount.movablePropertySumm = iflOfferItem.offers[0].apartmentInsuranceAmount.movablePropertySumm ?? apartmentMovablePropertySumm.data;
                        } else iflOfferItem.offers[0].apartmentInsuranceAmount = null;

                        const bathData = ticketDatas.filter(e => e.secondaryKey.startsWith("Баня#") === true);
                        if (bathData.length > 0) {
                            const bathConstructiveSumm = bathData.find(e => e.key === "Конструктив" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (bathConstructiveSumm)
                                iflOfferItem.offers[0].bathInsuranceAmount.constructiveSumm = iflOfferItem.offers[0].bathInsuranceAmount.constructiveSumm ?? bathConstructiveSumm.data;
                            const bathFinishingSumm = bathData.find(e => e.key === "Отделка" && e.secondaryKey.endsWith("#Страховая сумма"))
                            if (bathFinishingSumm)
                                iflOfferItem.offers[0].bathInsuranceAmount.finishingSumm = iflOfferItem.offers[0].bathInsuranceAmount.finishingSumm ?? bathFinishingSumm.data;
                            const bathMovablePropertySumm = bathData.find(e => e.key === "Движимое имущество" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (bathMovablePropertySumm)
                                iflOfferItem.offers[0].bathInsuranceAmount.movablePropertySumm = iflOfferItem.offers[0].bathInsuranceAmount.movablePropertySumm ?? bathMovablePropertySumm.data;
                            //console.log(bathConstructiveSumm, bathFinishingSumm, bathMovablePropertySumm);
                        } else iflOfferItem.offers[0].bathInsuranceAmount = null;

                        const garageData = ticketDatas.filter(e => e.secondaryKey.startsWith("Гараж#") === true);
                        if (garageData.length > 0) {
                            const garageConstructiveSumm = garageData.find(e => e.key === "Конструктив" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (garageConstructiveSumm)
                                iflOfferItem.offers[0].garageInsuranceAmount.constructiveSumm = iflOfferItem.offers[0].garageInsuranceAmount.constructiveSumm ?? garageConstructiveSumm.data;
                            const garageFinishingSumm = garageData.find(e => e.key === "Отделка" && e.secondaryKey.endsWith("#Страховая сумма"))
                            if (garageFinishingSumm)
                                iflOfferItem.offers[0].garageInsuranceAmount.finishingSumm = iflOfferItem.offers[0].garageInsuranceAmount.finishingSumm ?? garageFinishingSumm.data;
                            const garageMovablePropertySumm = garageData.find(e => e.key === "Движимое имущество" && e.secondaryKey.endsWith("#Страховая сумма"));
                            if (garageMovablePropertySumm)
                                iflOfferItem.offers[0].garageInsuranceAmount.movablePropertySumm = iflOfferItem.offers[0].garageInsuranceAmount.movablePropertySumm ?? garageMovablePropertySumm.data;
                        } else iflOfferItem.offers[0].garageInsuranceAmount = null;


                        //console.log("DATA: ", iflOfferItem);
                        return (
                            <IflOfferItem
                                key={item.id}
                                insuranceItem={iflOfferItem}
                                onAddOffer={onAddOffer}
                                onRemoveInsurance={onRemoveInsurance}
                                onRemoveOffer={onRemoveOffer}
                                onChangeOffer={onChangeOffer}
                                isNewOffer
                            />
                        )}
                    )}
                </div>
            )
        case 3:
            return (
                <div className="company-offers small">
                    {offers?.insuranceCompanies.map(item => {
                        return (
                            <IpotekaOfferItem
                                key={item.id}
                                insuranceItem={item}
                                onAddOffer={onAddOffer}
                                onRemoveInsurance={onRemoveInsurance}
                                onRemoveOffer={onRemoveOffer}
                                onChangeOffer={onChangeOffer}
                                isNewOffer
                            />

                        )}
                    )}
                </div>
            )
            
        default:
            return <>Для данного типа еще нет возможности добавлять предложение</>
    }
};

export default InsuranceOfferItem;