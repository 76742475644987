import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'react-datepicker';

import { useTelegram } from "../../hooks/useTelegram";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

import { requestTypeName, clientTicketStatus, ticketOfferStatusName, designOptionTypeName, paymentTypeName, fileNameByFileType, toCurrency } from '../../additional';

import TgHeader from '../../components/TgHeader';
import { TicketDataItem } from '../TicketPage';
import { Icon, StyledButton, Button, CheckBox, RadioButton, Input } from '../../components/UI';

import { fetchTgClientTicket, saveTicketSelectedOfferValue, updateFileData, savePaymentAndDileveryOptions, changeTicketStage } from '../../store/ticketSlice';
import { fetchInsurancesDictionary, fetchRequestTypesDictionary, fetchInsutanceConditionsDictionary } from '../../store/dictionarySlice';

import Upload, { upload } from '../../components/Upload/Upload';
import TgInsuranseSmallData from './TgInsuranseSmallData';
import OfferComment from './OfferComment';

const MyTicketData = () => {

    const { tg, user } = useTelegram();

    const ticketMenu = [
        "Общие данные",
        "Предложения",
        "Оформление",
        "Полис",
        "Завершение"
    ];

    const { height, width } = useWindowDimensions();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const main_menu = searchParams.get("main_menu");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, ticketId } = params;
    const openPage = searchParams.get('page');
    //alert(searchParams.get('page'));
    const [selected, setSelected] = useState(ticketMenu[openPage ? openPage : 0]);
    const [treeItems, setTreeItems] = useState([]);
    const [banks, setBanks] = useState([]);
    const [error, setError] = useState(null);
    //const [isLoading, setIsLoading] = useState(false);
    const { item: data } = useSelector(store => store.tickets);

    const [insuranceDescriptions, setInsuranceDescriptions] = useState([]);
    //const { banks } = useSelector(state => state.dictionaries);

    const { insurances, requestTypes, insutanceConditions } = useSelector(store => store.dictionaries);
    console.log("insuranceConditions", insutanceConditions);
    //console.log(requestTypes.find(x => x.requestTypeId === data?.requestTypeId) , requestTypes, insurances);
    const { fileNameInsuranceDescription, linkToInsuranceStatistic } = requestTypes.find(x => x.requestTypeId === data?.requestTypeId) ?? {};
    //const 

    const [selectedInsurance, setSelectedInsurance] = useState(null);
    //
    const [isDivOpen, setDivOpen] = useState(false);    // состояния для кнопки more которая открывает див с доп кнопками
    const [tgFormActive, setTgFormActive] = useState(false);


    const toggleDiv = () => {
        setDivOpen(!isDivOpen);
        setTgFormActive((prevValue) => !prevValue);
    };
    //const { offerDate, statusChangeDate, commercialOfferStatus, kaskoInsuranceCompanyOffers } = data;
    const onClickBackButtonHandler = () => {
        navigate(-1);
        //navigate(`/tg/Profile/${tg_user_id}`);
    };

    useEffect(() => {
        tg.ready();

        if (main_menu)
            tg.BackButton.show();

        tg.onEvent('backButtonClicked', onClickBackButtonHandler);
        return () => {
            tg.offEvent('backButtonClicked')
        }
    }, [tg, main_menu]);

    const getSecondaryDescription = (data) => {
        let text = "";

        //if (data?.osagoCommercialOffers.length > 0)
        data?.osagoCommercialOffers?.forEach(i => text = text + " " + i.secondDescription);

        //if (data?.kaskoCommercialOffers.length > 0)
        data?.kaskoCommercialOffers?.forEach(i => text = text + " " + i.secondDescription);

        //if (data?.iflCommercialOffers.length > 0)
        data?.iflCommercialOffers?.forEach(i => text = text + " " + i.secondDescription);

        //if (data?.ipotekaCommercialOffers.length > 0)
        data?.ipotekaCommercialOffers?.forEach(i => text = text + " " + i.secondDescription);

        //console.log("DD ",data?.ipotekaCommercialOffers?.map(i => i.secondDescription + " "))
        return text;
    }


    const getFirstDescription = (data) => {
        let text = "";

        //if (data?.osagoCommercialOffers.length > 0)
        data?.osagoCommercialOffers?.forEach(i => text = text + " " + i.firstDescription);

        //if (data?.kaskoCommercialOffers.length > 0)
        data?.kaskoCommercialOffers?.forEach(i => text = text + " " + i.firstDescription);

        //if (data?.iflCommercialOffers.length > 0)
        data?.iflCommercialOffers?.forEach(i => text = text + " " + i.firstDescription);

        //if (data?.ipotekaCommercialOffers.length > 0)
        data?.ipotekaCommercialOffers?.forEach(i => text = text + " " + i.firstDescription);

        return text;
    }
    //console.log(kaskoInsuranceCompanyOffers);

    //const insuranceCompaniesIds = [...new Set(
    //    kaskoInsuranceCompanyOffers.map(item => item.insuranceCompanyId)
    //)];

    //const initialSelectedOffer = {
    //    ticketId: null,
    //    insuranceCompanyId: null,
    //    offerId: null,
    //    summ: null,//Если франшиза то null
    //    franchizeOfferId: null //Если не франшиза то null
    //};

    useEffect(() => {
        //console.log("insuranceDescriptions ", insuranceDescriptions);
        let descriptions = [];
        if (data && insutanceConditions) {
            data?.osagoCommercialOffers?.forEach(offers => {
                offers.osagoInsuranceCompanyOffers.forEach(item => {
                    //console.log(
                    //    "###OSAGO#### ",
                    //    data.requestTypeId,
                    //    item.insuranceCompanyId,
                    //    insutanceConditions,
                    //    insutanceConditions?.find(x => x.insuranceCompanyId === item.insuranceCompanyId),
                    //    !descriptions.find(e => e === item.insuranceCompanyId)
                    //);

                    if (!descriptions.find(e => e === item.insuranceCompanyId)
                        && insutanceConditions?.find(x => x.insuranceCompanyId === item.insuranceCompanyId && x.requestTypeId === data.requestTypeId)?.insuranceConditionFile)
                        descriptions.push(item.insuranceCompanyId);
                });
            });

            data?.kaskoCommercialOffers?.forEach(offers => {
                offers.kaskoInsuranceCompanyOffers.forEach(item => {

                    if (!descriptions.find(e => e === item.insuranceCompanyId) && insutanceConditions?.find(x => x.insuranceCompanyId === item.insuranceCompanyId && x.requestTypeId === data.requestTypeId)?.insuranceConditionFile)
                        descriptions.push(item.insuranceCompanyId);
                });
            });

            data?.iflCommercialOffers?.forEach(offers => {
                offers.iflInsuranceCompanyOffers.forEach(item => {

                    if (!descriptions.find(e => e === item.insuranceCompanyId) && insutanceConditions?.find(x => x.insuranceCompanyId === item.insuranceCompanyId && x.requestTypeId === data.requestTypeId)?.insuranceConditionFile)
                        descriptions.push(item.insuranceCompanyId);
                });
            });

            data?.ipotekaCommercialOffers?.forEach(offers => {
                offers.ipotekaInsuranceCompanyOffers.forEach(item => {

                    if (!descriptions.find(e => e === item.insuranceCompanyId) && insutanceConditions?.find(x => x.insuranceCompanyId === item.insuranceCompanyId && x.requestTypeId === data.requestTypeId)?.insuranceConditionFile)
                        descriptions.push(item.insuranceCompanyId);
                });
            });
            //console.log("===== ", descriptions);

        }

        setInsuranceDescriptions(descriptions);
    }, [data, insutanceConditions]);

    //console.log(banks);
    useEffect(() => {
        const downloadData = async () => {
            dispatch(fetchInsurancesDictionary());
            dispatch(fetchRequestTypesDictionary());
            dispatch(fetchInsutanceConditionsDictionary());
            //console.log("data down");
        };

        downloadData()
            .finally(() => {
                //console.log("data down 2");
                dispatch(fetchTgClientTicket(ticketId));
            });
        // .then(d => { })
        // .cath(e => alert(e.message));

        // .then(d => { })
        // .cath(e => alert(e.message));
    }, [ticketId]);

    useEffect(() => {
        const type = requestTypeName(data?.requestTypeId);

        const dataFetch = async () => {

            try {
                //setError(null);
                //setIsLoading(true);
                //if (process.env.NODE_ENV !== 'production')
                const loadedBanks = await (
                    await fetch(
                        `api/dictionary/${type}/banks`, {
                        method: "GET",
                        mode: 'cors',
                        //mode: 'no-cors',
                        headers: new Headers({
                            "ngrok-skip-browser-warning": "69420",
                            'Content-Type': 'application/json',
                        })
                    })
                ).json();

                const _loadedBanks = loadedBanks.map(item => { return { ...item, value: item.id, label: item.name } });
                //console.log("_loadedBanks: ", _loadedBanks);
                setBanks(_loadedBanks);
                //setError("banks downloadded");

            } catch (e) {
                setError(`api/dictionary/${type}/banks` + " - ошибка загрузки - " + e.message + ' - ' + e.stack);
            };

        };

        if (type)
            dataFetch();
        console.log("bamks");
    }, [data?.requestTypeId]);

    useEffect(() => {
        if (data) {
            const tree = [];

            data?.ticketDatas?.forEach(item => {
                const { key, secondaryKey } = item;

                //console.log(key, secondaryKey);

                if (!secondaryKey.includes("#")) {
                    if (!tree.find(o => o.key === secondaryKey)) {
                        const allElements = data.ticketDatas.filter(e => e.secondaryKey === secondaryKey);
                        //console.log('Единичное поле', allElements.length === 1,  secondaryKey);
                        if (allElements.length === 1) {
                            //это единичное поле
                            tree.push({
                                id: item.id,
                                key: secondaryKey,
                                data: item.data
                            });
                        } else {
                            //это группа полей
                            tree.push({
                                key: secondaryKey,
                                data: allElements.map(i => {
                                    return {
                                        id: i.id,
                                        key: i.key,
                                        data: i.data
                                    };
                                })
                            });
                        };
                    }
                } else {
                    const subKey = secondaryKey.split('#');
                    //Тут проверить что нету больше данных только 1 поле
                    //тогда вывести не как группу потому что не корректно строится дерево
                    //    console.log('Единичное поле', allElements.length === 1,  secondaryKey);
                    //
                    if (!tree.find(o => o.key === subKey[0].trim())) {
                        //Отберем различные данные
                        const allElements = data.ticketDatas.filter(e => e.secondaryKey.split('#')[0] === subKey[0]);

                        //if (allElements.length === 1) {
                        //console.log('Группа', allElements.length, item.secondaryKey.split('#')[0], subKey[0]);
                        //}

                        const subGroups = allElements.reduce((groups, item) => {
                            const group = (groups[item.secondaryKey.split('#')[1]] || []);
                            group.push({
                                id: item.id,
                                key: item.key,
                                data: item.data
                            });
                            const stringName = item.secondaryKey.split('#')[1];
                            groups[stringName] = group;
                            return groups;
                        }, {});
                        tree.push({
                            //id: item.id,
                            key: subKey[0],
                            data: subGroups
                        });
                    }
                    //else
                    //{
                    //    const allElements = data.ticketDatas.filter(e => e.secondaryKey.split('#')[0] === subKey[0]);
                    //    console.log('Elem', allElements.length, item.secondaryKey.split('#')[0], subKey[0]);
                    //    tree.push({
                    //        id: item.id,
                    //        key: secondaryKey,
                    //        data: item.data
                    //    });

                    //}
                };
            });

            //console.log("set tree items");
            setTreeItems(tree);
        }

    }, [data]);

    const TicketInfoPage = () => {

        return (
            <div className=''>

                <div className='ticket-data-summary-info-tg'>
                    <div className='ticket-data-summary-row'>
                        <div>Номер заявки</div>
                        <div>{ticketId}</div>
                    </div>
                    <div className='ticket-data-summary-row'>
                        <div>Дата создания</div>
                        <div>{new Date(data?.dateCreated).toLocaleString('ru-RU')}</div>
                    </div>
                    {/* <div className='ticket-data-summary-row'>
                        <div>Дата изменения</div>
                        <div>{new Date(data?.dateModified).toLocaleString('ru-RU')}</div>
                    </div> */}
                    <div className='ticket-data-summary-row'>
                        <div>Вид страхования</div>
                        <div style={{ background: '#DDE2E4', borderRadius: 4, padding: '2px 4px' }}>
                            {requestTypeName(data?.requestTypeId)}</div>
                    </div>
                    <div className='ticket-data-summary-row'>
                        <div>Текущий этап</div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ background: '#F8C51B', borderRadius: 4, padding: '2px 4px' }}>
                                {clientTicketStatus(data?.ticketStage)}
                            </div>
                        </div>
                    </div>


                </div>

                <Elements items={treeItems} />

                {!data?.insuranceFileName ?
                    <div className='group-button-tg'>

                        <Button
                            tgFormBlue={true}
                            name=<div className='text-button-inside'><div> Начать оформление</div> <div className='icon-inside-button'><Icon name="arrow-right" /></div></div>
                            disabled={selectedInsurance === null ? false : true}
                            onClick={() => {
                                setSelected("Предложения");
                            }}
                        />

                        <Button
                            tgFormWhite={true}
                            name=<div><Icon name="phone" /> Перезвоните мне</div>
                            onClick={() => tg?.requestContact((e) => {
                                if (false) {
                                    alert("Вы не предоставили доступ к контакту, мы не сможем Вам перезвонить.");
                                } else {
                                    alert("Спасибо, наш менеджер свяжется с Вами в ближайшее время.")
                                }
                            })}
                        />
                    </div>
                    :
                    <div className='confirm-page-button-container-tg'>
                        <div className='confirm-page-button-tg'>
                            <Button tgFormBlue={true} name="Закрыть" onClick={() => tg.close()} />
                        </div>
                    </div>
                }
            </div>
        );
    };


    const isSelectedOffer = (offer) => {
        //console.log(offer.ticketId === selectedInsurance.ticketId,
        //    offer.insuranceCompanyId === selectedInsurance.insuranceCompanyId,
        //    offer.commercialOfferId === selectedInsurance.commercialOfferId,
        //    offer.offerId === selectedInsurance.offerId,
        //    offer.summ === selectedInsurance.summ,
        //    offer.franchizeOfferId === selectedInsurance.franchizeOfferId,
        //    offer.franchizeSumm === (selectedInsurance.franchizeSumm ?? null));

        if (selectedInsurance === null)
            return false;

        if (
            //offer.id === selectedInsurance.ticketId &&
            offer.ticketId === selectedInsurance.ticketId &&
            offer.insuranceCompanyId === selectedInsurance.insuranceCompanyId &&
            offer.commercialOfferId === selectedInsurance.commercialOfferId &&
            offer.offerId === selectedInsurance.offerId &&
            offer.summ === selectedInsurance.summ &&
            offer.franchizeOfferId === selectedInsurance.franchizeOfferId &&
            offer.franchizeSumm === (selectedInsurance.franchizeSumm ?? null)
        )
            return true;
        else
            return false;

    }

    const onSelectOfferHandler = (selectedItem) => {
        //console.log("SELECTED OFFER", selectedItem);

        // const seleted = { ...selectedItem };//, id: selectedItem.id ?? null }
        //const initialSelectedOffer = {
        //    ticketId: null,
        //    insuranceCompanyId: null,
        //    offerId: null,
        //    summ: null,//Если франшиза то null
        //    franchizeOfferId: null //Если не франшиза то null
        //};

        if (selectedInsurance !== null && isSelectedOffer(selectedItem) === true) {
            setSelectedInsurance(null);
            // } else if (selectedInsurance !== null && isSelectedOffer(selectedItem) === false) {
            //     setSelectedInsurance(selectedItem);
            dispatch(saveTicketSelectedOfferValue({ id: data.id, value: null }));

            //console.log("data", data)
        } else {
            setSelectedInsurance(selectedItem);
            dispatch(saveTicketSelectedOfferValue({ id: data.id, value: selectedItem }))
                .then(() => {
                    //setSelected("Оплата и доставка");
                });
        }
    };

    useEffect(() => {
        if (data?.selectedOffer) {
            setSelectedInsurance(data.selectedOffer);
        }
    }, [data?.selectedOffer]);

    //useEffect(() => {
    //    console.log("SELECTED",selectedInsurance);
    //}, [selectedInsurance]);

    const TicketCommercialOfferPage = () => {
        //console.log("Data ", data);

        const onChangeStatusHandler = (status) => {

            let cStaus = {
                ticketId: data.id,
                stage: null
            };
            if (status === "Не интересует") {
                cStaus.stage = 5;
            }
            else if (status === "Подумаю") {
                cStaus.stage = 6;
            }

            dispatch(changeTicketStage(cStaus))
                .then(() => {
                    tg.close();
                })
        };

        if (data && data.kaskoCommercialOffers.length === 0 && data.osagoCommercialOffers.length === 0 && data.ipotekaCommercialOffers.length === 0 && data.iflCommercialOffers.length === 0) {
            return (
                <div className='container-text-button-tg'>
                    <div className='confirm-page-tg'>
                        <div className='text-container-tg'>
                            Подготавливается коммерческое предложение для Вас. Как только оно будет готово, Вы получите уведомление от нас.
                        </div>
                    </div>
                    <div className='buttons-cells-first-tg'>
                        <Button
                            tgFormBlue={true}
                            name=<div className='text-button-inside'><div className='icon-inside-button'><Icon name="arrow-left" /></div><div>Назад</div></div>
                            disabled={false}
                            onClick={() => {
                                setSelected("Общие данные");
                                //const element = document.getElementById("box");

                                //element.scrollIntoView();
                            }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className='tg-ticket-offer-list-tg'>

                <OfferComment text={getFirstDescription(data)} />


                {data.kaskoCommercialOffers && data.kaskoCommercialOffers.map((item) => {
                    const { offerDate, statusChangeDate, commercialOfferStatus, kaskoInsuranceCompanyOffers } = item;

                    const insuranceCompaniesIds = [...new Set(
                        kaskoInsuranceCompanyOffers.map(item => item.insuranceCompanyId)
                    )];

                    return (
                        <div key={item.id}>
                            {/* <div className="tg-ticket-offer-container">
                                <div className="tg-saved-offer-header">
                                    <div>
                                        <div>Дата:</div>
                                        <div>{new Date(offerDate).toLocaleString()}</div>
                                    </div>
                                    <div>
                                        <div>Статус:</div>
                                        <div>{ticketOfferStatusName(commercialOfferStatus)}</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='saved-offer-data'>
                                {insuranceCompaniesIds.map(companyId => {
                                    const { name: companyName, logo } = insurances.find(x => x.id === companyId) ?? {};
                                    //const companyName = insurances.find(x => x.id === companyId)?.name;
                                    const companyOffers = item.kaskoInsuranceCompanyOffers.filter(x => x.insuranceCompanyId === companyId);

                                    return (
                                        <div className='insurance-offer-ticket' key={companyId}>
                                            <div className='company-name-ticket-tg'>
                                                <img style={{ width: 32, height: 32 }} src={logo ? ("/api/additional/file/" + logo) : "no_logo.png"} alt={""} /> {companyName}
                                            </div>
                                            <div className='tg-company-offers'>
                                                {companyOffers.map(offer => {

                                                    return (
                                                        <div key={offer.id} className={'tg-offer-item ' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                            {offer.theft && offer.damage && offer.complete && <div className='full-kasko-header-tg'>
                                                                Полное каско</div>}

                                                            {offer.theft &&
                                                                <div className="check-offer">
                                                                    <Icon name="checked-line" />Угон</div>
                                                            }
                                                            {offer.damage &&
                                                                <div className="check-offer">
                                                                    <Icon name="checked-line" />Ушерб</div>
                                                            }
                                                            <div className='offer-last-check-box'>
                                                                {offer.complete &&
                                                                    <div className="check-offer">
                                                                        <Icon name="checked-line" />Тотал</div>
                                                                }
                                                            </div>

                                                            {offer.franchizeOffers.length > 0 &&
                                                                <div className="franchize-offers-tg">Франшиза
                                                                    {/* <Icon name="checked-line" /> */}
                                                                </div>
                                                            }
                                                            {/* <div className='name-summ-franschize-container'> */}
                                                            <div className='name-summ-franschize-tg'>
                                                                <div className='offer-summ-tg'>Сумма</div>
                                                                {/* <div className='summ-franschize'>{offer.summ}</div> */}
                                                            </div>
                                                            {offer.franchizeOffers.length > 0 ?
                                                                <>
                                                                    <div className='franchizes-container-tg'>
                                                                        {offer?.franchizeOffers?.map((fr_item) => {
                                                                            console.log("fr_item: ", fr_item);
                                                                            return (
                                                                                <div key={fr_item.id}>
                                                                                    <div className='franschize-size-row-tg' >
                                                                                        <div>
                                                                                            <div className='small-summ-tg'><Icon name="duplicate" />{fr_item.size}</div>
                                                                                            {fr_item.summ &&
                                                                                                <div className="item-summ-tg"> {toCurrency(fr_item.summ, 'RUB', 'Ru-ru')}</div>}
                                                                                        </div>
                                                                                        {!offer.declined &&
                                                                                            <CheckBox
                                                                                                tgFormRound={true}
                                                                                                // name={(selectedInsurance === null || !isSelectedOffer({
                                                                                                //     //id: data.id,
                                                                                                //     ticketId: data.id,
                                                                                                //     insuranceCompanyId: companyId,
                                                                                                //     commercialOfferId: item.id,
                                                                                                //     offerId: offer.id,
                                                                                                //     summ: null,//Если франшиза то null
                                                                                                //     franchizeOfferId: item.id, //Если не франшиза то null
                                                                                                //     franchizeSumm: item.summ
                                                                                                // }) === true) ? "Выбрать" : "Отменить выбор"}
                                                                                                value={!(selectedInsurance === null || !isSelectedOffer({
                                                                                                    //id: data.id,
                                                                                                    ticketId: data.id,
                                                                                                    insuranceCompanyId: companyId,
                                                                                                    commercialOfferId: item.id,
                                                                                                    offerId: offer.id,
                                                                                                    summ: null,//Если франшиза то null
                                                                                                    franchizeOfferId: fr_item.id, //Если не франшиза то null
                                                                                                    franchizeSumm: fr_item.summ
                                                                                                }))}
                                                                                            id={data.id + "-" + companyId + "-" + item.id + "-" + offer.id + "-" + fr_item.id}
                                                                                                disabled={!(selectedInsurance === null || isSelectedOffer({
                                                                                                    //id: data.id,
                                                                                                    ticketId: data.id,
                                                                                                    insuranceCompanyId: companyId,
                                                                                                    commercialOfferId: item.id,
                                                                                                    offerId: offer.id,
                                                                                                    summ: null,//Если франшиза то null
                                                                                                    franchizeOfferId: fr_item.id, //Если не франшиза то null
                                                                                                    franchizeSumm: fr_item.summ
                                                                                                }) === true)}
                                                                                                onChange={() =>
                                                                                                    onSelectOfferHandler({
                                                                                                        //id: data.id,
                                                                                                        ticketId: data.id,
                                                                                                        insuranceCompanyId: companyId,
                                                                                                        commercialOfferId: item.id,
                                                                                                        offerId: offer.id,
                                                                                                        summ: null,//Если франшиза то null
                                                                                                        franchizeOfferId: fr_item.id, //Если не франшиза то null
                                                                                                        franchizeSumm: fr_item.summ
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='franschize-size-row no-franchise'>
                                                                        {offer.summ &&
                                                                            <div className='summ-franschize'>{toCurrency(offer.summ, 'RUB', 'Ru-ru')}</div>}
                                                                    </div>
                                                                    <div className='check-container-tg'>

                                                                        {!offer.declined &&
                                                                            <CheckBox
                                                                                tgFormRound={true}
                                                                                // name={(selectedInsurance === null || !isSelectedOffer({
                                                                                //     //id: data.id,
                                                                                //     ticketId: data.id,
                                                                                //     insuranceCompanyId: companyId,
                                                                                //     commercialOfferId: item.id,
                                                                                //     offerId: offer.id,
                                                                                //     summ: offer.summ,//Если франшиза то null
                                                                                //     franchizeOfferId: null, //Если не франшиза то null
                                                                                //     franchizeSumm: null
                                                                                // }) === true) ? "Выбрать" : "Отменить выбор"}
                                                                                value={!(selectedInsurance === null || !isSelectedOffer({
                                                                                    //id: data.id,
                                                                                    ticketId: data.id,
                                                                                    insuranceCompanyId: companyId,
                                                                                    commercialOfferId: item.id,
                                                                                    offerId: offer.id,
                                                                                    summ: offer.summ,//Если франшиза то null
                                                                                    franchizeOfferId: null, //Если не франшиза то null
                                                                                    franchizeSumm: null
                                                                                }))}
                                                                                id={data.id + "-" + companyId + "-" + item.id + "-" + offer.id}
                                                                                disabled={!(selectedInsurance === null || isSelectedOffer({
                                                                                    //id: data.id,
                                                                                    ticketId: data.id,
                                                                                    insuranceCompanyId: companyId,
                                                                                    commercialOfferId: item.id,
                                                                                    offerId: offer.id,
                                                                                    summ: offer.summ,//Если франшиза то null
                                                                                    franchizeOfferId: null, //Если не франшиза то null
                                                                                    franchizeSumm: null
                                                                                }) === true)}
                                                                                onChange={() =>
                                                                                    onSelectOfferHandler({
                                                                                        //id: data.id,
                                                                                        ticketId: data.id,
                                                                                        insuranceCompanyId: companyId,
                                                                                        commercialOfferId: item.id,
                                                                                        offerId: offer.id,
                                                                                        summ: offer.summ,//Если франшиза то null
                                                                                        franchizeOfferId: null, //Если не франшиза то null
                                                                                        franchizeSumm: null
                                                                                    })
                                                                                }
                                                                            />}
                                                                    </div>



                                                                </>
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                {data.osagoCommercialOffers && data.osagoCommercialOffers.map((item) => {
                    const { offerDate, statusChangeDate, commercialOfferStatus, osagoInsuranceCompanyOffers } = item;


                    const insuranceCompaniesIds = [...new Set(
                        osagoInsuranceCompanyOffers.map(item => item.insuranceCompanyId)


                    )];


                    return (
                        <div key={item.id}>
                            {/* <div className="tg-ticket-offer-container">
                                <div className="tg-saved-offer-header">
                                    <div>
                                        <div>Дата:</div>
                                        <div>{new Date(offerDate).toLocaleString()}</div>
                                    </div>
                                    <div>
                                        <div>Статус:</div>
                                        <div>{ticketOfferStatusName(commercialOfferStatus)}</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='saved-offer-data'>
                                {osagoInsuranceCompanyOffers && osagoInsuranceCompanyOffers.map(offer => {
                                    const { name: companyName, logo } = insurances.find(x => x.id === offer.insuranceCompanyId) ?? {};
                                    console.log("+++++", item)
                                    return (
                                        <>

                                            <div className="tg-insurance-offer-osago-container">
                                                {/* key = {companyId} */}
                                                <div className="offer-item-osago-name-tg">
                                                    <img style={{ width: 32, height: 32 }} src={logo ? ("/api/additional/file/" + logo) : "no_logo.png"} alt={""} /> {companyName}
                                                </div>

                                                <div key={offer.id} className={"tg-offer-item-osago-group-ticket" + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>

                                                    <div className="offer-item-osago-summ-tg">
                                                        <div className='summ-text-tg'>Сумма</div>
                                                        <div className='summ-osago-tg'>
                                                            {toCurrency(offer.summ, 'RUB', 'Ru-ru')}
                                                        </div>
                                                    </div>
                                                    <div className='check-container-osago-tg'>
                                                        {!offer.declined &&
                                                            <CheckBox
                                                                tgFormRound={true}
                                                                // name={(selectedInsurance === null || !isSelectedOffer({
                                                                //     ticketId: data.id,
                                                                //     insuranceCompanyId: offer.insuranceCompanyId,
                                                                //     commercialOfferId: item.id,
                                                                //     offerId: offer.id,
                                                                //     summ: offer.summ,//Если франшиза то null
                                                                //     franchizeOfferId: null, //Если не франшиза то null
                                                                //     franchizeSumm: null
                                                                // }) === true) ? "Выбрать" : "Отменить выбор"}
                                                                value={(!selectedInsurance === null || isSelectedOffer({
                                                                    ticketId: data.id,
                                                                    insuranceCompanyId: offer.insuranceCompanyId,
                                                                    commercialOfferId: item.id,
                                                                    offerId: offer.id,
                                                                    summ: offer.summ,//Если франшиза то null
                                                                    franchizeOfferId: null, //Если не франшиза то null
                                                                    franchizeSumm: null
                                                                }))}
                                                                id={data.id + "-" + offer.insuranceCompanyId + "-" + item.id + "-" + offer.id}
                                                                busy={false}
                                                                disabled={!(selectedInsurance === null || isSelectedOffer({
                                                                    ticketId: data.id,
                                                                    insuranceCompanyId: offer.insuranceCompanyId,
                                                                    commercialOfferId: item.id,
                                                                    offerId: offer.id,
                                                                    summ: offer.summ,//Если франшиза то null
                                                                    franchizeOfferId: null, //Если не франшиза то null
                                                                    franchizeSumm: null
                                                                }) === true)}
                                                                onChange={() =>
                                                                    onSelectOfferHandler({
                                                                        ticketId: data.id,
                                                                        insuranceCompanyId: offer.insuranceCompanyId,
                                                                        commercialOfferId: item.id,
                                                                        offerId: offer.id,
                                                                        summ: offer.summ,//Если франшиза то null
                                                                        franchizeOfferId: null, //Если не франшиза то null
                                                                        franchizeSumm: null
                                                                    })
                                                                }
                                                            />}
                                                    </div>
                                                </div>

                                            </div>
                                        </>

                                    )
                                })}
                            </div>
                        </div>
                    );
                })}

                {data.iflCommercialOffers && data.iflCommercialOffers.map((item) => {
                    const { offerDate, statusChangeDate, commercialOfferStatus, iflInsuranceCompanyOffers } = item;

                    return (
                        <div key={item.id}>
                            {/* <div className="tg-ticket-offer-container">
                                <div className="tg-saved-offer-header">
                                    <div>
                                        <div>Дата:</div>
                                        <div>{new Date(offerDate).toLocaleString()}</div>
                                    </div>
                                    <div>
                                        <div>Статус:</div>
                                        <div>{ticketOfferStatusName(commercialOfferStatus)}</div>
                                    </div>
                                </div>
                            </div> */}
                            {iflInsuranceCompanyOffers && iflInsuranceCompanyOffers.map(offer => {
                                const { name: companyName, logo } = insurances.find(x => x.id === offer.insuranceCompanyId) ?? {};

                                return (
                                    <div className='company-name middle-ifl-ticket'>
                                        <div className="company-name-ticket-ifl-tg">
                                            <img style={{ width: 32, height: 32 }} src={logo ? ("/api/additional/file/" + logo) : "no_logo.png"} alt={""} />
                                            {companyName}
                                        </div>
                                        <div key={offer.id} className={"tg-offer-item-osago-group-ticket" + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                            <div className="offer-item-osago-summ-tg">
                                                <div className='summ-text-tg'>Сумма</div>
                                                <div className='summ-osago-tg'>
                                                    {toCurrency(offer.summ, 'RUB', 'Ru-ru')}
                                                </div>
                                            </div>
                                            <div className='check-container-osago-tg'>
                                                {!offer.declined &&
                                                    <CheckBox
                                                        tgFormRound={true}
                                                        value={(!selectedInsurance === null || isSelectedOffer({
                                                            ticketId: data.id,
                                                            insuranceCompanyId: offer.insuranceCompanyId,
                                                            commercialOfferId: item.id,
                                                            offerId: offer.id,
                                                            summ: offer.summ,//Если франшиза то null
                                                            franchizeOfferId: null, //Если не франшиза то null
                                                            franchizeSumm: null
                                                        }))}
                                                        disabled={!(selectedInsurance === null || isSelectedOffer({
                                                            ticketId: data.id,
                                                            insuranceCompanyId: offer.insuranceCompanyId,
                                                            commercialOfferId: item.id,
                                                            offerId: offer.id,
                                                            summ: offer.summ,//Если франшиза то null
                                                            franchizeOfferId: null, //Если не франшиза то null
                                                            franchizeSumm: null
                                                        }) === true)}
                                                        id={data.id + "-" + offer.insuranceCompanyId + "-" + item.id + "-" + offer.id}
                                                        busy={false}
                                                        onChange={() =>
                                                            onSelectOfferHandler({
                                                                ticketId: data.id,
                                                                insuranceCompanyId: offer.insuranceCompanyId,
                                                                commercialOfferId: item.id,
                                                                offerId: offer.id,
                                                                summ: offer.summ,//Если франшиза то null
                                                                franchizeOfferId: null, //Если не франшиза то null
                                                                franchizeSumm: null
                                                            })
                                                        }
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='tg-ifl-offer-ifl-ticket'>
                                            {offer.houseInsuranceAmount &&
                                                <div className={'tg-offer-item-ifl-tg ' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                    <div className='ifl-container-first-tg'>
                                                        <div className="full-ifl-header-tg">
                                                            <Icon name="home" />
                                                            Дом
                                                        </div>
                                                    </div>
                                                    <div className='offer-last-check-box' />
                                                    <div className='container-group-ifl-tg'>
                                                        {offer.houseInsuranceAmount.constructiveSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Конструктив</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.houseInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {offer.houseInsuranceAmount.finishingSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Отделка</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.houseInsuranceAmount.finishingSumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {offer.houseInsuranceAmount.movablePropertySumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Движимое имущество</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.houseInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {/*<div className='offer-last-check-box-ifl' />*/}
                                                    {/*<div className='container-price-ifl-tg'>*/}
                                                    {/*    <div>Цена</div>*/}
                                                    {/*    <div className='summ-row-ifl-tg'>*/}
                                                    {/*        {toCurrency("56000", 'RUB', 'Ru-ru')}*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            }
                                            {offer.garageInsuranceAmount &&
                                                <div className={'tg-offer-item-ifl-tg ' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                    <div className='ifl-container-first-tg'>
                                                        <div className="full-ifl-header-tg">
                                                            <Icon name="garage" />
                                                            Гараж
                                                        </div>
                                                    </div>
                                                    <div className='offer-last-check-box' />
                                                    <div className='container-group-ifl-tg'>
                                                        {offer.garageInsuranceAmount.constructiveSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Конструктив</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.garageInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {offer.garageInsuranceAmount.constructiveSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Движимое имущество</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.garageInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {offer.bathInsuranceAmount &&
                                                <div className={'tg-offer-item-ifl-tg ' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                    <div className='ifl-container-first-tg'>
                                                        <div className="full-ifl-header-tg">
                                                            <Icon name="sauna" />
                                                            Баня
                                                        </div>
                                                    </div>
                                                    <div className='offer-last-check-box' />
                                                    <div className='container-group-ifl-tg'>
                                                        {offer.bathInsuranceAmount.constructiveSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Конструктив</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.bathInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {offer.bathInsuranceAmount.constructiveSumm &&
                                                            <div className="item-group-ifl-tg">
                                                                <div className='container-icon-text-ifl-tg'>
                                                                    <Icon name="checked-line" />
                                                                    <div>Движимое имущество</div>
                                                                </div>
                                                                <div className='text-summ-ifl-tg'>
                                                                    <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                    <div className='summ-ifl-tg'>
                                                                        {toCurrency(offer.bathInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {offer.apartmentInsuranceAmount &&
                                                <div className={'tg-offer-item-ifl-tg ' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                    <div className='offer-item-ifl-tg'>
                                                        <div className='ifl-container-first-tg'>
                                                            <div className="full-ifl-header-tg">
                                                                <Icon name="apartment" />
                                                                Квартира
                                                            </div>
                                                        </div>
                                                        <div className='offer-last-check-box' />
                                                        <div className='container-group-ifl-tg'>
                                                            {offer.apartmentInsuranceAmount.constructiveSumm &&
                                                                <div className="item-group-ifl-tg">
                                                                    <div className='container-icon-text-ifl-tg'>
                                                                        <Icon name="checked-line" />
                                                                        <div>Конструктив</div>
                                                                    </div>
                                                                    <div className='text-summ-ifl-tg'>
                                                                        <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                        <div className='summ-ifl-tg'>
                                                                            {toCurrency(offer.apartmentInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {offer.apartmentInsuranceAmount.finishingSumm &&
                                                                <div className="item-group-ifl-tg">
                                                                    <div className='container-icon-text-ifl-tg'>
                                                                        <Icon name="checked-line" />
                                                                        <div>Отделка</div>
                                                                    </div>
                                                                    <div className='text-summ-ifl-tg'>
                                                                        <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                        <div className='summ-ifl-tg'>
                                                                            {toCurrency(offer.apartmentInsuranceAmount.finishingSumm, 'RUB', 'Ru-ru')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {offer.apartmentInsuranceAmount.movablePropertySumm &&
                                                                <div className="item-group-ifl-tg">
                                                                    <div className='container-icon-text-ifl-tg'>
                                                                        <Icon name="checked-line" />
                                                                        <div>Движимое имущество </div>
                                                                    </div>
                                                                    <div className='text-summ-ifl-tg'>
                                                                        <div className='remainder-summ-ipoteka-tg'>Cумма покрытия</div>
                                                                        <div className='summ-ifl-tg'>
                                                                            {toCurrency(offer.apartmentInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    );
                })}

                {data.ipotekaCommercialOffers && data.ipotekaCommercialOffers.map((item) => {
                    const { offerDate, statusChangeDate, commercialOfferStatus, ipotekaInsuranceCompanyOffers } = item;
                    const clientSumm = data.ticketDatas.find(x => (x.key === "Сумма по ипотеке" && x.secondaryKey === "Сумма по ипотеке") || (x.key === "Остаток по кредиту" && x.secondaryKey === "Остаток по кредиту") || (x.key === "Остаток по ипотеке" && x.secondaryKey === "Остаток по ипотеке"))?.data.replace(/ /g, '');
                    //console.log("+++ ", toCurrency(data.ticketDatas.find(x => (x.key === "Сумма по ипотеке" && x.secondaryKey === "Сумма по ипотеке") || (x.key === "Остаток по кредиту" && x.secondaryKey === "Остаток по кредиту") || (x.key === "Остаток по ипотеке" && x.secondaryKey === "Остаток по ипотеке"))?.data.replace(/ /g, ''), 'RUB', 'Ru-ru'));
                    return (
                        <div key={item.id}>
                            {/* <div className="tg-ticket-offer-container">
                                <div className="tg-saved-offer-header">
                                    <div>
                                        <div>Дата:</div>
                                        <div>{new Date(offerDate).toLocaleString()}</div>
                                    </div>
                                    <div>
                                        <div>Статус:</div>
                                        <div>{ticketOfferStatusName(commercialOfferStatus)}</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='saved-offer-data'>
                                <div className="tg-insurance-offer-ipoteka-container">
                                    {ipotekaInsuranceCompanyOffers && ipotekaInsuranceCompanyOffers.map(offer => {
                                        console.log("insurances ", insurances);
                                        const { name: companyName, logo } = insurances?.find(x => x.id === offer.insuranceCompanyId) ?? {};

                                        console.log("offer", offer);
                                        console.log("!!!!!!!!", data.ticketDatas.find(x => (x.key === "Имущество" && x.secondaryKey === "Вид страхования")));
                                        return (
                                            <>
                                                <div className={"tg-offer-item-ipoteka-group-ticket"}>
                                                    <div className="offer-item-ipoteka-name-tg">
                                                        <img style={{ width: 32, height: 32 }} src={logo ? ("/api/additional/file/" + logo) : "no_logo.png"} alt={""} />
                                                        {companyName}

                                                    </div>
                                                    <div className={'container-ipoteka-tg' + (offer.declined ? "declined" : "") + (data?.selectedOffer?.offerId === offer.id ? "tg-selected" : "")}>
                                                        <div className='first-container-ipoteka-tg'>
                                                            {data.ticketDatas.find(x => (x.key === "Имущество" && x.secondaryKey === "Вид страхования"))?.data === "Да" &&
                                                                <div className='typeInsurance-property-ipoteka-tg'>Имущество</div>
                                                            }
                                                            {data.ticketDatas.find(x => (x.key === "Жизнь" && x.secondaryKey === "Вид страхования"))?.data === "Да" &&
                                                                <div className='typeInsurance-life-ipoteka-tg'>Жизнь</div>
                                                            }
                                                            {data.ticketDatas.find(x => (x.key === "Титул" && x.secondaryKey === "Вид страхования"))?.data === "Да" &&
                                                                <div className='typeInsurance-title-ipoteka-tg'>Титул</div>
                                                            }
                                                        </div>
                                                        <div className='offer-last-check-box'></div>
                                                        <div className='second-container-ipoteka-tg'>
                                                            <div className='remainder-summ-ipoteka-container-tg'>
                                                                <div className='remainder-summ-ipoteka-tg'>Остаток по ипотеке</div>
                                                                <div>{toCurrency(clientSumm, 'RUB', 'Ru-ru')} </div>
                                                            </div>
                                                            <div className='vertical-line-tg'></div>
                                                            <div className='offer-item-ipoteka-summ-container-tg'>
                                                                <div className="offer-item-ipoteka-summ-tg">
                                                                    Цена
                                                                </div>
                                                                <div> {toCurrency(offer.summ, 'RUB', 'Ru-ru')}</div>
                                                            </div>
                                                        </div>
                                                        <div className='check-container-ipoteka-tg'>
                                                            {!offer.declined &&
                                                                <CheckBox
                                                                    tgFormRound={true}
                                                                    // name={(selectedInsurance === null || !isSelectedOffer({
                                                                    //     ticketId: data.id,
                                                                    //     insuranceCompanyId: offer.insuranceCompanyId,
                                                                    //     commercialOfferId: item.id,
                                                                    //     offerId: offer.id,
                                                                    //     summ: offer.summ,//Если франшиза то null
                                                                    //     franchizeOfferId: null, //Если не франшиза то null
                                                                    //     franchizeSumm: null
                                                                    // }) === true) ? "Выбрать" : "Отменить выбор"}
                                                                    value={(!selectedInsurance === null || isSelectedOffer({
                                                                        ticketId: data.id,
                                                                        insuranceCompanyId: offer.insuranceCompanyId,
                                                                        commercialOfferId: item.id,
                                                                        offerId: offer.id,
                                                                        summ: offer.summ,//Если франшиза то null
                                                                        franchizeOfferId: null, //Если не франшиза то null
                                                                        franchizeSumm: null
                                                                    }))}
                                                                    id={data.id + "-" + offer.insuranceCompanyId + "-" + item.id + "-" + offer.id}
                                                                    busy={false}
                                                                    disabled={!(selectedInsurance === null || isSelectedOffer({
                                                                        ticketId: data.id,
                                                                        insuranceCompanyId: offer.insuranceCompanyId,
                                                                        commercialOfferId: item.id,
                                                                        offerId: offer.id,
                                                                        summ: offer.summ,//Если франшиза то null
                                                                        franchizeOfferId: null, //Если не франшиза то null
                                                                        franchizeSumm: null
                                                                    }) === true)}
                                                                    onChange={() =>
                                                                        onSelectOfferHandler({
                                                                            ticketId: data.id,
                                                                            insuranceCompanyId: offer.insuranceCompanyId,
                                                                            commercialOfferId: item.id,
                                                                            offerId: offer.id,
                                                                            summ: offer.summ,//Если франшиза то null
                                                                            franchizeOfferId: null, //Если не франшиза то null
                                                                            franchizeSumm: null
                                                                        })
                                                                    }
                                                                />}
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* {(selectedInsurance === null || isSelectedOffer({
                                                    ticketId: data.id,
                                                    insuranceCompanyId: offer.insuranceCompanyId,
                                                    commercialOfferId: item.id,
                                                    offerId: offer.id,
                                                    summ: offer.summ,//Если франшиза то null
                                                    franchizeOfferId: null, //Если не франшиза то null
                                                    franchizeSumm: null
                                                }) === true) &&
                                                
                                                    // <StyledButton name="Выбрать" onClickHandler={() =>
                                                    //     onSelectOfferHandler({
                                                    //         ticketId: data.id,
                                                    //         insuranceCompanyId: offer.insuranceCompanyId,
                                                    //         commercialOfferId: item.id,
                                                    //         offerId: offer.id,
                                                    //         summ: offer.summ,//Если франшиза то null
                                                    //         franchizeOfferId: null, //Если не франшиза то null
                                                    //         franchizeSumm: null
                                                    //     })}
                                                    // /> */}
                                                {/* тут declined */}


                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}


                <div className='group-info-container-tg'>
                    <div className='group-info-tg'>
                        <div>Полезная информация</div>
                        {linkToInsuranceStatistic && linkToInsuranceStatistic !== "" &&
                            <div className='group-text-tg'>
                                <Icon name="share" />
                                <a className='statistics-text-tg' href={linkToInsuranceStatistic} target='_blank'>Статистическая информация</a>
                            </div>
                        }
                        {fileNameInsuranceDescription && fileNameInsuranceDescription !== "" &&
                            <div className='group-text-tg'>
                                <Icon name="download-tg" />
                                <a className='statistics-text-tg' href={"/api/additional/file/" + fileNameInsuranceDescription} target='_blank'>PDF файл с полезной информацией</a>
                            </div>
                        }
                        <OfferComment text={getSecondaryDescription(data)} secondary />
                    </div>
                    <div className='seperate-line-tg'></div>
                    {insuranceDescriptions.length > 0 &&
                        <div className='group-info-tg'>
                            <div>Условия страхования</div>
                            {insuranceDescriptions.map(item => {
                                return (
                                    <div key={item} className='group-text-tg'>
                                        <Icon name="download-tg" />
                                        <a className='statistics-text-tg' href={"/api/additional/file/" + insutanceConditions.find(x => x.insuranceCompanyId === item && x.requestTypeId === data.requestTypeId)?.insuranceConditionFile} target='_blank'>{insurances.find(i => i.id === item)?.name}</a>
                                    </div>
                                );
                            })}
                        </div>
                    }

                </div>

                <div className='group-button-step'>
                    <div className='group-button-step-first'>
                        <div className='buttons-cells-primary'>
                            <Button
                                tgFormBlue={true}
                                name=<div className='text-button-inside'><div>Далее</div> <div className='icon-inside-button'><Icon name="arrow-right" /></div></div>
                                disabled={selectedInsurance !== null ? false : true}
                                onClick={() => {
                                    setSelected("Оформление");
                                    //const element = document.getElementById("box");

                                    //element.scrollIntoView();
                                }}
                            />
                        </div>
                        <div className='buttons-cells-secundary'>
                            <div className='button-call-me'>
                                <Button
                                    tgFormWhite={true}
                                    name=<div><Icon name="phone" /> Перезвоните мне</div>
                                    onClick={() => tg?.requestContact((e) => {
                                        if (false) {
                                            alert("Вы не предоставили доступ к контакту, мы не сможем Вам перезвонить.");
                                        } else {
                                            alert("Спасибо, наш менеджер свяжется с Вами в ближайшее время.")
                                        }
                                    })}
                                />
                            </div>
                            {/* <div className='button-cells-more'>

                                <Button
                                    onClick={toggleDiv}
                                    name=<div className='tree-dot-tg'>...</div>
                                    tgFormActive={true}
                                    tgFormGray={!tgFormActive}

                                />
                            </div> */}
                        </div>

                        <div className='open-cells-buttons'>
                            <div className='buttons-cells'>
                                <Button
                                    tgFormWhite={true}
                                    name="Я подумаю"
                                    disabled={selectedInsurance === null ? false : true}
                                    onClick={() => onChangeStatusHandler("Подумаю")}
                                />
                            </div>
                            <div className='buttons-cells'>
                                <Button
                                    tgFormWhite={true}
                                    name="Не интересует"
                                    disabled={selectedInsurance === null ? false : true}
                                    onClick={() => onChangeStatusHandler("Не интересует")}
                                />
                            </div>
                        </div>





                    </div>

                </div>

            </div>
        );
    };



    const TicketDesignOptionAndPaymentOption = () => {

        const initialFile = {
            refId: null,
            uploaded: false,
            fileName: null,
            fileId: null
        };

        const initialFileData = {
            fileType: null,
            multiple: false,
            required: true,
            //files: [{
            //    ...initialFile
            //}]
        };

        //public PaymentOption? PaymentOption { get; set; } = null;
        //public DeliveryType ? DeliveryType { get; set; } = null;
        //public string ? DeliveryAddress { get; set; } = null;
        //public DateTime ? DileveryDate { get; set; }
        //console.log(data.dileveryDate);
        const [paymentAndDileveryOptions, setPaymentAndDileveryOptions] = useState({
            paymentOption: data.paymentOption,
            deliveryType: data.deliveryType,
            deliveryAddress: data.deliveryAddress,
            dileveryDate: data.dileveryDate
        });

        const changedesignOptionTypeHandler = (type) => {
            setPaymentAndDileveryOptions(state => {
                let newState = { ...state };
                newState.deliveryType = type;
                if (type !== 2) {
                    newState.dileveryDate = null;
                    newState.deliveryAddress = null;
                }
                return newState;
            });

            dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, deliveryType: type }))
        }

        const changePaymentOptionTypeHandler = (type) => {
            setPaymentAndDileveryOptions(state => {
                let newState = { ...state };
                newState.paymentOption = type;

                return newState;
            });

            dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, paymentOption: type }))

        }

        const changeDateHandler = (date) => {
            //console.log(paymentAndDileveryOptions.dileveryDate);
            //console.log(date, date.toJSON(), date.toString("YYYY-MM-DDTHH:mm"));

            setPaymentAndDileveryOptions(state => {
                let newState = { ...state };
                newState.dileveryDate = date;

                return newState;
            });

            dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, dileveryDate: date }))
            //console.log(JSON.stringify(paymentAndDileveryOptions));

        }

        const handleFocus = (e) => {
            const { target } = e;

            if (target) {
                target.readOnly = true;  // -------> this for all others
                target.blur(); //  ------> this for ios iphone, TV Browsers, Ipad, Safari
            }
        };

        const changeAddressHandler = (address) => {
            setPaymentAndDileveryOptions(state => {
                let newState = { ...state };
                newState.deliveryAddress = address;

                return newState;
            });
        }

        const onBlurAddressHandler = () => {
            dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id }))
        };

        //useEffect(() => {
        //    //console.log("PO: ", paymentAndDileveryOptions);
        //    if (data && paymentAndDileveryOptions) {
        //        dispatch(savePaymentAndDileveryOptions({...paymentAndDileveryOptions, ticketId: data.id }))
        //    };

        //}, [paymentAndDileveryOptions, data]);

        const [url, setUrl] = useState();
        const [urls, setUrls] = useState([]);

        const [files, setFiles] = useState(state => {
            let _files = [];
            //console.log(data?.requestTypeId);



            //Паспорт у всех
            _files.push({
                ...initialFileData,
                fileType: 0,
                files: data.files.filter(x => x.fileType === 0).length !== 0 ?
                    data.files.filter(x => x.fileType === 0).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]

            });

            //Первый дополнительный пасотр пустой но можно будет добавить еще
            _files.push({
                ...initialFileData,
                multiple: true,
                required: false,
                fileType: 1,
                files: data.files.filter(x => x.fileType === 1).length !== 0 ?
                    data.files.filter(x => x.fileType === 1).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });

            //Договор страхования прошлого года (для согласования скидки за переход в другую СК)
            _files.push({
                ...initialFileData,
                fileType: 8,
                files: data.files.filter(x => x.fileType === 8).length !== 0 ?
                    data.files.filter(x => x.fileType === 8).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });


            //каско и осаго
            if (data?.requestTypeId === 0 || data?.requestTypeId === 1) {

                //ПТС
                // _files.push({
                //     ...initialFileData,
                //     fileType: 10,
                //     //files: [{
                //     //    ...initialFile
                //     //}]
                //     files: data.files.filter(x => x.fileType === 10).length !== 0 ?
                //         data.files.filter(x => x.fileType === 10).map(x => {
                //             return {
                //                 refId: null,
                //                 uploaded: true,
                //                 fileName: x.fileName,
                //                 fileId: x.id
                //             }
                //         }) :
                //         [{ ...initialFile }]
                // });

                //СТС
                _files.push({
                    ...initialFileData,
                    fileType: 11,
                    //files: [{
                    //    ...initialFile
                    //}]
                    files: data.files.filter(x => x.fileType === 11).length !== 0 ?
                        data.files.filter(x => x.fileType === 11).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                });

                //Водительские удостоверения
                _files.push({
                    ...initialFileData,
                    multiple: true,
                    fileType: 12,
                    //files: [{
                    //    ...initialFile
                    //}]
                    files: data.files.filter(x => x.fileType === 12).length !== 0 ?
                        data.files.filter(x => x.fileType === 12).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                });

                //Только каско
                if (data?.requestTypeId === 0) {
                    //Кредитный договор (1.2.3 страницы и последняя страница с подписью и печатью банка).
                    _files.push({
                        ...initialFileData,
                        required: false,
                        fileType: 7,
                        files: data.files.filter(x => x.fileType === 7).length !== 0 ?
                            data.files.filter(x => x.fileType === 7).map(x => {
                                return {
                                    refId: null,
                                    uploaded: true,
                                    fileName: x.fileName,
                                    fileId: x.id
                                }
                            }) :
                            [{ ...initialFile }]
                        //files: [{
                        //    ...initialFile
                        //}]
                    });
                };
                //Тллько осаго
                if (data?.requestTypeId === 1) {

                };
            };
            //Ипотека и ифл
            if (data?.requestTypeId === 2 || data?.requestTypeId === 3) {
                //ЕГРН
                _files.push({
                    ...initialFileData,
                    fileType: 2,
                    files: data.files.filter(x => x.fileType === 2).length !== 0 ?
                        data.files.filter(x => x.fileType === 2).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //договор купли-продажи/мены;
                _files.push({
                    ...initialFileData,
                    fileType: 3,
                    files: data.files.filter(x => x.fileType === 3).length !== 0 ?
                        data.files.filter(x => x.fileType === 3).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //свидетельство о праве собственности;
                _files.push({
                    ...initialFileData,
                    fileType: 4,
                    files: data.files.filter(x => x.fileType === 4).length !== 0 ?
                        data.files.filter(x => x.fileType === 4).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //договор передачи/дарения;
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 5,
                    files: data.files.filter(x => x.fileType === 5).length !== 0 ?
                        data.files.filter(x => x.fileType === 5).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //Справка, удостоверяющая рыночную стоимость жилья, земли
                _files.push({
                    ...initialFileData,
                    fileType: 6,
                    files: data.files.filter(x => x.fileType === 6).length !== 0 ?
                        data.files.filter(x => x.fileType === 6).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //Кредитный договор (1.2.3 страницы и последняя страница с подписью и печатью банка).
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 7,
                    files: data.files.filter(x => x.fileType === 7).length !== 0 ?
                        data.files.filter(x => x.fileType === 7).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //Отчёт об оценке
                _files.push({
                    ...initialFileData,
                    fileType: 9,
                    files: data.files.filter(x => x.fileType === 9).length !== 0 ?
                        data.files.filter(x => x.fileType === 9).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });

                //Только ипотека
                if (data?.requestTypeId === 3) {
                    //Справка, удостоверяющая рыночную стоимость жилья, земли
                // _files.push({
                //     ...initialFileData,
                //     fileType: 6,
                //     files: data.files.filter(x => x.fileType === 6).length !== 0 ?
                //         data.files.filter(x => x.fileType === 6).map(x => {
                //             return {
                //                 refId: null,
                //                 uploaded: true,
                //                 fileName: x.fileName,
                //                 fileId: x.id
                //             }
                //         }) :
                //         [{ ...initialFile }]
                //     //files: [{
                //     //    ...initialFile
                //     //}]
                // });
                // // Паспорт продавца (все страницы)
                // _files.push({
                //     ...initialFileData,
                //     fileType: 21,
                //     files: data.files.filter(x => x.fileType === 21).length !== 0 ?
                //         data.files.filter(x => x.fileType === 21).map(x => {
                //             return {
                //                 refId: null,
                //                 uploaded: true,
                //                 fileName: x.fileName,
                //                 fileId: x.id
                //             }
                //         }) :
                //         [{ ...initialFile }]
                //     //files: [{
                //     //    ...initialFile
                //     //}]
                // });
                // // Справки ПД/ПНД
                // _files.push({
                //     ...initialFileData,
                //     fileType: 22,
                //     files: data.files.filter(x => x.fileType === 22).length !== 0 ?
                //         data.files.filter(x => x.fileType === 22).map(x => {
                //             return {
                //                 refId: null,
                //                 uploaded: true,
                //                 fileName: x.fileName,
                //                 fileId: x.id
                //             }
                //         }) :
                //         [{ ...initialFile }]
                //     //files: [{
                //     //    ...initialFile
                //     //}]
                // });
                }
                //Только ифл
                if (data?.requestTypeId === 2) {
                    //Паспорт страхователя (стр. 2-3 и стр. с актуальной регистрацией)
            _files.push({
                ...initialFileData,
                fileType: 17,
                files: data.files.filter(x => x.fileType === 17).length !== 0 ?
                    data.files.filter(x => x.fileType === 17).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });
            //Действующий полис (при наличии)
            _files.push({
                ...initialFileData,
                required: false,
                fileType: 18,
                files: data.files.filter(x => x.fileType === 18).length !== 0 ?
                    data.files.filter(x => x.fileType === 18).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });
                }
            }

            _files.sort((a, b) => {
                return a.fileType - b.fileType;
            });

            return _files;
            //console.log("SORTED", _files);
        });


        const updateFileUrlAndId = (index, item, e) => {
            //console.log("+++", index, item, e);

            setFiles(state => {
                let newState = [...state];
                newState[index].files[0].fileName = e.fileName;
                newState[index].files[0].fileId = e.id;
                newState[index].files[0].uploaded = true;

                //console.log("NEW STATE: ", newState);
                return newState;
            });

            //Сохраним в состояние чтоб при переключении не пропадало
            dispatch(updateFileData(e));

        }

        const uploadedFile = (files) => {
            let uploaded = false;
            files?.forEach(file => {
                //console.log("--- ", uploaded, file.uploaded, uploaded  file.uploaded);
                if (file.uploaded === true) {
                    uploaded = true;
                    return uploaded;
                }

            });

            //console.log("XXX", uploaded, files)

            return uploaded;
        };

        const uploadRefs = useRef([]);

        //const uploadRefs = useRef([]);
        const uploadRef = useRef();
        //console.log("cur id", uploadRef.current);
        useEffect(() => {
            //console.log("FILES", files);
        }, [files]);

        useEffect(() => {
            if (data?.requestTypeId === 3) {
                //выберем автоматически вариант оплаты и доставки так как доступно только по 1 типу
                if (paymentAndDileveryOptions.deliveryType !== 0)
                    changedesignOptionTypeHandler(0);

                if (paymentAndDileveryOptions.paymentOption !== 3)
                    changePaymentOptionTypeHandler(3);

            };
        }, [data?.requestTypeId, paymentAndDileveryOptions]);

        return (
            <div className='container-step-three-tg'>
                <div className='group-containers-tg'>
                    <div>Выберите вариант оформления полиса, вид доставки а также прикрепите необходимые файлы для продолжения</div>

                    {/*<div>Вариант оформления</div>*/}

                    <fieldset className="input-radio-group-tg">
                        <legend className="input-radio-legend-tg">Вариант оформления:</legend>

                        <div className="input-radio-tg">
                            <RadioButton
                                type="radio"
                                id="designOptionType_0"
                                name="designOptionType"
                                value="0"
                                checked={paymentAndDileveryOptions.deliveryType === 0}
                                onChange={() => changedesignOptionTypeHandler(0)}
                                tgForm={true}
                            />
                            <label className='label-input-tg' htmlFor="designOptionType_0">Дистанционное (оформление полиса в электронном виде с печатью и подписью факсимиле)</label>
                        </div>

                        {/*
                        <div className="input-radio-tg">
                            <input
                                type="radio" id="designOptionType_1"
                                name="designOptionType"
                                value="1"
                                checked={paymentAndDileveryOptions.deliveryType === 1}
                                onChange={() => changedesignOptionTypeHandler(1)}
                            />
                            <RadioButton
                                id="designOptionType_1"
                                name="designOptionType"
                                value="1"
                                checked={paymentAndDileveryOptions.deliveryType === 1}
                                onChange={() => changedesignOptionTypeHandler(1)}
                                tgForm={true}
                            />
                            <label className='label-input-tg' htmlFor="designOptionType_1">В нашем ближайшем офисе</label>
                        </div>
                        */}

                        {data?.requestTypeId !== 3 &&
                            <div className="input-radio-tg">
                                <RadioButton
                                    id="designOptionType_2"
                                    name="designOptionType"
                                    value="2"
                                    checked={paymentAndDileveryOptions.deliveryType === 2}
                                    onChange={() => changedesignOptionTypeHandler(2)}
                                    tgForm={true}
                                />
                                <label className='label-input-tg' htmlFor="designOptionType_2">Доставка в любое удобное время и место (бесплатно)</label>
                            </div>
                        }
                    </fieldset>


                    {paymentAndDileveryOptions.deliveryType === 2 &&
                        <>
                            <div className='date-container-tg'>
                                <div className='adres-delivery-tg'>Введите адрес доставки</div>
                                <Input 
                                    type="text" 
                                    placeholder="Адрес доставки" 
                                    value={paymentAndDileveryOptions.deliveryAddress ?? ''} 
                                    onChange={e => changeAddressHandler(e.target.value)}
                                    onBlur={() => {
                                        console.log("blur address");
                                        onBlurAddressHandler();
                                    }}
                                    tgForm={true} />
                                <div>Выберите дату и время доставки</div>
                                <DatePicker
                                    selected={paymentAndDileveryOptions.dileveryDate ? new Date(paymentAndDileveryOptions.dileveryDate) : null}
                                    minDate={new Date()}
                                    placeholderText='Дата и время доставки'
                                    onChange={(date) => changeDateHandler(date)}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    onFocus={handleFocus}
                            
                                //locale="ru-RU"
                                    //shouldCloseOnSelect={false}
                                    //disabledKeyboardNavigation
                                    // isClearable
                                //timeInputLabel="Время:"
                                //onChangeRaw={(event) => console.log(event.target.value)}
                                    timeCaption="Время:"
                                    showTimeSelect
                                    timeIntervals={10}
                                    className='datapicker-tg'
                                />
                            </div>
                        </>
                    }

                    <fieldset className="input-radio-group-tg">
                        <legend className="input-radio-legend-tg">Вариант оплаты:</legend>

                        {/* <div className="input-radio-tg">
                            <RadioButton
                                id="paymentType_0"
                                name="paymentType"
                                value="0"
                                checked={paymentAndDileveryOptions.paymentOption === 0}
                                onChange={() => changePaymentOptionTypeHandler(0)}
                                tgForm={true}
                            />
                            <label className='label-input-tg' htmlFor="paymentType_0">В кассу страховой компании</label>
                        </div> */}
                        {data?.requestTypeId !== 3 &&
                            <>
                                <div className="input-radio-tg">
                                    <RadioButton
                                        id="paymentType_1"
                                        name="paymentType"
                                        value="1"
                                        checked={paymentAndDileveryOptions.paymentOption === 1}
                                        onChange={() => changePaymentOptionTypeHandler(1)}
                                        tgForm={true}
                                    />
                                    <label className='label-input-tg' htmlFor="paymentType_1">Наличными выездному специалисту</label>
                                </div>

                                {data?.requestTypeId !== 2 &&
                                    <div className="input-radio-tg">
                                        <RadioButton
                                            id="paymentType_2"
                                            name="paymentType"
                                            value="2"
                                            checked={paymentAndDileveryOptions.paymentOption === 2}
                                            onChange={() => changePaymentOptionTypeHandler(2)}
                                            tgForm={true}
                                        />
                                        <label className='label-input-tg' htmlFor="paymentType_2">По карте выездному специалисту</label>
                                    </div>
                                }
                            </>
                        }
                        <div className="input-radio-tg">
                            <RadioButton
                                id="paymentType_3"
                                name="paymentType"
                                value="3"
                                checked={paymentAndDileveryOptions.paymentOption === 3}
                                onChange={() => changePaymentOptionTypeHandler(3)}
                                tgForm={true}
                            />
                            <label className='label-input-tg' htmlFor="paymentType_3">По выставленному счету от страховой компании. Оплата без комиссии картой любого банка по ссылке от страховой компании или в мобильном банке.</label>
                        </div>
                    </fieldset>
                </div>

                {data?.requestTypeId === 1 &&
                    <fieldset className="input-radio-group-doc-tg">
                        <legend className="input-radio-legend-tg">Прикрепите документы:</legend>
                        {files.map((item, index) => {
                            return (
                                <Upload
                                    key={index}
                                    ref={(element) => { uploadRefs.current[index] = element }}
                                    onUpload={(e) => updateFileUrlAndId(index, item, e)} //setUrl
                                    fileType={item.fileType}
                                    ticketId={data.id}
                                >
                                    <div className='icon-upload-container-tg' onClick={() => uploadRefs.current[index].upload()}>
                                        <div className='icon-upload-tg'>
                                            {uploadedFile(item?.files) === false &&
                                                <div>
                                                    <Icon name="file-up" />
                                                </div>
                                            }

                                            {uploadedFile(item?.files) === true &&
                                                <div className='icon-clip'>
                                                    <Icon name="file-done" />
                                                </div>
                                            }
                                        </div>

                                        <div className='name-doc-upload-tg'>
                                            {fileNameByFileType(item.fileType)}
                                            <div className='color-asterix-tg'>{item.required && "*"}</div>
                                        </div>


                                    </div>
                                </Upload>
                            )

                            //<div key={index}
                            //    ref={(element) => { refs.current[index] = element }}
                            ////Adds the current element to our refs.current array
                            //>{item}</div>
                        })}

                    </fieldset>
                }

                <div className='group-button-tg'>
                    <Button
                        tgFormBlue={true}
                        name=<div className='name_end'> Завершить оформление <div className='icon-inside-button'><Icon name="checked-line-white" /></div></div>
                        disabled={selectedInsurance !== null ? false : true}  // тут сначало false потом true
                        onClick={() => {
                            //setSelected("Оформление");
                            //const element = document.getElementById("box");
                            dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id }))
                                .then(() => {
                                    dispatch(changeTicketStage({ ticketId: data.id, stage: 15 }))
                                        .then(() => {
                                            //tg.close();
                                            setSelected("Завершено");

                                        })
                                })
                                .cath((e) => {
                                    setError(e);
                                    console.error(e);
                                })

                            //{id}/changeStatus/{statusId}
                            console.log("Завершить оформление");
                            //element.scrollIntoView();
                        }}
                    />

                    <Button
                        tgFormWhite={true}
                        name=<div><Icon name="phone" />Перезвоните мне</div>
                        onClick={() => tg?.requestContact((e) => {
                            if (false) {
                                alert("Вы не предоставили доступ к контакту, мы не сможем Вам перезвонить.");
                            } else {
                                alert("Спасибо, наш менеджер свяжется с Вами в ближайшее время.")
                            }
                        })}
                    />
                </div>
            </div>
        );
    };

    //console.log(selected === "Общие данные");
    const Elements = ({ items }) => {
        try {
            if (!Array.isArray(items)) {
                const entries = Object.entries(items);

                return (
                    <>
                        {entries.map(([key, values]) => {
                            //console.log(value.key);
                            return (
                                <div key={key}>
                                    <div key={key} className='group-container-tg'>
                                        {/* <div className='object-name-tg'>{key}</div> номер водителя */}
                                        <div className='object-name-tg'></div>

                                        <Elements items={values} />
                                    </div>
                                </div>
                            )
                        })}
                    </>
                );
            } else {
                return (
                    <>

                        {items.map((item) => {

                            if (typeof item.data === "string") {
                                return (

                                    <TicketDataItem key={item.id} item={item} tgClient={true} />

                                );
                            } else if (typeof item.data === "array") {
                                return (

                                    <div className='array-container' key={item.key}>
                                        <div className='group-container'>
                                            <div className='object-name-tg'>{item.key}</div>
                                            <Elements items={item.data} />
                                        </div>
                                    </div>

                                );
                            } else if (typeof item.data === "object") {
                                return (

                                    <div className='object-container-tg' key={item.key}>
                                        <div className='object-name-tg'>{item.key}</div>
                                        <Elements items={item.data} />
                                    </div>

                                )
                            }
                        }
                        )}
                    </>
                );
            }
        } catch (e) {
            console.log(e);
            console.log(items);
            return <>ERROR</>
        }

    };

    useEffect(() => {
        //const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        //};
    }, [selected]);

    useEffect(() => {
        if (data?.insuranceFileName && data?.insuranceFileName !== null) {
            setSelected("Полис");
            console.log("MENU ", data?.insuranceFileName, selected);
        } else if (data?.ticketStage === 15) {
            setSelected("Завершено");
            console.log("MENU stage 15", selected);
        } else {
            if (openPage) {
                setSelected(ticketMenu[openPage])
            } else {
                //setSelected("Общие данные");

            }
        }
    }, [data?.insuranceFileName, data?.ticketStage, openPage]);

    //console.log("DATA ", selected, data);

    return (
        <>
            {/* <TgHeader name={"Заявка №" + ticketId} /> */}
            {/* <TgHeader name={"Заявка " + requestTypeName(data?.requestTypeId)} /> */}
            <TgHeader name={"Заявка"} type={requestTypeName(data?.requestTypeId)} />


            <div className={"form"}>
                {
                    //console.log("FFDATA ",!data, data?.id, ticketId, data?.id !== ticketId, !data || data?.id !== ticketId)
                }
                {(!data || data?.id != ticketId) ?
                    <div className="loader-secondary">Загрузка</div>
                    :
                    <>
                        <div className='first-form-tg'>
                            <div className="tg-ticket-first-menu">
                                {data && !data?.insuranceFileName &&

                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                                            <Icon onClick={() => selected === "Завершено" ? {} : setSelected("Общие данные")} name="file-b" />
                                            <div style={{ borderTop: "2px solid " + (selected === "Предложения" || selected === "Оформление" || selected === "Завершено" ? "#50A7EA" : "#9AA6AC"), flexGrow: 1 }} />
                                            <Icon onClick={() => selected === "Завершено" ? {} : selected === "Завершено" ? {} : setSelected("Предложения")} name="car" stroke={selected === "Предложения" || selected === "Оформление" || selected === "Завершено" ? "#50A7EA" : "#9AA6AC"} />
                                            <div style={{ borderTop: "2px solid " + (selected === "Оформление" || selected === "Завершено" ? "#50A7EA" : "#9AA6AC"), flexGrow: 1 }} />
                                            <Icon onClick={() => selected === "Завершено" ? {} : setSelected("Оформление")} name="pencil-line" stroke={selected === "Оформление" || selected === "Завершено" ? "#50A7EA" : "#9AA6AC"} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div onClick={() => selected === "Завершено" ? {} : setSelected("Общие данные")} className={"tg-menu-item " + (selected === "Общие данные" ? "tg-selected-menu" : "")}>Общие данные</div>
                                            <div onClick={() => selected === "Завершено" ? {} : setSelected("Предложения")} className={"tg-menu-item " + (selected === "Предложения" ? "tg-selected-menu" : "")}>Предложения</div>
                                            <div onClick={() => selected === "Завершено" ? {} : setSelected("Оформление")} className={"tg-menu-item " + (selected === "Оформление" ? "tg-selected-menu" : "")}>Оформление</div>
                                        </div>
                                    </>
                                }
                                {data && data.insuranceFileName &&
                                    <div className="tg-ticket-menu-container-new">
                                        <div
                                            style={{ width: '50%' }}
                                            className={"tg-ticket-menu-item-new " + ("Полис" === selected ? "active" : "")}
                                            onClick={() => setSelected("Полис")}
                                        >
                                            Полис
                                        </div>
                                        <div

                                            className={"tg-ticket-menu-item-new " + ("Общие данные" === selected ? "active" : "")}
                                            onClick={() => setSelected("Общие данные")}
                                        >
                                            Общие данные
                                        </div>
                                    </div>
                                }
                            </div>

                            {selected === "Общие данные" && data &&
                                <TicketInfoPage />
                            }
                            {selected === "Предложения" && data &&
                                <TicketCommercialOfferPage />
                            }
                            {selected === "Оформление" && data &&
                                <TicketDesignOptionAndPaymentOption />
                            }
                            {selected === "Полис" && data &&
                                <>
                                    <div className='confirm-page-container-tg'>
                                        <TgInsuranseSmallData
                                            data={{
                                                insuranceType: requestTypeName(data?.requestTypeId),
                                                insuranceCompany: insurances?.find(x => x.id === data?.selectedOffer?.insuranceCompanyId)?.name ?? "",
                                                period: new Date(data?.insuranceDateStart).toLocaleDateString('ru-RU') + " - " + new Date(data?.insuranceDateEnd).toLocaleDateString('ru-RU'),
                                                summ: data?.insuranceSumm,
                                                number: data?.insuranceNumber,
                                                fileName: data?.insuranceFileName
                                            }}
                                        />

                                        <div className='group-info-container-tg'>
                                            <div className='group-text-polis-tg'><div>Оплачено</div> <div>{data.paid === false ? "Нет" : "Да"}</div></div>
                                            {/* <div>Доставлено: {data.delivered === false ? "Нет" : "Да"}</div> */}
                                            <div className='group-text-tg'>
                                                <Icon name="download-tg" />
                                                <a className='statistics-text-tg' href={"api/ticket/file/" + data?.insuranceFileName} alt="Скачать полис">Скачать полис</a>
                                            </div>
                                        </div>

                                        <div className='confirm-page-button-container-tg'>
                                            <div className='confirm-page-button-tg'>
                                                <Button tgFormBlue={true} name="Закрыть" onClick={() => tg.close()} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {selected === "Завершено" && data &&
                                <div className='confirm-page-container-tg'>
                                    <div className='confirm-page-tg'>
                                        <div className='big-checkbox-container-tg'><Icon name="big-checkbox" /></div>
                                        <div className='confirm-page-primary-tg'>
                                            <div className='confirm-page-text-done-tg'>Готово</div>
                                            <div className='confirm-page-text-tg'>Ваша заявка успешно отправлена.</div>
                                            <div className='confirm-page-text-tg'>Как только наш менеджер ее обработает, вы получите уведомление.</div>
                                        </div>
                                    </div>
                                    <div className='confirm-page-button-container-tg'>
                                        <div className='confirm-page-button-tg'>
                                            <Button tgFormBlue={true} name="Закрыть" onClick={() => tg.close()} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }

            </div>
        </>
    );
};

export default MyTicketData;

//<div className="tg-ticket-menu-container">
//    {ticketMenu.map(item => {
//        return (
//            <div
//                key={item}
//                className={"tg-ticket-menu-item " + (item === selected ? "active" : "")}
//                onClick={() => setSelected(item)}
//            >
//                {item}
//            </div>
//        )
//    })}
//</div>