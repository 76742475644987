import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '../../components/UI/';
import { saveNote} from '../../store/ticketSlice';

const Note = ({ editMode, id, ticketId, responsibleId, noteText, noteDate = new Date(), onEdit = () => { }, onSave = () => { }, onCancel = () => { }, onDelete = () => { } }) => {
    const dispatch = useDispatch();
    const currentResponsible = useSelector(state => state.auth.user);
    const responsible = useSelector(state => state.users.list).find(x => x.id === responsibleId);
    console.log(currentResponsible);

    const [editedNoteText, setEditedNoteText] = useState(noteText);
    return (
        <div className='ticket-note'>
            <div className='ticket-note-data'>
                {editMode ? 
                    (
                        <>
                        <div>
                          <textarea
                            className='note-textarea'
                            type='text'
                            maxLength={500}
                            placeholder='Введите текст заметки'
                            cols={100}
                            value={editedNoteText}
                            onChange={(e) => setEditedNoteText(e.target.value)}  
                          />
                         </div>
                        </>
                      )
                    :
                    <div>
                    <textarea
                            className='edited-note-text'
                            type='text'
                            maxLength={500}
                            placeholder='Введите текст заметки'
                            cols={100}
                            value={editedNoteText}
                          />
                    </div>
                }
            </div>
            <div className='ticket-note-line' />

            <div className='ticket-bottom'>
                <div className='ticket-bottom-data'>
                    {!editMode &&
                        <>
                            <div>
                                {new Date(noteDate).toLocaleString('ru-RU')}
                            </div>
                            <div className='dot' />
                            <div>{responsible?.email}</div>
                        </>
                    }
                </div>
                <div className='ticket-bottom-buttons'>
                    {editMode ?
                        <Icon name='cancel' onClick={onCancel} />
                        :
                        <Icon name='delete' onClick={onDelete} />
                    }
                    <div/>
                    {editMode ?
                        <Icon name='done' onClick={() => saveNote(editedNoteText)}/>
                        :
                        <Icon name='edit' onClick={onEdit} />
                    }
                </div>
            </div>
        </div>
    );
};

export default Note;