import React, { useState, useEffect } from 'react';

import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';

const IpotekaOfferItem = ({ insuranceItem, isNewOffer, onRemoveInsurance = () => { }, onAddOffer = () => { }, onChangeOffer = () => { }, onRemoveOffer = () => { } }) => {

    //console.log("+++", insuranceItem.offers[0]);
    const [currenOffer, setCurrentOffer] = useState({ ...insuranceItem.offers[0] });

    const onChangeSummHandler = (value) => {
        setCurrentOffer(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            return {
                ...state,
                summ: v
            };
        });
    };

    useEffect(() => {
        //console.log('Осаго offer изменен');
        onChangeOffer({ currenOffer, insuranceId: insuranceItem.id });
    }, [currenOffer]);

    return (
        <div className='company-name small'>
            <div className="company-name-row-osago">
                {insuranceItem?.insuranceCompany?.name}
                {isNewOffer &&
                    <Icon name='delete'
                        onClick={() => onRemoveInsurance({ item: insuranceItem, isSet: false })}
                    />
                }
            </div>
            {/*<Input value={currenOffer.summ} onChange={(e) => onChangeSummHandler(e.target.value)} />*/}
            <Input
                className={'input'}
                type="text"
                placeholder={'Сумма страховой'}
                //disabled={false}
                value={currenOffer.summ === 0 ? "" : currenOffer.summ}
                onChange={(e) => onChangeSummHandler(e.target.value)}
            />
        </div>
    );
};

export default IpotekaOfferItem;
