import React from 'react';
import { ticketStatus, requestTypeName, clientTicketStatus } from '../../additional';
const TgInsuranceRequestSmallData = ({ data, onClick = () => { } }) => {
    const { id, ticketStage, haveCommercialOffer, documentsUploaded, carInfo, bankName, iflOptions, paymentOption, paid, deliveryType, deliveryAddress, delivered, insuranceCompanyId, insuranceNumber, insuranceDateStart, insuranceDateEnd, insuranceSumm, requestTypeId } = data;
    const { commercialOfferStatus } = data;

    return (
        <div className="friend-line" onClick={onClick}>
            <div className='insurance-small-data-container'>
                <div className='insurance-small-info-line'>
                    <div>Тип страхования</div>
                    <div>{requestTypeName(requestTypeId)}</div>
                </div>
                <div className='insurance-small-info-line'>
                    <div>Заявка №</div>
                    <div>{id}</div>
                </div>
                <div className='insurance-small-info-line'>
                    <div>Статус заявки</div>
                    <div>{clientTicketStatus(ticketStage)}</div>
                </div>

                <div className='insurance-small-info-line'>
                    <div>Статус КП</div>
                    <div>{haveCommercialOffer ? "Есть" : "Готовится"}</div>
                </div>

                {carInfo &&
                    <div className='insurance-small-info-line'>
                        <div>Автомобиль</div>
                        <div>{carInfo}</div>
                    </div>
                }
                {bankName &&
                    <div className='insurance-small-info-line'>
                        <div>Банк</div>
                        <div>{bankName}</div>
                    </div>
                }
                {iflOptions &&
                    <div className='insurance-small-info-line ifl-option'>
                        <div>Имущество</div>
                        <div className='ifl-tg-option'>{iflOptions.map(item => <div key={item}>{item}</div>)}</div>
                    </div>
                }

                <div className='insurance-small-info-line'>
                    <div>Документы</div>
                    <div>{documentsUploaded ? "Загружены" : "Не загружены"}</div>
                </div>
            </div>
        </div>
    );
};

export default TgInsuranceRequestSmallData;