import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchClients } from '../../store/clientSlice';

const ClientInfoPage = ({ clientInfo }) => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const { list } = useSelector(store => store.clients);

    const item = list.find(x => x.id === parseInt(id));

    useEffect(() => {
        if (list && list.length === 0) {
            setIsLoading(true);
            dispatch(fetchClients())
                .then(() => {
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    console.error(e);
                });
        };
    }, [list]);

    if (!item) return <></>;

    return (
        <div className='client-info-container'>
            <div className="client-info-left">
                <div className='client-info-row'>
                    <div>ИД Клиента</div>
                    <div>{item.id}</div>
                </div>
                <div className='client-info-row'>
                    <div>ФИО</div>
                    <div>{item.firstName} {item.lastName}</div>
                </div>
                <div className='client-info-row'>
                    <div>Имя пользователя</div>
                    <div>{item.username}</div>
                </div>
                <div className='client-info-row'>
                    <div>Номер телефона</div>
                    <div>{item.phone}</div>
                </div>
                <div className='client-info-row'>
                    <div>ИД Telegram</div>
                    <div>{item.telegramId}</div>
                </div>
            </div>
            <div className='client-info-right'>
                <img className='client-info-img'
                    src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                    alt="new"
                />
            </div>
        </div>
    );
};

export default ClientInfoPage;