import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdEditNote, MdDone, MdOutlineCancel } from 'react-icons/md';

import { Input, Icon } from '../../components/UI';

import { saveTicketValue } from '../../store/ticketSlice';
import { getVehicleTypeById } from '../../store/dictionarySlice';

const TicketDataItem = ({ item, tgClient=false }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    //const { onClickOutside, item } = props;

    const [isEditMode, setIsEditMode] = useState(false);
    const [value, setValue] = useState(item.data);
    const [isLoading, setIsLoading] = useState(false);

    const [text, setText] = useState(null);
    useEffect(() => {
        if (item.data) {

            const t = async () => {
                if (item.data.startsWith("vehicleTypeId#")) {
                    const response = await fetch(`/api/dictionary/vehicle/type/${item.data.replace("vehicleTypeId#", "")}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            //Authorization: `Bearer ${user.accessToken}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Server Error!');
                    }

                    const data = await response.json();
                    console.log(data);

                    setText(data.name);
                    //setValue(data.name);
                } else if (value.startsWith("vehicleMakerId#")) {
                    const response = await fetch(`/api/dictionary/vehicle/make/${item.data.replace("vehicleMakerId#", "")}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            //Authorization: `Bearer ${user.accessToken}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Server Error!');
                    }

                    const data = await response.json();
                    console.log(data);

                    setText(data.name);
                }
                else if (value.startsWith("vehicleModelId#"))
                {
                    const response = await fetch(`/api/dictionary/vehicle/model/${item.data.replace("vehicleModelId#", "")}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            //Authorization: `Bearer ${user.accessToken}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Server Error!');
                    }

                    const data = await response.json();
                    console.log(data);

                    setText(data.name);
                }
                else if (value.startsWith("БанкID#")) {
                    const response = await fetch(`/api/dictionary/bank/${item.data.replace("БанкID#", "")}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            //Authorization: `Bearer ${user.accessToken}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Server Error!');
                    }

                    const data = await response.json();
                    console.log(data);

                    setText(data.name);
                    //setValue(data.name);
                }
                else {
                    setText(item.data);
                    //setValue(item.data);
                }


            }

            t();

            //dispatch(getVehicleTypeById(61)).then((res) => {
            //    console.log("res: ", res.payload);
            //    setText(res.payload?.name);
            //    setValue(res.payload?.name)
            //});
        }
    }, [item.data]);

    //const [text, setText] = useState((state) => {
    //    if (value.startsWith("vehicleTypeId#")) {
    //        dispatch(getVehicleTypeById(value.replace("vehicleTypeId#", "")))
    //            .then((result) => console.log("res: ", result))
    //            return ""
    //    }
    //    else if (value.startsWith("vehicleMakerId#"))
    //        return "производитель транспорта";
    //    //return <></>;
    //    else if (value.startsWith("vehicleModelId#"))
    //        return "модель";
    //    else if (value.startsWith("БанкID#")) {
    //        //dispatch(getVehicleTypeById(61)).then((res) => {
    //        //    console.log("res: ", res.payload);
    //        //    return res.payload;
    //        //});
    //        //dispatch(getVehicleTypeById(61))
    //        return "банк";
    //    }
    //    else
    //        return item.data;
    //});

    const onChangeHandler = (value) => {
        setValue(value);
    };

    const setEditModeHandler = () => {
        setValue(text);
        setIsEditMode(true);
    };

    const cancelHandler = () => {
        setIsEditMode(false);
        setValue(item.data);
    };

    const saveHandler = () => {
        setIsLoading(true);
        dispatch(saveTicketValue({ id: item.id, value: value }))
            .then(() => {
                setIsLoading(false);
                setIsEditMode(false);
            })
            .catch(() => {
                console.log("Ошибка сохранения данных");
                setIsLoading(false);
            });
    };

    useEffect(() => {
        //const handleClickOutside = (event) => {
        //    if (ref.current && !ref.current.contains(event.target)) {
        //        console.log('outside click', value);

        //        saveHandler();

        //        //onClickOutside && onClickOutside();
        //    }
        //};

        const handeleClickEnter = (event) => {
            if (ref.current && ref.current.contains(event.target) && event.key === 'Enter') {
                // code for enter
                console.log('enter click', value);
                saveHandler();
            }
            if (ref.current && ref.current.contains(event.target) && event.key === 'Escape') {
                // code for enter
                console.log('escape click', value);
                cancelHandler();
            }
        };

        //document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('keypress', handeleClickEnter, true);

        return () => {
            //document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keypress', handeleClickEnter, true);
        };
    }, [value]);



    //useEffect(() => {
    //    document.addEventListener("mousedown", clickOutsideHandler);

    //    return () => {
    //        document.removeEventListener("mousedown", clickOutsideHandler);
    //    };
    //}, []);

    //const clickOutsideHandler = (e) => {
    //    console.log(e.target.classList);
    //    if (
    //        !e.target.classList.contains("string-value") &&
    //        !e.target.classList.contains('text-field__input')
    //    ) {
    //        saveHandler();
    //    };
    //};
    //const ExtendedDataText = ({ value }) => {

    //    useEffect(() => {

    //        if (value.startsWith("vehicleTypeId#")) {
    //            dispatch(getVehicleTypeById(value.replace("vehicleTypeId#", "")))
    //                .then((result) => console.log("res: ", result))
    //        }
    //        else if (value.startsWith("vehicleMakerId#"))
    //            setText("производитель транспорта");
    //        //return <></>;
    //        else if (value.startsWith("vehicleModelId#"))
    //            setText("модель");
    //        else if (value.startsWith("БанкID#")) {
    //            dispatch(getVehicleTypeById(61)).then((res) => {
    //                console.log("res: ", res.payload);
    //            });
    //            setText("банк");
    //            //return <>банк</>;
    //        }
    //        else
    //            setText(value);


    //    }, []);


    //    return (<>{text}</>)
    //};



    return (
        <div className='string-container' key={item.key + "-" + item.value } ref={ref} >
            <div className='string-key'>{item.key}</div>
            {isEditMode ?
                <div className='string-input-buttons'>
                    <Input className='string-value' value={value} onChange={(element) => onChangeHandler(element.target.value)} />
                    <div className='string-input-buttons-container'>
                        <Icon name='done' onClick={saveHandler} />
                        <div />
                        <Icon name='cancel' onClick={cancelHandler} />
                    </div>
                </div>
                :
                <div className='string-input-buttons' >
                    <div className='string-value' onDoubleClick={() => tgClient === false ? setEditModeHandler : {}}>{text}</div>
                    {tgClient === false && <Icon name='edit' onClick={setEditModeHandler} />}
                </div>
            }
        </div>
    );
};


export default TicketDataItem;