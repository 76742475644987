import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../components/UI';
import { toCurrency } from '../../additional';
import { ticketOfferStatusName } from '../../additional';
import IflOfferItem from './IflOfferItem';

//Компонент отображения сохраненного оффера
const KaskoOfferItemSaved = ({ offer, selectedOffer }) => {
    const { insurances } = useSelector(store => store.dictionaries);

    //console.log('selectedOffer ', selectedOffer, offer);

    const { offerDate, statusChangeDate, commercialOfferStatus, kaskoInsuranceCompanyOffers } = offer;

    const insuranceCompaniesIds = [...new Set(
        kaskoInsuranceCompanyOffers.map(item => item.insuranceCompanyId)
    )];


    const isSelectedOffer = (offer) => {
        //console.log(offer);
        //console.log(offer.ticketId,
        //    offer.insuranceCompanyId,
        //    //offer.commercialOfferId,
        //    offer.offerId,
        //    offer.summ,
        //    offer.franchizeOfferId,
        //    offer.franchizeSumm);

        //console.log(selectedOffer.ticketId,
        //    selectedOffer.insuranceCompanyId,
        //    //selectedOffer.commercialOfferId,
        //    selectedOffer.offerId,
        //    selectedOffer.summ,
        //    selectedOffer.franchizeOfferId,
        //    (selectedOffer.franchizeSumm ?? null));

        //console.log(offer.ticketId === selectedOffer.ticketId,
        //    offer.insuranceCompanyId === selectedOffer.insuranceCompanyId,
        //    //offer.commercialOfferId === selectedOffer.commercialOfferId,
        //    offer.offerId === selectedOffer.offerId,
        //    offer.summ === selectedOffer.summ,
        //    offer.franchizeOfferId === selectedOffer.franchizeOfferId,
        //    offer.franchizeSumm === (selectedOffer.franchizeSumm ?? null));

        if (!selectedOffer || selectedOffer === null)
            return false;

        if (
            //offer.id === selectedInsurance.ticketId &&
            offer.ticketId === selectedOffer.ticketId &&
            offer.insuranceCompanyId === selectedOffer.insuranceCompanyId &&
            //offer.commercialOfferId === selectedOffer.commercialOfferId &&
            offer.offerId === selectedOffer.offerId &&
            offer.summ === selectedOffer.summ &&
            offer.franchizeOfferId === selectedOffer?.franchizeOfferId &&
            offer.franchizeSumm === (selectedOffer?.franchizeSumm ?? null)
        )
            return true;
        else
            return false;

    }

    //console.log(insuranceCompaniesIds);
    return (
        <div className='saved-offer-item-container'>
            <div className='saved-offer-data'>
                {insuranceCompaniesIds.map(companyId => {
                    const companyName = insurances.find(x => x.id === companyId).name;
                    const companyOffers = offer.kaskoInsuranceCompanyOffers.filter(x => x.insuranceCompanyId === companyId);

                    return (
                        <div className='insurance-offer' key={companyId}>
                            <div className='comapany-name-header'>
                                <div className='company-name'>
                                    {companyName} <div className='icons-delete-chevron-up'>
                                        {/* <Icon name="delete" />   */}
                                        <Icon name="chevron-up" />
                                    </div>
                                </div>

                                <div className="saved-offer-header">
                                    <div className='saved-offer-results'>
                                        <div>Дата создания: {new Date(offerDate).toLocaleString()}</div>
                                        <div>Дата изменения статуса: {new Date(statusChangeDate).toLocaleString()}</div>
                                        <div>Статус оффера: {ticketOfferStatusName(commercialOfferStatus)}</div>
                                    </div>
                                </div>
                                <div className='container-comentary'>
                                    <p><label htmlFor="fistDescription">Основной комментарий:</label></p>
                                    <textarea className='comentary-text-area' id="fistDescription" name="fistDescription" rows="4" cols="60" defaultValue={offer.firstDescription} onChange={() => { }} />
                                </div>
                                <div className='company-offers-kasko'>
                                    {companyOffers.map(offerItem => {

                                        const fo = offerItem?.franchizeOffers.find(x => x.id === selectedOffer?.franchizeOfferId && x.summ === selectedOffer?.franchizeSumm);
                                        //console.log('FO ', fo);
                                        //console.log("Selected ", isSelectedOffer({
                                        //    //id: data.id,
                                        //    ticketId: offer.ticketId,
                                        //    insuranceCompanyId: offerItem.insuranceCompanyId,
                                        //    //commercialOfferId: offer.id,
                                        //    offerId: offerItem.id,
                                        //    summ: offerItem.summ ,//Если франшиза то null
                                        //    franchizeOfferId: fo?.id ?? null, //Если не франшиза то null
                                        //    franchizeSumm: fo?.summ ?? null
                                        //}));

                                        const so = isSelectedOffer({
                                            //id: data.id,
                                            ticketId: offer.ticketId,
                                            insuranceCompanyId: offerItem.insuranceCompanyId,
                                            //commercialOfferId: offer.id,
                                            offerId: offerItem.id,
                                            summ: offerItem.summ === 0 ? null : offerItem.summ,//Если франшиза то null
                                            franchizeOfferId: fo?.id ?? null, //Если не франшиза то null
                                            franchizeSumm: fo?.summ ?? null
                                        });

                                        return (
                                            <div key={offerItem.id} className={'offer-item-kasko ' + (so === true ? 'selected ' : '') + (offerItem.declined === true ? 'declined' : '')} >
                                                 {so === true  && offerItem.declined === false ? <div className='icon-container-offer'><Icon name = 'check'/></div> : ""}
                                                 {so === false  && offerItem.declined === true ? <div className='red-icon-container'><Icon name = "x-close"/></div> : ""}
                                                {offerItem.theft && offerItem.damage && offerItem.complete && <div className='full-kasko-header'>
                                                    <Icon name="info" />Полное каско</div>}
                                                {offerItem.theft &&
                                                    <div className="check-offer">
                                                        <Icon name="checked-line" />Угон</div>
                                                }
                                                {offerItem.damage &&
                                                    <div className="check-offer">
                                                        <Icon name="checked-line" />Ушерб</div>
                                                }
                                                <div className='offer-last-check-box'>
                                                    {offerItem.complete &&
                                                        <div className="check-offer">
                                                            <Icon name="checked-line" />Тотал</div>
                                                    }
                                                </div>
                                                {offerItem.franchizeOffers.length > 0 &&
                                                    <div className="franchize-offers">Предложение франшизы
                                                        <Icon name="checked-line" /></div>
                                                }
                                                <div className='offer-summ'>Сумма предложения</div>
                                                {offerItem.franchizeOffers.length > 0 ?
                                                    <div className='franchizes-container'>
                                                        {offerItem?.franchizeOffers?.map((item) => {
                                                            //console.log(fo == item);
                                                            const sfo = fo == item;
                                                            return (
                                                                <div className={'franschize-size-row ' + (sfo ? "selected" : '')} key={item.id}>
                                                                    <div>{item.size}</div>
                                                                    {item.summ &&
                                                                        <div className="item-summ">{item.summ}</div>}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    :
                                                    <div className='franschize-size-row no-franchise'>
                                                        {offerItem.summ &&
                                                            <div>
                                                                {/* {offerItem.summ} */}
                                                                {toCurrency(offerItem.summ, 'RUB', 'Ru-ru')}
                                                            </div>}
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className='conatiner-comentary'>
                                <p><label htmlFor="secondDescription">Дополнительный комментарий:</label></p>
                                <textarea className='comentary-text-area' id="secondDescription" name="secondDescription" rows="4" cols="60" defaultValue={offer.secondDescription} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default KaskoOfferItemSaved;