import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { StyledButton, Spinner } from '../UI';

import './modal.modules.css';

const ModalDialog = ({ title, children, isLoading=false, showModal=false, onSave, onCancel, className }) => {

    return (
        <Modal isOpen={showModal} toggle={onCancel} className={className} style={{position: 'relative'}}>
            {isLoading && <Spinner />}
            <ModalHeader className='modal-header' toggle={onCancel}>{title}</ModalHeader>
            <ModalBody className='modal-body'>
                {children}
            </ModalBody>
            <ModalFooter className='modal-footer'>
                
                <Button color="secondary" className='button-color-whyte' onClick={onCancel}>Отмена</Button>
                {' '}
                <StyledButton className='button-save' name='Сохранить' onClickHandler={onSave} />
            </ModalFooter>
        </Modal>
    );
};

export default ModalDialog;
