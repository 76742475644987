import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { NumericFormat, NumberFormatBase } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTelegram } from "../../hooks/useTelegram";
import TgHeader from '../../components/TgHeader';
import { requestTypeName, requestTypeNameMenu, fileNameByFileType } from '../../additional';
import Upload from '../../components/Upload/Upload';
import { ButtonUpload, Popover } from './MainMenu';
import { CheckBox, Icon } from '../../components/UI';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import ClipLoader from "react-spinners/ClipLoader";
import PolicyPrivacy from './PolicyPrivacy';
import { updateFileData } from '../../store/ticketSlice';


const UploadFiles = () => {
    const { height, width } = useWindowDimensions();
    const { tg } = useTelegram();
    const dispatch = useDispatch();
    const selectFilesRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [files, setFiles] = useState([]);
    const params = useParams();
    const requestTypeName = params?.type;
    const tg_user_id = params?.tg_user_id;
    const ticketId = params?.ticketId;
    const [searchParams] = useSearchParams();
    const fileTipes = searchParams.get("fileTypes")?.split(",");
    const initialFile = {
        refId: null,
        uploaded: false,
        fileName: null,
        fileId: null
    };

    const initialFileData = {
        fileType: null,
        multiple: false,
        required: true,
        //files: [{
        //    ...initialFile
        //}]
    };
    const [error, setError] = useState(null);
    const [summ, setSumm] = useState(null);

    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [acceptProcessingPersonalData, setAcceptProcessingPersonalData] = useState(false);

    const [customfiles, setCustomFiles] = useState(state => {
        let _files = [];
        if (fileTipes) {
            fileTipes.forEach((fileType) => {
                _files.push({
                    ...initialFileData,
                    required: true,
                    fileType: parseInt(fileType),
                    files: [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
            })
        }

        _files.sort((a, b) => {
            return a.fileType - b.fileType;
        });

        return _files;
        //console.log("SORTED", _files);
    });

    //// TODO добавить режим подгрузки файлов к тикету чтоб переиспользовать данную форму
    //const ticketId = params?.ticketId;
    ////Вариант для работы формы
    ////create_ticket - для создания тикета
    ////upload_to_ticket - для подгрузки к тикету
    //const upload_mode = params?.upload_mode;

    const handleFileChange = ({ target }) => {
        if (target.files) {
            //setStatus('initial');
            setIsUploading(true);
            //setFiles(target.files);
            handleUpload(target.files)
                .then((response) => {
                    console.log(response);
                    setFiles([...files, ...response]);
                    setIsUploading(false);
                    //navigate(`/tg/${tg_user_id}/${navigationData.link}/new/uploadForm`, { state: { previousPage: "/tg/MainMenu", type: "kasko" } });
                    ///tg/:type/new/uploadForm
                })
                .catch((e) => {
                    console.log(e);
                    setIsUploading(false);
                });
        }
    };

    const deleteFileHandler = (fileId) => {
        const deleteTempFile = async () => {
            const tempFiles = await fetch(
                    `api/additional/${fileTipes?.length > 0 ? "DeleteUploadedFile" : "DeleteUploadedTempFile"}/${tg_user_id}/${fileId}`, {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                    }
                });

            // set state when the data received
            if (fileTipes?.length > 0) {
                console.log(tempFiles);
                setCustomFiles(state => {
                    let newState = [...state];

                    newState.forEach(type => {
                        type.files = type.files.filter(x => x.fileId !== fileId);
                    })
                    return newState;
                });
            } else {
                setFiles(files.filter((file) => file.id !== fileId));
            }
        };
        setDeleting(true);
        deleteTempFile()
            .then(() => {

            })
            .finally(() => {
                setDeleting(false);
            });
    };

    const onFinishHandler = () => {

        const createTicketFromFiles = async () => {
            const formData = new FormData();
            formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));
            formData.append('Summ', summ);
            formData.append('AcceptProcessingPersonalData', acceptProcessingPersonalData);

            var result = await fetch(
                `api/additional/CreateTicketFromTempFiles/${requestTypeNameMenu(requestTypeName)}/${tg_user_id}`, {
                method: 'POST',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            })

            return result;
        };

        const notifyAddedFilesToTicket = async () => {
            const formData = new FormData();
            formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));
            formData.append('AcceptProcessingPersonalData', acceptProcessingPersonalData);

            var result = await fetch(
                `api/additional/notifyAddedTicketFiles/${requestTypeNameMenu(requestTypeName)}/${tg_user_id}/${ticketId}`, {
                method: 'POST',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            })

            return result;
        }

        setIsUploading(true);
        setError(null);
        if (fileTipes && fileTipes.length > 0) {
            //Логика подгрузки файлов к тикету инициализация оповещений менеджера и клиента
            notifyAddedFilesToTicket()
                .then(result => {
                    console.log(result);
                    tg.close();
                })
                .catch(error => {
                    setError("Упс, произошла ошибка, попробуйте позьже");
                })
                .finally(() => {
                    setIsUploading(false);
                });
        } else {
            //Логика когда создается новый тикет по файлам
            createTicketFromFiles()
                .then(result => {
                    console.log(result);
                    tg.close();
                })
                .catch(error => {
                    setError("Упс, произошла ошибка, попробуйте позьже");
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    };

    useEffect(() => {
        //Получим список уже загруженных файлов
        const formData = new FormData();
        formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));

        const getTempFiles = async () => {
            const tempFiles = await (

                await fetch(
                    `api/additional/GetUploadedTempFiles/${tg_user_id}`, {
                    method: 'POST',
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: formData
                }
                )
            ).json();

            // set state when the data received
            setFiles(tempFiles);
        };

        getTempFiles();

    }, []);

    //useEffect(() => {
    //    if (selectFilesRef.current) {
    //        selectFilesRef.current.click();
    //    };
    //}, [selectFilesRef]);

    const handleUpload = (files, options) => {
        if (files) {
            //setStatus('uploading');

            // Вытащили xhr из Promise, чтобы прокинуть abort
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';

            const onProgress = options?.onProgress;

            const promise = new Promise((resolve, reject) => {
                xhr.open('POST', "/api/additional/UploadTempFiles");

                xhr.upload.onprogress = (event) => {
                    onProgress?.(Math.round((event.loaded / event.total) * 100));
                };

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        //console.log(xhr.response);
                        resolve(xhr.response);
                    }
                    else reject(xhr.response);
                };

                const formData = new FormData();
                [...files].forEach((file) => {
                    //console.log(file);
                    formData.append('files', file);
                });
                formData.append('UserId', tg_user_id);
                formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));

                xhr.send(formData);
            });

            // Присвоили свойство abort, которое прервет запрос
            promise.abort = () => xhr.abort();

            return promise;

        }
    };

    // const CheckboxChangehandler = (e) => {
    //     setAcceptProcessingPersonalData(e.target.checked);
    // };
    const CheckboxChangehandler = () => {
        setAcceptProcessingPersonalData(!acceptProcessingPersonalData);
    }

    const onClickPrivacyPolicy = (e) => {
        e.preventDefault();
        setShowPrivacyPolicy(!showPrivacyPolicy);
    };

    const onSetAcceptPolicy = (value) => {
        //console.log(value);
        setAcceptProcessingPersonalData(value);
        setShowPrivacyPolicy(false);
        tg.BackButton.hide();
    }

    const updateFileUrlAndId = (index, item, e) => {
        console.log("После отгрузки +++", index, item, e);

        setCustomFiles(state => {
            let newState = [...state];

            e.forEach(file => {
                newState[index].files.push({
                    fileName: file.fileName,
                    fileId: file.id,
                    uploaded: true
                });
            })

            //newState[index].files[0].fileName = e.fileName;
            //newState[index].files[0].fileId = e.id;
            //newState[index].files[0].uploaded = true;

            //console.log("NEW STATE: ", newState);
            return newState;
        });

        //Сохраним в состояние чтоб при переключении не пропадало
        //dispatch(updateFileData(e));

    }

    const uploadedFile = (files) => {
        let uploaded = false;
        files?.forEach(file => {
            //console.log("--- ", uploaded, file.uploaded, uploaded  file.uploaded);
            if (file.uploaded === true) {
                uploaded = true;
                return uploaded;
            }

        });

        //console.log("XXX", uploaded, files)

        return uploaded;
    };

    const uploadRefs = useRef([]);

    //const uploadRefs = useRef([]);
    const uploadRef = useRef();
    //console.log("cur id", uploadRef.current);
    useEffect(() => {
        console.log("FILES", customfiles);
    }, [customfiles]);

    const isDisabled = () => {
        if (acceptProcessingPersonalData === false) return true;
            
        if ((fileTipes && fileTipes.length > 0)) {
            let isUploaded = true;
            customfiles.forEach(type => {
                let haveFiles = type.files.filter(x => x.fileId !== null);
                if (haveFiles.length === 0) {
                    isUploaded = true;
                } else {
                    isUploaded = isUploaded && false;
                }
            });
            return isUploaded;
        } else {
            //console.log(((requestTypeName === "ipoteka" || requestTypeName === "kasko") && summ.length === 0), summ.length);
            if (isUploading || files.length === 0 || acceptProcessingPersonalData === false)
                return true;
            else {
                if ((requestTypeName === "ipoteka" || requestTypeName === "kasko") && (summ === null || summ.length === 0))
                    return true;
                else
                    return false;
            }
        }
    }

    if (showPrivacyPolicy) return <PolicyPrivacy setAccepted={onSetAcceptPolicy} />

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <input hidden ref={selectFilesRef} id="file" type="file" multiple onChange={handleFileChange} />
            <TgHeader name="Документы" type={requestTypeNameMenu(requestTypeName)} />
            {isUploading && <Popover message="Дождитесь отправки файлов" />}

            <div className="upload-files-container">

                {fileTipes && fileTipes.length > 0 &&

                    <fieldset className="input-radio-group-doc-tg">
                        <legend className="input-radio-legend-tg">Прикрепите следующие документы:</legend>
                        {customfiles.map((item, index) => {
                            return (
                                <>
                                    <Upload
                                        key={index}
                                        ref={(element) => { uploadRefs.current[index] = element }}
                                        onUpload={(e) => updateFileUrlAndId(index, item, e)} //setUrl
                                        fileType={item.fileType}
                                        multiple={true}
                                        ticketId={ticketId}
                                    >
                                        <div className='icon-upload-container-tg' onClick={() => uploadRefs.current[index].upload()}>
                                            <div className='icon-upload-tg'>
                                                {uploadedFile(item?.files) === false &&
                                                    <div>
                                                        <Icon name="file-up" />
                                                    </div>
                                                }

                                                {uploadedFile(item?.files) === true &&
                                                    <div className='icon-clip'>
                                                        <Icon name="file-done" />
                                                    </div>
                                                }
                                            </div>

                                            <div className='name-doc-upload-tg'>
                                                {fileNameByFileType(item.fileType)}
                                                <div className='color-asterix-tg'>{item.required && "*"}</div>
                                            </div>


                                        </div>
                                    </Upload>
                                    <TransitionGroup className="upload-files-container-block">
                                        {customfiles[index].files.filter(x => x.fileId !== null) &&
                                            [...customfiles[index].files].filter(x => x.fileId !== null).map((file, index) => {
                                                const nodeRef = createRef(null);
                                                //console.log("added files ",file) 
                                                return (
                                                    <CSSTransition
                                                        key={index + "_" + file.fileId}
                                                        nodeRef={nodeRef}
                                                        timeout={500}
                                                        classNames="item"
                                                    >
                                                        <div className='container-upload-file-tg' key={file.fileId} ref={nodeRef}>
                                                            <div className='icon-file-upload-tg'>
                                                                <img style={{ width: 120, height: 120, border: "1px solid gray", borderRadius: 16 }} src={"api/additional/file/" + file.fileName} alt="" />
                                                            </div>
                                                            <div className='container-info-img-tg' key={file.fileName}>
                                                                <div>Документ {index + 1}</div>
                                                                {/*<div>{file.size} байт</div>*/}
                                                            </div>
                                                            {!isUploading && (deleting
                                                                ?
                                                                <ClipLoader
                                                                    className="button_spinner"
                                                                    color="#50A7EA"
                                                                    loading={isUploading}
                                                                    size={28}
                                                                />
                                                                :
                                                                <div onClick={() => deleteFileHandler(file.fileId)}><Icon name='delete' /></div>
                                                            )}
                                                        </div>
                                                    </CSSTransition>
                                                )
                                            })
                                        }

                                    </TransitionGroup>

                                </>
                                
                            )

                            //<div key={index}
                            //    ref={(element) => { refs.current[index] = element }}
                            ////Adds the current element to our refs.current array
                            //>{item}</div>
                        })}

                    </fieldset>

                }
                {!(fileTipes && fileTipes.length > 0) &&
                    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                        <div className='referal-link-container'>
                            <div className='icon-text-file-tg'> <Icon name='info-circle' /> Загрузите сюда ваш предыдущий полис</div>
                            <div className='text-info-upload-tg'>Для этого сделайте фотографии всех страниц, либо выберите из галереи</div>
                        </div>
                        <TransitionGroup className="upload-files-container-block">
                            {files &&
                                [...files].map((file, index) => {
                                    const nodeRef = createRef(null);

                                    return (
                                        <CSSTransition
                                            key={file.id}
                                            nodeRef={nodeRef}
                                            timeout={500}
                                            classNames="item"
                                        >
                                            <div className='container-upload-file-tg' key={file.id} ref={nodeRef}>
                                                <div className='icon-file-upload-tg'>
                                                    <img style={{ width: 120, height: 120, border: "1px solid gray", borderRadius: 16 }} src={"api/additional/file/" + file.fileName} alt="" />
                                                </div>
                                                <div className='container-info-img-tg' key={file.name}>
                                                    <div>Документ {index + 1}</div>
                                                    {/*<div>{file.size} байт</div>*/}
                                                </div>
                                                {!isUploading && (deleting
                                                    ?
                                                    <ClipLoader
                                                        className="button_spinner"
                                                        color="#50A7EA"
                                                        loading={isUploading}
                                                        size={28}
                                                    />
                                                    :
                                                    <div onClick={() => deleteFileHandler(file.id)}><Icon name='delete' /></div>
                                                )}
                                            </div>
                                        </CSSTransition>
                                    )
                                })
                            }

                        </TransitionGroup>

                    </div>
                }


                <div className="upload-files-container-block">
                    
                    {(requestTypeName === "ipoteka" || requestTypeName === "kasko") && !(fileTipes && fileTipes.length > 0) &&
                        <div>
                            <label className='label-input-tg'>{requestTypeName === "ipoteka" ? "Остаток основного долга по кредиту" : "Страховая сумма (Цена ТС)"}</label>
                            <NumericFormat
                                className={'masked-input'}
                                type="text"
                                placeholder={requestTypeName === "ipoteka" ? "Остаток основного долга по кредиту" : "Страховая сумма (Цена ТС)"}
                                value={summ}
                                onChange={(e) => setSumm(e.target.value)}
                                thousandSeparator=" "
                                decimalScale={requestTypeName === "ipoteka" ? 2 : 0}
                                style={{ marginBottom: 0 }}
                            />
                        </div>
                    }
                    <div className='personal-data-question'>
                        <CheckBox
                            id="acceptProcessingPersonalData"
                            value={acceptProcessingPersonalData}
                            onChange={CheckboxChangehandler}
                            tgForm={true}
                        />
                        <label htmlFor="personalDataCheckbox" className='label-policy-privacy-tg'>
                            Я даю согласие на сбор, использование и обработку моих персональных данных и сведений из предоставленных документов в соответствии с <br /><a href="tg/PolicyPrivacy" onClick={onClickPrivacyPolicy}>Примечание о конфиденциальности</a>.
                        </label>
                    </div>

                    {!(fileTipes && fileTipes.length > 0) &&
                        <ButtonUpload leftIconName="attachment" buttonType="tg-white" name="Добавить файл(ы)" isUploading={isUploading} onClick={() => {
                            //tg.close();
                            if (selectFilesRef.current) {
                                selectFilesRef.current.click();
                            };
                        }} />
                    }

                    <ButtonUpload
                        buttonType="tg-blue"
                        name="Отправить"
                        disabled={isDisabled()}
                        onClick={onFinishHandler}
                    />
                </div>

            </div>
        </div>
    );
};

export default UploadFiles;
