import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { InView, useInView, observe } from "react-intersection-observer";
import { getChats } from '../../store/clientSlice';
import { animateScroll } from "react-scroll";

import { Button } from 'reactstrap';
import { connection } from '../../store/index.js';
import { Icon } from '../../components/UI/index.js';
import Upload from '../../components/Upload/Upload';

const ClientChatPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const uploadRef = useRef();

    const { user } = useSelector(state => state.auth);
    const { chats } = useSelector(state => state.clients);
    //console.log("item ",item);
    const [isLoading, setIsLoading] = useState(false);
    const [managerMessage, setManagerMessage] = useState("");
    //const [chatList, setChatList] = useState([]);
    //const [chatMessages, setChatMessages] = useState([]);
    const delay = 200;
    
    const messageRefs = useRef([]);

    //console.log(chats);

    useEffect(() => {
        dispatch(getChats(id));
    }, []);

    useEffect(() => {
        const messagesCount = chats.filter(message =>
            (message.Viewed ?? message.viewed) === false &&
            (message.messageDirection ?? message.MessageDirection) === 0)
            .length;

        //console.log(messagesCount);
        let messageId = 0;
        let unreadMessages = [];

        if (messagesCount > 0) {
            //const messageId = chats[messagesCount - 1].Id ?? chats[messagesCount - 1].id;
            unreadMessages = chats
                .filter(message =>
                    (message.Viewed ?? message.viewed) === false &&
                    (message.messageDirection ?? message.MessageDirection) == 0)
                .sort((a, b) => (b.Id ?? b.id) - (a.id ?? a.Id));

            messageId = unreadMessages[messagesCount - 1].Id ?? unreadMessages[messagesCount - 1].id;
        } else {
            if (unreadMessages.length === 0) {
                const counter = chats.length - 1;
                messageId = (chats[counter]?.Id ?? chats[counter - 1]?.id) ?? 0;
            }
        }

        //console.log("View this: ", unreadMessages.length, messageId);

        if (messageId !== 0)
            scrollToMessage(messageId);

    }, [chats])

    const scrollToMessage = (messageId) => {
        //console.log("Нода ",messageRefs?.current[messageId]);
        messageRefs?.current[messageId]?.scrollIntoView();
        //window.scrollTo(0, messageRefs?.current[messageId]?.scrollHeight)//myRef.current.scrollHeight)
    }

    //useEffect(() => {

    //    const sendMessageNow = async () => {
    //        if (managerMessage.length > 0) {
    //            const messageToClient = {
    //                Text: managerMessage,
    //                ClientId: parseInt(id),
    //                MessageType: 0,
    //                ChatId: 0
    //            }

    //            setManagerMessage("");
    //            await connection.send("SendToClient", JSON.stringify(messageToClient));

    //        } else {
    //            alert("Введите сообщение для отправки")
    //        }
    //    }

    //    let catchCombination = (() => {
    //        let combination = [];
    //        window.addEventListener("keyup", () => {
    //            combination = [];
    //            //document.getElementById("clicked").innerHTML = ""; //мусор
    //        });
    //        return (e, userCombination) => {
    //            combination.push(e.code);
    //            console.log("Зажаты " + combination.join("+"), combination.join("+").toLowerCase(), userCombination.toLowerCase(), );
    //            //document.getElementById("clicked").innerHTML += "Зажаты " + combination.join("+") + "<br>"; //мусор
    //            if (combination.join("+").toLowerCase() === userCombination.toLowerCase()) {
    //                //document.body.innerHTML += "<span style='color:green'>Комбинация поймана!<span><br>";  //тут должен быть твой код
    //                sendMessageNow();
    //            }
    //        }
    //    })();


    //    window.addEventListener("keydown", e => catchCombination(e, "ControlLeft+Enter"));

    //    //const onKeypress = e => console.log(e);

    //    //document.addEventListener('keypress', onKeypress);

    //    return () => {
    //        document.removeEventListener('keydown', catchCombination);
    //    };
    //}, []);

    return (
        <div className="chat-container">
            <div className="chat-messages" id="chat-messages">
                {chats?.map(message =>
                    <Message
                        ref={(element) => messageRefs.current[message.Id ?? message.id] = element}
                        key={message.Id ?? message.id}
                        message={message}
                        messageRefs={messageRefs}
                    />
                )}
            </div>
            <div className="manager-send-message-container">
                <textarea className='text-area-message' value={managerMessage} rows={5} onChange={(e) => setManagerMessage(e.target.value)}></textarea>
                    <div className='btn-send-message'>
                    <Icon
                        onClick={async () => {
                            if (managerMessage.length > 0) {
                                const messageToClient = {
                                    Text: managerMessage,
                                    ClientId: parseInt(id),
                                    MessageType: 0,
                                    ChatId: 0
                                }

                                setManagerMessage("");
                                await connection.send("SendToClient", JSON.stringify(messageToClient));

                            } else {
                                alert("Введите сообщение для отправки")
                            }

                        }}
                        
                        className='send-button'
                        name={"button-send"}
                    />
                    <Upload
                        ref={(element) => { uploadRef.current = element }}
                        onUpload={(e) => {
                            //Отправим сообщение клиентув телеграмм
                            console.log(e.fileName);

                            const sendFileToClient = async() => {
                                
                                const messageToClient = {
                                    Text: e.fileName,
                                    ClientId: parseInt(id),
                                    MessageType: 0,
                                    ChatId: 0
                                }
                                await connection.send("SendFileToClient", JSON.stringify(messageToClient));

                            }

                            sendFileToClient();

                        }} 
                        //fileType={modalItem?.id}
                        uploadUrl="api/additional/UploadAnyFile"
                    >
                        <Icon
                            onClick={() => uploadRef.current.upload()}
                            name="clip"
                            className="file-button"
                        />
                    </Upload>

                    </div>
            </div>
        </div>
    );
}

export default ClientChatPage;

const Message = React.forwardRef(({ messageRefs, message }, ref) => {
    const { chats } = useSelector(state => state.clients);
    const messageId = message.Id ?? message.id;
    const currentMessage = chats.find(x => (x.id ?? x.Id) == messageId);


    const { ref: inViewRef, inView } = useInView();

    const messageType = message.messageType ?? message.MessageType;
    const messageText = message.text ?? message?.Text;
    const messageDirection = message.messageDirection ?? message.MessageDirection;


    useEffect(() => {
        //console.log(messageId, (currentMessage.viewed ?? currentMessage.Viewed) === false, (currentMessage.MessageDirection ?? currentMessage.messageDirection) === 0, inView);
        //пометим прочитанным
        if (inView == true && (currentMessage.viewed ?? currentMessage.Viewed) === false && (currentMessage.MessageDirection ?? currentMessage.messageDirection) === 0) { // && (messageRefs?.current[messageId]?.messageDirection ?? messageRefs.current[messageId]?.MessageDirection) == false)  
            //setTimeout(async () => {
            //console.log("SetReadMessage")
            connection.send("SetReadMessage", messageId);
            //}, 100);
        }
    }, [inView, messageId, currentMessage]);

    return (
        <div
            ref={(element) => messageRefs.current[messageId] = element}
            className={((message.messageDirection ?? message.MessageDirection) == 0 ? "client-message" : "manager-message")}
        >
            <div
                ref={inViewRef}
            >
                <div className="message-text">
                    {messageType === 0 &&
                        messageText
                    }
                    {messageType === 1 &&
                        <a href={"api/ticket/file/" + messageText} target="_self">
                            <img className="chat-img" src={process.env.REACT_APP_PUBLIC_URL + '/api/ticket/file/' + messageText} alt={messageText} />
                        </a>
                    }
                    {messageType === 2 &&
                        <a href={"api/ticket/file/" + messageText} target="_self">
                            <Icon className="chat-document" name={getDocumentIconName(messageText) !== "pdf" ? "document" : getDocumentIconName(messageText)} />
                        </a>
                    }
                </div>
                <div className="message-datetime"> {new Date(message.DateTime ?? message.dateTime).toLocaleString()}</div>
            </div>
        </div>
    );
});

const getDocumentIconName = (filename = null) => {
    if (filename !== null) {
        const splitName = filename.split(".");
        //console.log(filename, splitName.length);

        return splitName[splitName.length - 1];
    } else {
        return "document";
    }

}