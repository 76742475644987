import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTelegram } from "../../hooks/useTelegram";
import { Button } from "../../components/UI";
import './PolicyPrivacy.modules.css';
//import { Button } from "../../components/UI/StyledButton/StyledButton";

export const PolicyPrivacy = ({ setAccepted }) => {
    const { tg } = useTelegram();
    //const navigate = useNavigate();

    const onClickBackButtonHandler = (e) => {
        console.log("e: ", e);
        e.preventDefault();
        setAccepted(false);
        tg.BackButton.hide();
    };

    useEffect(() => {
        tg.BackButton.show();
        tg.BackButton.onClick(event => onClickBackButtonHandler(event))
        //tg.onEvent('backButtonClicked', onClickBackButtonHandler);
        return () => {
            //tg.offEvent('backButtonClicked', onClickBackButtonHandler)
            tg.BackButton.offClick(event => onClickBackButtonHandler(event))
        }
    }, [tg]);

    return (
        <div className="privacy_container">

            <h1 className="policy_header">Политика конфиденциальности сервиса Block Страхование</h1>

            <div className="section">
                <h2>1. КРАТКОЕ ОПИСАНИЕ ПОЛИТИКИ</h2>
                <p>1.1. В настоящей Политике разъясняется наша процедура обработки Персональных данных.</p>
                <p>
                    1.2. Настоящая Политика выпущена компанией Общество с ограниченной ответственностью «Эксперт Иншуранс» (далее
                    “Оператор”).
                </p>
                <p>
                    1.3. Для отражения изменений в нашей практике Обработки Персональных данных или изменений применимого
                    законодательства в настоящую Политику могут вноситься изменения и дополнения или обновления. Мы рекомендуем
                    внимательно прочитать настоящую Политику и регулярно проверять данную страницу для просмотра изменений,
                    которые мы можем вносить в условия настоящей Политики.
                </p>
                <p>
                    1.4. Настоящая Политика рассчитана на лиц, которые не входят в нашу организацию, но с которыми мы
                    взаимодействуем, включая индивидуальных заказчиков, представителей клиентов, деловых партнеров и сторонние
                    организации, посетителей наших Веб-сайтов, пользователей нашего Приложения и других пользователей наших
                    услуг (далее совместно — «вы»).
                </p>
                <p>1.5. Определяемые термины, используемые в настоящей Политике, разъясняются в Разделе 11 ниже.</p>
            </div>

            <div className="section">
                <h2>2. СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>
                    2.1 Как правило, мы будем обрабатывать Персональные данные, получаемые от вас или собираемые у вас при
                    покупке вами продукции, просмотрев Веб-сайта, использовании Приложения или непосредственном взаимодействии с
                    нами. Например, мы будем собирать или получать ваши Персональные данные в следующих случаях:
                </p>
                <ul>
                    <li>2.1.1. Мы собираем или получаем ваши Персональные данные в ходе обычных отношений с вами;</li>
                    <li>
                        2.1.2. при предоставлении нам Персональных данных напрямую (например, при обращении к нам по электронной
                        почте, телефону или с помощью любых других средств связи, предоставлении своих контактных данных или
                        отправке заявления о приеме на работу);
                    </li>
                    <li>
                        2.1.3. Мы можем собирать сведения об операции, платеже или соответствующих запросах при получении услуги
                        Оператора.
                    </li>
                    <li>
                        2.1.4. Мы можем собирать контактную информацию и сведения о вашем запросе, которые необходимы для его
                        выполнения или для предоставления вам ответа, при обращении к нам или отправке нам вопросов,
                        комментариев, предложений или жалоб;
                    </li>
                    <li>
                        2.1.5. Мы можем собирать контактную и иную информацию для рассмотрения вашего резюме при подаче заявки
                        на вакансию;
                    </li>
                    <li>
                        2.1.6. Мы можем собирать информацию, используемую вами для создания учетных записей или доступа к ним,
                        при создании таких учетных записей или получении доступа к ним на наших Веб-сайтах или в Приложении;
                    </li>
                    <li>
                        2.1.7. Мы можем собирать информацию о способах использования вами наших Веб-сайтов и вашем
                        местоположении при использовании (и установке в соответствующих случаях) наших Веб-сайтов или
                        Приложения;
                    </li>
                    <li>
                        2.1.8. Мы можем получать Персональные данные от соответствующего стороннего поставщика контента или
                        рекламы в случае вашего взаимодействия с таким сторонним контентом или рекламой на наших Веб-сайтах или
                        в Приложении (включая сторонние плагины и Файлы cookie);
                    </li>
                    <li>
                        2.1.9. Мы собираем или получаем Персональные данные от третьих лиц, которые их нам предоставляют
                        (например, бюро кредитных историй; правоохранительные органы и т. д.);
                    </li>
                    <li>
                        2.1.10. Мы собираем любые иные Персональные данные, которые вы добровольно предоставляете нам по любой
                        другой причине.
                    </li>
                </ul>
                <p>
                    2.2. Мы также можем Обрабатывать Персональные данные, к которым вы прямо предоставили общий доступ, включая
                    Персональные данные, которыми вы делитесь в отношении Группы в социальных сетях, таких как Facebook, Twitter
                    и Яндекс.
                </p>
                <p>
                    2.3. При определенных обстоятельствах мы также можем создавать Персональные данные о вас, например записи о
                    вашем взаимодействии с нами и данные о вашем взаимодействии с нами в прошлом. Мы также можем объединять
                    Персональные данные, полученные от наших Веб-сайтов, Приложения, продукции или услуг, в том числе в том
                    случае, когда такие данные собираются с разных устройств.
                </p>
                <p>
                    2.4. Также обращаем ваше внимание на то, что мы можем собирать ваши Персональные данные с помощью Файлов
                    cookie (небольших файлов данных, хранящихся на вашем устройстве и используемых для доступа к нашим
                    Веб-сайтам и их использования), веб-маяков (небольших прозрачных изображений, используемых для отслеживания
                    посещений Веб-сайтов и эффективности рекламы) и других аналогичных технологий.
                </p>
                <p>
                    2.5. Информация, собираемая с использованием таких Файлов cookie и маяков, других аналогичных технологий,
                    обычно носит более технический характер, например IP-адреса, браузеры и типы устройств.
                </p>
                <p>
                    2.6. Несмотря на то, что Файлы cookie и маяки обычно принимаются автоматически согласно настройкам вашего
                    браузера, такие настройки можно скорректировать или изменить непосредственно в браузере. Однако обращаем
                    ваше внимание на то, что для доступа к определенным функциям наших Веб-сайтов или Приложения либо для их
                    использования могут потребоваться Файлы cookie и маяки.
                </p>
                <p>
                    2.7. При использовании вами мобильного устройства для доступа к нашим Веб-сайтам мы также можем Обрабатывать
                    информацию о вашем физическом местоположении. Вы не обязаны соглашаться на предоставление такой информации и
                    можете отказаться от сбора нами такой информации, скорректировав или изменив настройки своего устройства.
                </p>
            </div>
            <div className="section">
                <h2>3. КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>3.1. Мы можем Обрабатывать следующие категории Персональных данных о вас:</p>
                <ul>
                    <li>3.1.1. Личные данные: ФИО; Ваше изображение и транспортного средства.</li>
                    <li>
                        3.1.2. Демографические сведения: пол; дата рождения / возраст; гражданство; подданство; профессия,
                        должность; языковые предпочтения; свидетельство о заключении и расторжении брака и иные акты
                        гражданского состояния, сведения об иждивенцах, включая детей; сведения о трудовой деятельности и иной
                        формы занятости, псевдонимы.
                    </li>
                    <li>
                        3.1.3. Идентифицирующие сведения: национальный идентификационный номер или паспортные данные, в том
                        числе изображения, и иные аналогичные данные.
                    </li>
                    <li>
                        3.1.4. Финансовые сведения: данные о банковских продуктах; данные о страховании и страховых продуктах
                        (услуги, товары); банковский счет и данные кредитной или дебетовой карты; сведения о вкладах, займах /
                        кредитах, кредитных картах; сведения, полученные от бюро кредитных историй, включая сведения,
                        предусмотренные законодательством о кредитных историях, и сведения об оценке вашей платежеспособности.
                    </li>
                    <li>
                        3.1.5. Контактные данные: адрес для корреспонденции; фактический адрес места жительства и пребывания;
                        номер телефона; адрес электронной почты; данные вашего публичного профиля (публичных профилей) в
                        социальных сетях.
                    </li>
                    <li>
                        3.1.6. Сведения о транспортном средстве: марка, модель транспортного средства; идентификационный номер
                        транспортного средства; государственный номер транспортного средства; дата выпуска (продажи)
                        транспортного средства; данные договора купли-продажи транспортного средства; данные об установке
                        противоугонной системы и т.п.
                    </li>
                    <li>
                        3.1.7. Записи о предоставлении согласия: записи о согласиях, которые вы могли предоставить, с указанием
                        даты и времени, способа предоставления согласия и сопутствующих сведений (например, предмета согласия).
                    </li>
                    <li>
                        3.1.8. Данные, относящиеся к нашим Веб-сайтам и Приложениям: тип устройства; операционная система; тип
                        браузера; настройки браузера; IP-адрес; языковые настройки; даты и время подключения к Веб-сайту;
                        статистика использования Приложения; настройки Приложения; даты и время подключения к Приложению; иные
                        сведения о технических средствах связи; имя пользователя; пароль; данные для безопасного входа в
                        систему; данные об использовании; сводные статистические сведения.
                    </li>
                    <li>
                        3.1.9. Данные о контенте и рекламе: записи о вашем взаимодействии с онлайн-рекламой и контентом, записи
                        о рекламе и контенте, отображаемых для вас на страницах или экранах Приложения, и о вашем возможном
                        взаимодействии с таким контентом или рекламой (включая наведение курсора мыши, щелчки мышью, заполняемые
                        вами формы (в том числе неотправленные неполные формы) и любое взаимодействие с сенсорным экраном).
                    </li>
                    <li>
                        3.1.10. Суждения и мнения: суждения и мнения, которые вы решаете отправить нам или публично размещаете о
                        нас в социальных сетях.
                    </li>
                </ul>
            </div>

            <div className="section">
                <h2>4. СПЕЦИАЛЬНЫЕ КАТЕГОРИИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>
                    4.1. Мы не стремимся собирать или иным образом Обрабатывать Специальные категории персональных данных в ходе
                    обычной коммерческой деятельности. При возникновении необходимости в Обработке Специальных категорий ваших
                    персональных данных по любой причине мы можем Обрабатывать их на следующих условиях:
                </p>
                <ul>
                    <li>
                        4.1.1. Соблюдение применимого законодательства: мы можем Обрабатывать Специальные категории ваших
                        персональных данных, если такая Обработка требуется или разрешена применимым законодательством;
                    </li>
                    <li>
                        4.1.2. Выявление и предотвращение преступлений: мы можем Обрабатывать Специальные категории ваших
                        персональных данных, если такая Обработка необходима в целях выявления или предотвращения преступления
                        (например, предотвращения мошенничества);
                    </li>
                    <li>
                        4.1.3. Установление, исполнение или защита законных требований: мы можем Обрабатывать Специальные
                        категории ваших персональных данных, если такая Обработка необходима в целях установления, исполнения
                        или защиты законных требований;
                    </li>
                    <li>
                        4.1.4. Наличие согласия: мы можем Обрабатывать Специальные категории ваших персональных данных, если в
                        соответствии с применимым законодательством мы получили ваше прямое согласие до Обработки Специальных
                        категорий ваших персональных данных (данное правовое основание действует только в отношении полностью
                        добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на любом
                        основании является необходимой или обязательной).
                    </li>
                </ul>
            </div>
            <div className="section">
                <h2>5. ЦЕЛИ И ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ДАННЫХ</h2>
                <p>
                    5.1. С учетом применимого законодательства цель Обработки Персональных данных и правовые основания
                    осуществления такой Обработки представлены ниже:
                </p>
                <ul>
                    <li>
                        <strong>Действие, относящееся к обработке данных</strong><br />
                        <strong>Предоставление Веб-сайтов, Приложения, продукции и оказание услуг:</strong>
                        <p>
                            Предоставление наших Веб-сайтов, Приложения, продукции или оказание услуг; предоставление рекламной
                            продукции по запросу; информационное взаимодействие с вами в отношении таких Веб-сайтов, Приложения,
                            продукции или услуг.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях
                            принятия мер до заключения договора с нами; у нас есть законное право на осуществление Обработки
                            данных с целью предоставления наших Веб-сайтов, Приложения, продукции или оказания услуг (в случаях,
                            когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным
                            правом); или мы получили ваше предварительное согласие на Обработку данных (данное правовое
                            основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в
                            отношении Обработки данных, которая на любом основании является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Проверки соблюдения требований:</strong>
                        <p>
                            Исполнение наших обязательств по соблюдению нормативных требований; проверки по процедуре
                            идентификации клиента; подтверждение и идентификация вашей личности; привлечение бюро кредитных
                            историй; проверка по санкционным спискам государственных и (или) правоохранительных органов, а также
                            проверка наличия иных правовых ограничений.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; Обработка данных
                            необходима в связи с любым договором, который вы заключили с нами, или в целях принятия мер до
                            заключения договора с нами; у нас есть законное право на осуществление Обработки данных с целью
                            исполнения наших нормативных и юридических обязательств (в случаях, когда ваши интересы, основные
                            права или свободы не имеют преимущественной силы над таким законным правом); или мы получили ваше
                            предварительное согласие на Обработку данных (данное правовое основание действует только в отношении
                            полностью добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на
                            любом основании является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Информационное взаимодействие и маркетинг:</strong>
                        <p>
                            Информационное взаимодействие с вами с помощью любых средств (в том числе по электронной почте,
                            телефону, с помощью текстовых сообщений, в социальных сетях, по почте или лично) для предоставления
                            новостных материалов и иной информации, которая может вас заинтересовать, с непременным учетом
                            необходимости получения вашего предварительного согласия на подписку на информационную рассылку в
                            соответствии с требованиями применимого законодательства; сохранение и обновление вашей контактной
                            информации (в соответствующих случаях); получение вашего предварительного согласия на подписку на
                            информационную рассылку (при необходимости).
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях
                            принятия мер до заключения договора с нами; у нас есть законное право на осуществление Обработки
                            данных с целью обращения к вам с учетом необходимости постоянного соблюдения применимого
                            законодательства (в случаях, когда ваши интересы, основные права или свободы не имеют
                            преимущественной силы над таким законным правом); или мы получили ваше предварительное согласие на
                            Обработку данных (данное правовое основание действует только в отношении полностью добровольной
                            Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании
                            является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Управление ИТ-системами:</strong>
                        <p>
                            Управление нашими коммуникационными, ИТ-системами и системами безопасности, а также их эксплуатация;
                            проведение аудитов (включая аудиты безопасности) и осуществление контроля в отношении таких систем.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; у нас есть законное право
                            на осуществление Обработки данных с целью обеспечения безопасной среды на наших объектах (в случаях,
                            когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным
                            правом); или обработка данных необходима для защиты жизненно важных интересов какого-либо
                            физического лица.
                        </p>
                    </li>
                    <li>
                        <strong>Охрана труда и техника безопасности:</strong>
                        <p>
                            Анализ показателей охраны труда и техники безопасности и их учет; предоставление безопасной и
                            защищенной среды на наших объектах; исполнение соответствующих юридических обязательств.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; у нас есть законное право
                            на осуществление Обработки данных с целью обеспечения безопасной среды на наших объектах (в случаях,
                            когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным
                            правом); или обработка данных необходима для защиты жизненно важных интересов какого-либо
                            физического лица.
                        </p>
                    </li>
                    <li>
                        <strong>Управление финансами:</strong>
                        <p>Продажи; финансы; проведение корпоративного аудита; управление сетью поставщиков.</p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            У нас есть законное право на осуществление Обработки данных с целью управления финансами и решения
                            финансовых вопросов в рамках нашей коммерческой деятельности (в случаях, когда ваши интересы,
                            основные права или свободы не имеют преимущественной силы над таким законным правом); или мы
                            получили ваше предварительное согласие на Обработку данных (данное правовое основание действует
                            только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки
                            данных, которая на любом основании является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Опросы:</strong>
                        <p>
                            Взаимодействие с вами с целью получения вашего мнения о наших Веб-сайтах, Приложении, продукции или
                            услугах.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            У нас есть законное право на осуществление Обработки данных с целью проведения опросов, составления
                            отчетов об уровне удовлетворенности клиентов и исследования рынка (в случаях, когда ваши интересы,
                            основные права или свободы не имеют преимущественной силы над таким законным правом); или мы
                            получили ваше предварительное согласие на Обработку данных (данное правовое основание действует
                            только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки
                            данных, которая на любом основании является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Безопасность:</strong>
                        <p>
                            Обеспечение физической безопасности наших объектов (включая записи посещений наших объектов); записи
                            видеонаблюдения; электронная безопасность (включая записи входов в систему и данные доступа).
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; или у нас есть законное
                            право на осуществление Обработки данных с целью обеспечения физической и электронной безопасности
                            нашего бизнеса и объектов (в случаях, когда ваши интересы, основные права или свободы не имеют
                            преимущественной силы над таким законным правом).
                        </p>
                    </li>
                    <li>
                        <strong>Проведение расследований:</strong>
                        <p>
                            Выявление, расследование и предотвращение нарушений политики и уголовно наказуемых преступлений в
                            соответствии с применимым законодательством.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; или у нас есть законное
                            право на осуществление Обработки данных с целью выявления нарушений наших политик и применимого
                            законодательства, а также защиты от таких нарушений (в случаях, когда ваши интересы, основные права
                            или свободы не имеют преимущественной силы над таким законным правом).
                        </p>
                    </li>
                    <li>
                        <strong>Соблюдение законов и норм:</strong>
                        <p>
                            Исполнение наших юридических и нормативных обязательств в соответствии с применимым
                            законодательством.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>Обработка данных необходима в целях исполнения юридического обязательства.</p>
                    </li>
                    <li>
                        <strong>Улучшение наших Веб-сайтов, Приложения, продукции и услуг:</strong>
                        <p>
                            Выявление проблем в работе наших Веб-сайтов, Приложения, проблем с нашей продукцией или услугами;
                            планирование улучшений наших Веб-сайтов, Приложения, продукции или услуг; создание новых Веб-сайтов,
                            Приложения, продукции или услуг.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            У нас есть законное право на осуществление Обработки данных с целью улучшения наших Веб-сайтов,
                            Приложения, продукции или услуг (в случаях, когда ваши интересы, основные права или свободы не имеют
                            преимущественной силы над таким законным правом); или мы получили ваше предварительное согласие на
                            Обработку данных (данное правовое основание действует только в отношении полностью добровольной
                            Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании
                            является необходимой или обязательной).
                        </p>
                    </li>
                    <li>
                        <strong>Предотвращение случаев мошенничества:</strong>
                        <p>Выявление, предотвращение и расследование случаев мошенничества.</p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства (в частности, в отношении
                            применимого трудового законодательства); или у нас есть законное право на осуществление Обработки
                            данных с целью выявления случаев мошенничества и защиты от них (в случаях, когда ваши интересы,
                            основные права или свободы не имеют преимущественной силы над таким законным правом).
                        </p>
                    </li>
                    <li>
                        <strong>Установление, исполнение и защита законных требований:</strong>
                        <p>
                            Управление законными требованиями; установление фактов и требований, включая сбор, рассмотрение и
                            предъявление документов, фактов, доказательств и письменных заявлений свидетелей; осуществление
                            законных прав, исполнение законных требований, защита законных прав и требований, в том числе в
                            рамках официальных судебных разбирательств.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства; у нас есть законное право
                            на осуществление Обработки данных с целью установления, осуществления или защиты наших законных прав
                            (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким
                            законным правом); или Обработка необходима в целях установления, исполнения или защиты законных
                            требований.
                        </p>
                    </li>
                    <li>
                        <strong>Подбор персонала и рассмотрение заявлений о приеме на работу:</strong>
                        <p>
                            Подбор персонала; реклама вакантных должностей; проведение собеседований; анализ соответствия
                            предполагаемой должности; записи решений в отношении найма; данные предложений; данные о принятии
                            предложений.
                        </p>
                        <strong>Правовое основание обработки данных:</strong>
                        <p>
                            Обработка данных необходима в целях исполнения юридического обязательства (в частности, в отношении
                            применимого трудового законодательства); у нас есть законное право на осуществление Обработки данных
                            с целью подбора персонала и рассмотрения заявлений о приеме на работу (в случаях, когда ваши
                            интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или
                            мы получили ваше предварительное согласие на Обработку данных (данное правовое основание действует
                            только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки
                            данных, которая на любом основании является необходимой или обязательной).
                        </p>
                    </li>
                </ul>
            </div>
            <div className="section">
                <h2>6. ХРАНЕНИЕ ДАННЫХ</h2>
                <p>
                    6.1. Мы предпринимаем все разумно необходимые действия для обеспечения Обработки ваших Персональных данных
                    исключительно в течение минимального срока, необходимого для достижения целей, установленных настоящей
                    Политикой. Критерии для определения срока хранения ваших Персональных данных представлены ниже:
                </p>
                <ul>
                    <li>
                        <strong>Срок хранения данных:</strong>
                        <p>
                            6.1.1. Мы будем хранить Персональные данные в форме, позволяющей идентифицировать их субъектов,
                            исключительно в течение того срока, пока:
                        </p>
                        <ul>
                            <li>
                                (A) мы поддерживаем с вами постоянные отношения (например, пока вы пользуетесь нашими услугами
                                или на законных основаниях включены в наш список рассылки и не отказались от подписки); или
                            </li>
                            <li>
                                (B) ваши Персональные данные необходимы для законных целей, изложенных в настоящей Политике, для
                                достижения которых у нас есть действующее правовое основание (например, если ваши персональные
                                данные включены в договор между нами и нам необходимо продолжить Обработку таких данных для
                                исполнения своих обязательств по данному договору; если у нас есть юридическое обязательство по
                                хранению ваших Персональных данных);
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Сроки исковой давности:</strong>
                        <p>6.1.2. В течение:</p>
                        <ul>
                            <li>
                                (C) применимого срока исковой давности в соответствии с применимым законодательством (т. е.
                                срока, в течение которого соответствующее лицо может предъявить нам законное требование в связи
                                с вашими Персональными данными или к которому относятся ваши Персональные данные); и
                            </li>
                            <li>
                                (D) 2 (двух) дополнительных месяцев после окончания такого применимого срока исковой давности
                                (чтобы у нас был разумно необходимый срок для выявления Персональных данных, относящихся к
                                требованию, в случае предъявления соответствующим лицом такого требования в конце срока исковой
                                давности),
                            </li>
                        </ul>
                        <p>а также:</p>
                        <ul>
                            <li>
                                6.1.3. Помимо этого, в случае предъявления соответствующих законных требований мы продолжаем
                                Обрабатывать Персональные данные в течение дополнительных сроков, необходимых в связи с таким
                                требованием.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Ограничение обработки и удаление данных:</strong>
                        <p>
                            6.2. В течение сроков, указанных в пунктах 6.1.2(A) и 6.1.2(B) выше, мы ограничим Обработку ваших
                            Персональных данных только их хранением и обеспечением их безопасности, за исключением случаев,
                            когда такие данные необходимо рассматривать в связи с законным требованием или обязательством в
                            соответствии с применимым законодательством.
                        </p>
                        <p>
                            6.3. По завершении сроков, указанных в Разделах 6.1.1, 6.1.2 и 6.1.3 выше (каждого из таких сроков в
                            соответствующем случае), мы:
                        </p>
                        <ul>
                            <li>6.3.1. Павсегда удалим или уничтожим соответствующие Персональные данные; либо</li>
                            <li>6.3.2. Обезличим соответствующие Персональные данные.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="section">
                <h2>7. РАСКРЫТИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>
                    7.1. Мы раскрываем Персональные данные другим организациям в рамках нашей группы компаний для законных
                    деловых целей и для эксплуатации наших Веб-сайта, Приложения, предоставления вам нашей продукции или
                    оказания наших услуг в соответствии с применимым законодательством. Кроме того, мы раскрываем Персональные
                    данные:
                </p>
                <ul>
                    <li>
                        7.1.1. Вам и (в соответствующих случаях) назначенным вами представителям; законодательным и регулирующим
                        органам по их запросу или для целей сообщения о фактических или предполагаемых нарушениях применимого
                        закона или нормативно-правового акта;
                    </li>
                    <li>
                        7.1.2. Бухгалтерам, аудиторам, консультантам, юристам и другим нашим сторонним профессиональным
                        консультантам, с учетом необходимости исполнения договорных обязательств по обеспечению
                        конфиденциальности, имеющих юридическую силу;
                    </li>
                    <li>
                        7.1.3. Сторонним Обработчикам данных (например, поставщикам платежных услуг; поставщикам ИТ-услуг и т.
                        д.), расположенным на территории Российской Федерации;
                    </li>
                    <li>
                        7.1.4. Соответствующей стороне, регулирующему, государственному, правоохранительному органу или суду в
                        случаях, если такое раскрытие необходимо в целях установления, исполнения или защиты законных
                        требований;
                    </li>
                    <li>
                        7.1.5. Соответствующей стороне, регулирующему, государственному, правоохранительному органу или суду в
                        целях предотвращения, расследования, выявления уголовно наказуемых преступлений, привлечения к
                        ответственности за такие преступления или исполнения уголовных наказаний;
                    </li>
                    <li>
                        7.1.6. Соответствующему стороннему приобретателю (приобретателям) или правопреемнику (правопреемникам)
                        (включая потенциального стороннего приобретателя (приобретателей) или правопреемника (правопреемников))
                        в случае продажи или передачи нами всего нашего бизнеса или всех активов либо их соответствующей части
                        (в том числе в случае реорганизации, роспуска или ликвидации); а также
                    </li>
                    <li>
                        7.1.7. Соответствующему стороннему поставщику в случае использования нашими Веб-сайтами и Приложением
                        сторонней рекламы, плагинов или контента. Если вы решите осуществить взаимодействие с такой рекламой,
                        плагинами или контентом, ваши Персональные данные могут быть переданы соответствующему стороннему
                        поставщику. Рекомендуем вам ознакомиться с политикой конфиденциальности такого третьего лица до
                        взаимодействия с его рекламой, плагинами или контентом.
                    </li>
                </ul>
            </div>

            <div className="section">
                <h2>8. ЗАЩИТА И МЕСТОНАХОЖДЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>
                    8.1. Мы внедрили определенные меры безопасности для защиты ваших Персональных данных и снижения риска их
                    утраты или кражи, получения несанкционированного доступа к ним, их раскрытия, копирования, неправомерного
                    использования или изменения. Мы также периодически обновляем и пересматриваем такие меры безопасности.
                    Следует понимать, что несмотря на использование указанных мер безопасности и наши усилия никакая система или
                    сеть не могут быть полностью защищены и что при раскрытии Персональных данных в веб-браузерах и на
                    электронных устройствах возникают неминуемые риски.
                </p>
                <p>
                    8.2. Поскольку сеть Интернет является открытой системой, передача информации через нее не является абсолютно
                    безопасной. Несмотря на то, что мы примем все разумно необходимые меры для защиты ваших Персональных данных
                    в порядке, указанном выше, мы не можем гарантировать безопасность ваших данных, передаваемых нам через сеть
                    Интернет — любая такая передача осуществляется на ваш собственный риск, при этом вы несете ответственность
                    за обеспечение безопасности отправки нам всех отправляемых вами Персональных данных.
                </p>
                <p>
                    8.3. Обращаем ваше внимание на то, что при прямой передаче вами Персональных данных в одну из наших
                    организаций мы не несем ответственности за такую передачу ваших Персональных данных. При этом с момента
                    получения ваших Персональных данных мы будем Обрабатывать их в соответствии с положениями настоящей
                    Политики.
                </p>
            </div>
            <div className="section">
                <h2>9. ВАШИ ПРАВА</h2>
                <p>
                    9.1. С учетом применимого законодательства у вас могут быть следующие права в отношении Обработки нами ваших
                    соответствующих Персональных данных:
                </p>
                <ul>
                    <li>
                        9.1.1. Право на отказ от предоставления нам ваших Персональных данных (однако обращаем ваше внимание на
                        то, что в случае непредставления нам ваших Персональных данных мы не сможем обеспечить вас всеми
                        преимуществами от использования наших Веб-сайта, Приложения, продукции или услуг (например, без
                        необходимых данных предоставление отдельных услуг может стать невозможным));
                    </li>
                    <li>
                        9.1.2. Право на запрос на получение доступа к вашим Персональным данным или их копий вместе с
                        информацией в отношении характера таких Персональных данных, их Обработки и раскрытия;
                    </li>
                    <li>9.1.3. Право на запрос на исправление неточностей в ваших Персональных данных;</li>
                    <li>
                        9.1.4. Право на запрос на удаление ваших Персональных данных на законных основаниях или на ограничение
                        Обработки ваших Персональных данных;
                    </li>
                    <li>
                        9.1.5. Право на передачу определенных Персональных данных другому Контролеру в структурированном, широко
                        используемом и машиночитаемом формате (в соответствующих случаях);
                    </li>
                    <li>
                        9.1.6. При Обработке ваших Персональных данных на основании вашего согласия — право на отзыв такого
                        согласия (следует отметить, что такой отзыв не влияет на правомерность Обработки, выполненной до даты
                        получения нами уведомления о таком отзыве и не препятствует Обработке ваших Персональных данных на любых
                        других имеющихся правовых основаниях); а также
                    </li>
                    <li>
                        9.1.7. Право на подачу жалоб в отношении Обработки ваших Персональных данных в орган по надзору за
                        соблюдением законодательства по защите данных.
                    </li>
                </ul>
                <p>
                    9.2. С учетом применимого законодательства вам предоставлены следующие дополнительные права в отношении
                    Обработки нами ваших Персональных данных:
                </p>
                <ul>
                    <li>
                        9.2.1. Право на возражение (по причинам, связанным с вашей конкретной ситуацией) против Обработки ваших
                        Персональных данных нами или от нашего имени; а также
                    </li>
                    <li>
                        9.2.2. Право на возражение против Обработки ваших Персональных данных нами или от нашего имени в целях
                        прямого маркетинга.
                    </li>
                </ul>
                <p>9.3. Данные права не влияют на ваши установленные законом права.</p>
                <p>
                    9.4. Для осуществления одного или нескольких из указанных прав, при возникновении вопросов о таких правах, о
                    любом другом положении данного уведомления или о нашей Обработке ваших Персональных данных используйте
                    контактные данные, указанные в Разделе 10. Обращаем ваше внимание на то, что:
                </p>
                <ul>
                    <li>
                        9.4.1. Если для выполнения вашего запроса необходимо установить дополнительные факты (например,
                        определить, имеются ли несоответствия процедуры Обработки данных применимому законодательству), мы
                        рассмотрим ваш запрос в разумно короткие сроки до принятия решения о дальнейших действиях.
                    </li>
                </ul>
            </div>
            <div className="section">
                <h2>10. КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>
                <p>
                    10.1. Если вы хотите задать вопросы, выразить озабоченность или подать жалобу в отношении настоящей Политики,
                    можно связаться с нашим Ответственным лицом по указанным ниже адресу места нахождения и адресу электронной
                    почты.
                </p>
                <p>
                    10.2. При необходимости внесения изменений для обеспечения точности, полноты и актуальности ваших
                    Персональных данных вы можете связаться с нами или войти в свою учетную запись (если это применимо). Вы
                    также можете отправить нам письменный запрос, если хотите получить доступ к вашим Персональным данным,
                    собранным нами. Сообщаем вам о том, что для самостоятельного изменения вами ваших Персональных данных,
                    получения к ним доступа или их просмотра мы можем попросить вас предоставить необходимые идентификационные
                    данные.
                </p>
                <p>
                    10.3. Во всех случаях мы в целом постараемся ответить на ваш запрос в течение 30 (тридцати) дней или сообщим
                    о необходимости продления срока для предоставления ответа.
                </p>
            </div>

            <div className="section">
                <h2>11. ОПРЕДЕЛЕНИЯ</h2>
                <p>11.1. В целях настоящей Политики:</p>
                <ul>
                    <li>
                        «Приложение» означает предоставляемое нами приложение (включая случаи предоставления таких приложений
                        через сторонние магазины или торговые площадки или любым другим способом).
                    </li>
                    <li>
                        «Файл cookie» означает небольшой файл, размещаемый на вашем устройстве при посещении вами веб-сайта (в
                        том числе наших Веб-сайтов). В настоящей Политике ссылка на «Файл cookie» включает аналогичные
                        технологии, такие как веб-маяки и прозрачные GIF-файлы.
                    </li>
                    <li>«Контролер» означает организацию, определяющую способ и цель Обработки Персональных данных.</li>
                    <li>
                        «Законодательство о защите данных» означает Федеральный закон Российской Федерации № 152-ФЗ «О
                        персональных данных» от 27 июля 2006 года;
                    </li>
                    <li>
                        «Персональные данные» означают информацию, которая относится к физическому лицу или позволяет прямо или
                        косвенно идентифицировать такое физическое лицо, в частности, посредством таких идентификаторов как имя,
                        идентификационный номер, сведения о местоположении, онлайн-идентификатор или с помощью одного или
                        несколько признаков, характерных для физической, психологической, генетической, умственной,
                        экономической, культурной или социальной идентичности данного физического лица.
                    </li>
                    <li>
                        «Обрабатывать», «Обработка» или «Обработанный» означают любую операцию, осуществляемую с Персональными
                        данными, с применением автоматизированных средств или без их применения, в частности, сбор, запись,
                        организацию, структурирование, хранение, модификацию или изменение, извлечение, консультирование,
                        использование, раскрытие посредством передачи, распространение или предоставление иным способом,
                        упорядочение или комбинирование, ограничение, удаление или уничтожение.
                    </li>
                    <li>
                        «Обработчик данных» означает лицо или организацию, которые Обрабатывают Персональные данные от имени
                        Контролёра (отличные от работников Контролёра).
                    </li>
                    <li>
                        «Ответственное лицо» означает руководителя по информационной безопасности, контактные данные которого
                        приведены ниже: abuse@block.ru. 111524, г. Москва, вн. тер. г. Муниципальный округ Перово, ул.
                        Электродная, д. 2, стр. 34, эт. 2 пом. 36. Телефон: +7 (495) 120-00-95
                    </li>
                    <li>
                        «Специальные категории персональных данных» означают Персональные данные, которые могут относиться к
                        специальным категориям в соответствии с применимым законодательством.
                    </li>
                    <li>«Веб-сайт» означает веб-сайт, который эксплуатируется или обслуживается нами или от нашего имени.</li>
                </ul>
            </div>
            {setAccepted &&
                <Button tgFormBlue={true} name="Принимаю условия" className="accept-button" onClick={() => setAccepted(true)} />
            }

        </div>
    );
};

export default PolicyPrivacy;