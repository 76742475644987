import React, { useState, useEffect, useCallback } from 'react';
import { Input, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { StyledButton } from './UI';

const ConfirmDeleteModal = ({ entityName, entityId, showModal, setShowModal, onDeleteHandler = () => { } }) => {
    const onDeleteEntityHandler = () => {
        //console.log(entityId);
        onDeleteHandler({ answer: true, entityId: entityId});
        setShowModal(false);
    };

    const onCancelHandler = () => {
        //console.log(entityId);
        onDeleteHandler({ answer: false, entityId: entityId});
        setShowModal(false);
    };

    return (
        <Modal
            isOpen={showModal}
            toggle={() => setShowModal(false)}
        >
            <ModalHeader toggle={() => setShowModal(false)}>
               Удаление
            </ModalHeader>
            <ModalBody>
                Вы уверены что хотите удалить {entityName}?
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    onClick={onDeleteEntityHandler}
                >
                    Удалить
                    </Button>
                {' '}
                <Button onClick={onCancelHandler}>
                    Отменить
                    </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDeleteModal;