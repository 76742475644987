import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { StyledButton, Search } from '../../components/UI';
import { Pagination, Spinner } from '../../components/UI';

import { stageName, requestTypeName, ticketStatus } from '../../additional';

import { fetchTicketsByClilentId } from '../../store/ticketSlice';
import { fetchUsers } from '../../store/userSlice';

const ClientTicketsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list } = useSelector(store => store.tickets);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();

    const item = list.find(x => x.id === parseInt(id));

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    const onEditHandler = (item) => {
        navigate(`/ticket/${item.id}`, item);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchTicketsByClilentId(id))
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                console.error(e);
            });
    }, [id]);

    return (
        <div className='client-info-ticket'>
            {isLoading && <Spinner />}
            <div className='client-info-buttons'>
                {selectedItem ?
                    <>
                        <StyledButton name="Удалить" />
                        <StyledButton name="Редактровать" onClickHandler={() => onEditHandler(selectedItem)} />
                    </>
                    :
                    <>
                        <StyledButton name="Добавить" />
                    </>
                }
            </div>

            <div className="ticket-table-container">
                <div className="ticket-table-header">
                    <div className='id'>ID</div>
                    <div className='type'>Вид запроса</div>
                    <div className='date-created'>Дата запроса</div>
                    <div className='description'>Описание</div>
                    <div className='status'>Статус</div>
                    <div className='user'>Ответственный</div>
                </div>
                {list && list.map((item) => {
                    const { id, requestTypeId, dateCreated, ticketStage, description, responsible } = item;
                    console.log(item);
                    return (
                        <div
                            className={"ticket-table-row " + (selectedItem && selectedItem.id === id ? "selected" : "")}
                            onClick={() => onSelectHandler(item)}
                            onDoubleClick={() => onEditHandler(item)}
                            key={id}
                        >
                            <div className='id'>{id}</div>
                            <div className='type'>{requestTypeName(requestTypeId)}</div>
                            <div className='date-created'>{(new Date(dateCreated)).toLocaleDateString("ru-RU")}</div>
                            <div className='description'>{description}</div>
                            <div className='status'>{ticketStatus(ticketStage)}</div>
                            <div className='user'>{responsible?.email ? responsible.email : 'Нет'}</div>
                        </div>
                    );
                })}
                <Pagination
                    //totalRecords={10}
                    pageLimit={25}
                    currentPage={currentPage}
                    pageNeighbours={5}
                    totalPages={100}
                    onPageChanged={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default ClientTicketsPage;