
import React, { useState, useEffect } from 'react';

import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';

const IflOfferItem = ({ insuranceItem, isNewOffer, onRemoveInsurance = () => { }, onAddOffer = () => { }, onChangeOffer = () => { }, onRemoveOffer = () => { } }) => {

    //console.log("+++", insuranceItem.offers);
    console.log("item: ", insuranceItem);
    //console.log("F: ", insuranceItem.ticketDatas)

    const [currenOffer, setCurrentOffer] = useState({ ...insuranceItem.offers[0] });

    const onChangeSummHandler = (value) => {
        setCurrentOffer(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            return {
                ...state,
                summ: v
            };
        });
    };

    const onChangeDataSummHandler = (id, key, value) => {
        setCurrentOffer(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            return {
                ...state,
                [id]: { ...state[id], [key]: v }
            };
        });
    };

    useEffect(() => {
        //console.log('Осаго offer изменен');
        onChangeOffer({ currenOffer, insuranceId: insuranceItem.id });
    }, [currenOffer]);

    //console.log("CURRENT: ", currenOffer);
    return (
        <div className='company-name middle'>
            <div className="company-name-row-add">
                <div className='company-name-summ'>
                    {insuranceItem?.insuranceCompany?.name}
                    <Input
                        className={'input'}
                        type="text"
                        placeholder={'Сумма страховой'}
                        //disabled={false}
                        value={currenOffer.summ === 0 ? "" : currenOffer.summ}
                        onChange={(e) => onChangeSummHandler(e.target.value)}
                    />
                </div>

                {isNewOffer &&
                    <Icon name='delete'
                        onClick={() => onRemoveInsurance({ item: insuranceItem, isSet: false })}
                    />
                }
            </div>
            {/*<Input value={currenOffer.summ} onChange={(e) => onChangeSummHandler(e.target.value)} />*/}
            <div className='ifl-offer'>
                {currenOffer.apartmentInsuranceAmount &&
                    <div className='offer-item'>
                        <div className="full-kasko-header">Квартира</div>
                        {currenOffer.apartmentInsuranceAmount.constructiveSumm &&
                            <Input
                                name="Конструктив"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.apartmentInsuranceAmount.constructiveSumm === 0 ? "" : currenOffer.apartmentInsuranceAmount.constructiveSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("apartmentInsuranceAmount", "constructiveSumm", e.target.value)}

                            />
                        }
                        {currenOffer.apartmentInsuranceAmount.finishingSumm &&
                            <Input
                                name="Отделка"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.apartmentInsuranceAmount.finishingSumm === 0 ? "" : currenOffer.apartmentInsuranceAmount.finishingSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("apartmentInsuranceAmount", "finishingSumm", e.target.value)}

                            />
                        }
                        {currenOffer.apartmentInsuranceAmount.movablePropertySumm &&
                            <Input
                                name="Движимое имущество"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.apartmentInsuranceAmount.movablePropertySumm === 0 ? "" : currenOffer.apartmentInsuranceAmount.movablePropertySumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("apartmentInsuranceAmount", "movablePropertySumm", e.target.value)}

                            />
                        }
                    </div>
                }
                {currenOffer.houseInsuranceAmount &&
                    <div className='offer-item'>
                        <div className="full-kasko-header">Дом</div>
                        {currenOffer.houseInsuranceAmount.constructiveSumm &&
                            <Input
                                name="Конструктив"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.houseInsuranceAmount.constructiveSumm === 0 ? "" : currenOffer.houseInsuranceAmount.constructiveSumm}
                                onChange={(e) => onChangeDataSummHandler("houseInsuranceAmount", "constructiveSumm", e.target.value)}
                            />
                        }
                        {currenOffer.houseInsuranceAmount.finishingSumm &&
                            <Input
                                name="Отделка"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.houseInsuranceAmount.finishingSumm === 0 ? "" : currenOffer.houseInsuranceAmount.finishingSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("houseInsuranceAmount", "finishingSumm", e.target.value)}

                            />
                        }
                        {currenOffer.houseInsuranceAmount.movablePropertySumm &&
                            <Input
                                name="Движимое имущество"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.houseInsuranceAmount.movablePropertySumm === 0 ? "" : currenOffer.houseInsuranceAmount.movablePropertySumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("houseInsuranceAmount", "movablePropertySumm", e.target.value)}
                            />
                        }
                    </div>
                }
                {currenOffer.bathInsuranceAmount &&
                    <div className='offer-item'>
                        <div className="full-kasko-header">Баня</div>
                        {currenOffer.bathInsuranceAmount.constructiveSumm &&
                            <Input
                                name="Конструктив"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.bathInsuranceAmount.constructiveSumm === 0 ? "" : currenOffer.bathInsuranceAmount.constructiveSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("bathInsuranceAmount", "constructiveSumm", e.target.value)}

                            />
                        }
                        {currenOffer.bathInsuranceAmount.finishingSumm &&
                            <Input
                                name="Отделка"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.bathInsuranceAmount.finishingSumm === 0 ? "" : currenOffer.bathInsuranceAmount.finishingSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("bathInsuranceAmount", "finishingSumm", e.target.value)}

                            />
                        }
                        {currenOffer.bathInsuranceAmount.movablePropertySumm &&
                            <Input
                                name="Движимое имущество"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.bathInsuranceAmount.movablePropertySumm === 0 ? "" : currenOffer.bathInsuranceAmount.movablePropertySumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("bathInsuranceAmount", "movablePropertySumm", e.target.value)}

                            />
                        }
                    </div>
                }
                {currenOffer.garageInsuranceAmount &&
                    <div className='offer-item'>
                        <div className="full-kasko-header">Гараж</div>
                        {currenOffer.garageInsuranceAmount.constructiveSumm &&
                            <Input
                                name="Конструктив"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.garageInsuranceAmount.constructiveSumm === 0 ? "" : currenOffer.garageInsuranceAmount.constructiveSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("garageInsuranceAmount", "constructiveSumm", e.target.value)}

                            />
                        }
                        {currenOffer.garageInsuranceAmount.finishingSumm &&
                            <Input
                                name="Отделка"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.garageInsuranceAmount.finishingSumm === 0 ? "" : currenOffer.garageInsuranceAmount.finishingSumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("garageInsuranceAmount", "finishingSumm", e.target.value)}

                            />
                        }
                        {currenOffer.garageInsuranceAmount.movablePropertySumm &&
                            <Input
                                name="Движимое имущество"
                                className={'input'}
                                type="text"
                                placeholder={'Страховая сумма'}
                                value={currenOffer.garageInsuranceAmount.movablePropertySumm === 0 ? "" : currenOffer.garageInsuranceAmount.movablePropertySumm}
                                //onChange={(e) => onChangeSummHandler(e.target.value)}
                                onChange={(e) => onChangeDataSummHandler("garageInsuranceAmount", "movablePropertySumm", e.target.value)}

                            />
                        }
                    </div>
                }
            </div>

        </div>
    );
};

export default IflOfferItem;
