import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useTelegram } from "../../hooks/useTelegram";

import { Icon, ReferalLink } from '../../components/UI';
import TgHeader from '../../components/TgHeader';
import { toCurrency, stageName, requestTypeName } from '../../additional';
import { fetchProfileData } from '../../store/profileSlice';

const Profile = () => {
    const params = useParams();
    //const history = useHistory();
    //console.log(history.lenght)


    const tg_user_id = params?.id;
    const navigate = useNavigate();
    const { tg } = useTelegram();
    const { height, width } = useWindowDimensions();
    const location = useLocation();
    const previousPage = location.state?.previousPage;

    const dispatch = useDispatch();
    const { balance, completedCount, unCompletedCount, friendsCount } = useSelector(state => state.profile);

    const onClickBackButtonHandler = () => {
        navigate(-1);
        //if (previousPage)
        //    navigate(previousPage);
    };

    useEffect(() => {
        if (tg_user_id)
            dispatch(fetchProfileData(tg_user_id));
    }, [tg_user_id]);

    useEffect(() => {
        tg.ready();

        //if (insuranceType || insuranceType === 0) {
        console.log(previousPage);
        //} else {
        if (previousPage) {
            tg.BackButton.show();

            tg.onEvent('backButtonClicked', onClickBackButtonHandler);
            return () => {
                tg.offEvent('backButtonClicked')
            }
        }
        //}

    }, [tg, previousPage]);

    const onClickMyInsurancesHandler = () => {
        navigate(`/tg/Profile/MyInsurances/${tg_user_id}`, { state: { previousPage: `/tg/Profile/${tg_user_id}` }, id: "0", insuranceCount: completedCount });
    };

    const onClickMyFriendsHandler = () => {
        navigate(`/tg/Profile/MyFriends/${tg_user_id}`, { state: { previousPage: `/tg/Profile/${tg_user_id}` }, id: "0" });
    };

    const onClickMyOffersHandler = () => {
        navigate(`/tg/Profile/MyOffers/${tg_user_id}`, { state: { previousPage: `/tg/Profile/${tg_user_id}` }, id: "0", insuranceCount: unCompletedCount });
    };

    const onClickMySettingsHandler = () => {

    };

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <TgHeader name="Профиль" />
            <div className='body-profile-tg'>
                <div className='profile-menu-line-balance'>
                    <div className='balance-text'>
                        {/* <Icon name="currency-ruble" /> */}
                        Баланс
                    </div>
                    <div className='balance-bold-text'>{toCurrency(balance, 'RUB', 'Ru-ru')}</div>
                </div>
                <div className='profile-menu-line' onClick={onClickMyInsurancesHandler}>
                    <div><Icon name="file-check" />Полисы</div>
                    <div>{completedCount}</div>
                </div>
                <div className='profile-menu-line' onClick={onClickMyOffersHandler}>
                    <div><Icon name="file-plus" />Заявки</div>
                    <div>{unCompletedCount}</div>
                </div>
                <div className='profile-menu-line' onClick={onClickMyFriendsHandler}>
                    <div><Icon name="users-tg" />Друзья</div>
                    <div>{friendsCount}</div>
                </div>

                <ReferalLink />
            </div>

        </div>
    );
};

export default Profile;