import React from 'react';

import './ButtonSelector.modules.css';

const ButtonSelector = ({ name, list, onClick }) => {
    return (
        <>
            <div className='button-selector-name'>
                {name}
            </div>
            <div className='button-selector'>
                {list.map((item) => {
                    return (
                        <div
                            className={'button-item ' + (item.isSet ? "active" : "")}
                            key={item.id}
                            onClick={() => onClick({ isSet: !item.isSet, item })}
                        >
                            {item.name ? item.name : item.size.toLocaleString("ru-RU")}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ButtonSelector;