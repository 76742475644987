import React from 'react';
import { BsGear, BsArchive } from "react-icons/bs";

const UserListItem = ({ user, onEditHandler = () => { }, onSelectHandler = () => { }, selectedItem, onArchiveHandler = () => { }}) => {
    const { id, email, role, name, surname, ticketsInWorkCount } = user;
    console.log("+++++ ", selectedItem, selectedItem, id);
    return (
        <div key={id} 
            className={"banks-row " + (selectedItem && selectedItem === id ? "selected" : "")}
            onClick={() => onSelectHandler(id)}
            onDoubleClick={() => onEditHandler(id)}
            >
            <div className='bank-id'>{id}</div>
            <div className='bank-name'>{name + ' ' + surname}</div>
            <div className='bank-email'>
                {email}
            </div>
            <div className='bank-name-role' data-role={role === 0 ? "Администратор" : "Менеджер"}></div>
             <div className='actions'>
                {/*<BsGear className='icon-button' color='green' onClick={() => onEditHandler(id)} />*/}
                {/*{email !== 'admin@system.ru' &&*/}
                {/*    <BsArchive className='icon-button' color='red' onClick={() => onArchiveHandler(id)} />*/}
                {/*}*/}
                {ticketsInWorkCount}
            </div> 
        </div>
    );
};

export default UserListItem;
