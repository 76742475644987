import React, { useState, useEffect } from 'react';

import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';
import { useSelector } from 'react-redux';
import { toCurrency } from '../../additional';
import { ticketOfferStatusName } from "../../additional";
import IflOfferItem from './IflOfferItem';

const IpotekaOfferItemSaved = ({ insuranceItem, isNewOffer, offers, selectedOffer }) => {
    const { insurances } = useSelector(state => state.dictionaries)

    const isSelectedOffer = (offer) => {
        //console.log(offer);
        //console.log(offer.ticketId,
        //    offer.insuranceCompanyId,
        //    //offer.commercialOfferId,
        //    offer.offerId,
        //    offer.summ,
        //    offer.franchizeOfferId,
        //    offer.franchizeSumm);

        //console.log(selectedOffer.ticketId,
        //    selectedOffer.insuranceCompanyId,
        //    //selectedOffer.commercialOfferId,
        //    selectedOffer.offerId,
        //    selectedOffer.summ,
        //    selectedOffer.franchizeOfferId,
        //    (selectedOffer.franchizeSumm ?? null));

        //console.log(offer.ticketId === selectedOffer.ticketId,
        //    offer.insuranceCompanyId === selectedOffer.insuranceCompanyId,
        //    //offer.commercialOfferId === selectedOffer.commercialOfferId,
        //    offer.offerId === selectedOffer.offerId,
        //    offer.summ === selectedOffer.summ,
        //    offer.franchizeOfferId === selectedOffer.franchizeOfferId,
        //    offer.franchizeSumm === (selectedOffer.franchizeSumm ?? null));

        if (!selectedOffer || selectedOffer === null)
            return false;

        if (
            //offer.id === selectedInsurance.ticketId &&
            offer.ticketId === selectedOffer.ticketId &&
            offer.insuranceCompanyId === selectedOffer.insuranceCompanyId &&
            //offer.commercialOfferId === selectedOffer.commercialOfferId &&
            offer.offerId === selectedOffer.offerId &&
            offer.summ === selectedOffer.summ
            //offer.franchizeOfferId === selectedOffer.franchizeOfferId &&
            //offer.franchizeSumm === (selectedOffer.franchizeSumm ?? null)
        )
            return true;
        else
            return false;

    }


    return (
        <div className="offerItemOsago">
            <div className="saved-offer-header">
                <div className='saved-offer-results'>
                    <div>Дата создания: {new Date(insuranceItem.offerDate).toLocaleString()}</div>
                    <div>Дата изменения статуса: {new Date(insuranceItem.statusChangeDate).toLocaleString()}</div>
                    <div>Статус оффера: {ticketOfferStatusName(insuranceItem.commercialOfferStatus)}</div>
                </div>
                {/*<div className='saved-offer-comments'>*/}
                {/*    <div>Что выбрал пользователь в данном офере</div>*/}
                {/*    <div>Комментарии для оффера:</div>*/}

                {/*</div>*/}
            </div>
            <div className='container-comentary'>
                <p><label htmlFor="fistDescription">Основной комментарий:</label></p>
                <textarea className='comentary-text-area' id="fistDescription" name="fistDescription" rows="4" cols="60" defaultValue={insuranceItem.firstDescription} onChange={() => { }} />
            </div>
            <div className="insurance-offer-osago-container">
                {insuranceItem?.ipotekaInsuranceCompanyOffers && insuranceItem?.ipotekaInsuranceCompanyOffers.map(offer => {

                    const so = isSelectedOffer({
                        //id: data.id,
                        ticketId: insuranceItem.ticketId,
                        insuranceCompanyId: offer.insuranceCompanyId,
                        //commercialOfferId: offer.id,
                        offerId: offer.id,
                        summ: offer.summ === 0 ? null : offer.summ,//Если франшиза то null
                    });

                    console.log("declined ", insuranceItem.declined, insuranceItem);
                    return (
                        <div className={"offer-item-osago-group " + (so === true ? 'selected ' : '') + (offer.declined === true ? 'declined' : '')}>
                            {so === true  && offer.declined === false ? <div className='icon-container-offer'><Icon name = 'check'/></div> : ""}
                            {so === false  && offer.declined === true ? <div className='red-icon-container'><Icon name = "x-close"/></div> : ""}                            <div className="offer-item-osago-name">
                                {insurances.find(x => x.id === offer.insuranceCompanyId)?.name}
                            </div>
                            <div className="offer-item-osago-summ">
                            {toCurrency(offer.summ, 'RUB', 'Ru-ru')}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='container-comentary'>
                <p><label htmlFor="secondDescription">Дополнительный комментарий:</label></p>
                <textarea className='comentary-text-area' id="secondDescription" name="secondDescription" rows="4" cols="60" defaultValue={insuranceItem.secondDescription} />
            </div>
        </div>

    );
};

export default IpotekaOfferItemSaved;