import React, { useEffect, } from "react";
import { useParams } from 'react-router';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useTelegram } from "../../hooks/useTelegram";

import { requestTypeNameMenu } from '../../additional';
import TgHeader from '../../components/TgHeader';
//import { useTelegramBackRoutes } from '../../hooks/useTelegramBack';

export const SubMenu = () => {
    const params = useParams();

    const [searchParams] = useSearchParams();
    const { tg } = useTelegram();
    const tg_user_id = params?.id;
    const page = params?.page;
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.state?.previousPage;
    const type = location.state?.type;

    console.log(previousPage);
    //const backRoute = useTelegramBackRoutes();
    //console.log("back button route: ", backRoute);

    const { height, width } = useWindowDimensions();

    const onClickBackButtonHandler = () => {
        //if (previousPage)
        //    navigate(previousPage);
        //history.goBack();
        navigate(-1);
    };

    useEffect(() => {
        if (previousPage) {
            tg.BackButton.show();

            tg.onEvent('backButtonClicked', onClickBackButtonHandler);
            return () => {
                tg.offEvent('backButtonClicked')
            }
        }
    }, [tg, previousPage]);

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <TgHeader type={requestTypeNameMenu(page)} />

            <div className='profile-menu-line' onClick={() => {
                tg.BackButton.show();
                navigate(`/tg/${page}/insurances/${tg_user_id}/`, { state: { previousPage: `/tg/SubMenu/${tg_user_id}/${type}` } });
            }}>
                <div>📁 Мои полисы</div>
                <div></div>
            </div>
            <div className='profile-menu-line' onClick={() => {
                tg.BackButton.show();
                navigate(`/tg/${page}/new?userId=${tg_user_id}&main_menu=1`, { state: { previousPage: `/tg/SubMenu/${tg_user_id}/${type}` } });
            }}>
                <div>📄 Новый полис</div>
                <div></div>
            </div>
            <div className='profile-menu-line' onClick={() => {
                onClickBackButtonHandler();
            }}>
                <div>🕹 Главное меню</div>
                <div></div>
            </div>
        </div>
    );
};

export default SubMenu;
