import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdEditNote, MdDone, MdOutlineCancel } from 'react-icons/md';

import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';
import { ButtonSelector } from '../../components';

import { ReactComponent as AddOfferIcon } from './add-offer.svg';

const initialStateFranchizeOffer = {
    id: 0,
    size: 0,
    summ: 0,
    isSet: true
};

//Компонент создания нового КП
const OfferItem = ({ offer, insuranceId, onRemoveOffer = () => { }, onChangeOffer = () => { } }) => {
    const franchiseOffers = useSelector(store => store.dictionaries.insurances).find(x => x.id === insuranceId)?.requestTypes.find(x => x.requestTypeId === 0)?.franchiseSizes;

    const [currenOffer, setCurrentOffer] = useState({ ...offer });
    //структура оффера
    //    id: 0,
    //    theft: false,
    //    damage: false,
    //    complete: false,
    //    summ: 0,
    //    haveFranchize: false,
    //    franchizeOffers: []
    useEffect(() => {
        console.log('Cganged offer');
        onChangeOffer({ currenOffer, insuranceId });
    }, [currenOffer]);

    const onClickTheftHandler = () => {
        setCurrentOffer(state => {
            return {
                ...state,
                theft: !state.theft,
                complete: !state.theft === false ? false : state.complete
            };
        });
    };

    const onClickDamageHandler = () => {
        setCurrentOffer(state => {
            return {
                ...state,
                damage: !state.damage,
            };
        });
    };

    const onClickCompleteHandler = () => {
        if (currenOffer.theft === true) {
            setCurrentOffer(state => {
                return {
                    ...state,
                    complete: !state.complete,
                };
            });
        };
    };

    const onClickHaveFranchiseHandler = () => {
        setCurrentOffer(state => {
            return {
                ...state,
                haveFranchize: !state.haveFranchize,
                summ: !state.haveFranchize ? 0 : state.summ,
                franchizeOffers: []
            };
        });
    };

    const onClickFranchiseOfferHandler = ({ item }) => {

        //const initialStateFranchizeOffer = {
        //    id: 0,
        //    size: 0,
        //    summ: 0
        //};

        //console.log(franchizeOffer);

        setCurrentOffer(state => {
            let frOffers = [...state.franchizeOffers]
            const frIndex = frOffers.find(x => x.id === item.id)
            if (frIndex) {
                frOffers = frOffers.filter(x => x.id !== item.id);
            } else {
                frOffers = [...frOffers, { ...initialStateFranchizeOffer, ...item }]
            }
            return {
                ...state,
                franchizeOffers: frOffers.sort((a, b) => a.size - b.size)
            };
        });
    }

    const onChangeFranchizeSummHandler = (id, value) => {

        setCurrentOffer(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            let frOffers = [...state.franchizeOffers]
            const frIndex = frOffers.findIndex(x => x.id === id);

            frOffers[frIndex].summ = v;

            return {
                ...state,
                franchizeOffers: frOffers
            };
        });
    };

    const onChangeSummHandler = (value) => {
        setCurrentOffer(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = 0;

            return {
                ...state,
                summ: v
            };
        });
    };

    //useEffect(() => {
    //    //console.log('current offer', currenOffer);
    //    //console.log(currenOffer.franchizeOffers);
    //}, [currenOffer]);

    return (
        <div key={offer.id} className='offer-item'>
            
                {currenOffer.theft && currenOffer.damage && currenOffer.complete && <div className='full-kasko-header'><Icon name='info' />Полное каско</div>}
                <CheckBox id={offer.insuranceCompanyId + "-" + offer.id + '-ugon-checkbox'} name="Угон" value={currenOffer.theft} onChange={onClickTheftHandler} />
                <CheckBox id={offer.insuranceCompanyId + "-" + offer.id + '-usherb-checkbox'} name="Ущерб" value={currenOffer.damage} onChange={onClickDamageHandler} />
                <div className='offer-last-check-box'>
                    <CheckBox id={offer.insuranceCompanyId + "-" + offer.id + '-total-checkbox'} name="Тотал" value={currenOffer.complete} disabled={!currenOffer.theft} onChange={onClickCompleteHandler} />
                </div>
                {currenOffer.theft && currenOffer.damage && currenOffer.complete && franchiseOffers?.length > 0 &&
                    <CheckBox id={offer.insuranceCompanyId + "-" + offer.id + '-franchize-checkbox'} name="Предложение франшизы" value={currenOffer.haveFranchize} onChange={onClickHaveFranchiseHandler} />
                }
                <div className='offer-summ'>Сумма предложения</div>
                {currenOffer.haveFranchize ?
                    <div className='franchizes-container'>
                        <div className='franchize-sizes-selector'>
                            <ButtonSelector
                                list={franchiseOffers.map(item => {
                                    const isSet = currenOffer.franchizeOffers.findIndex(x => x.id === item.id) === -1 ? false : true;
                                    return { ...item, isSet: isSet }
                                })}
                                onClick={onClickFranchiseOfferHandler}
                            />
                        </div>
                        {currenOffer?.franchizeOffers?.map((item) => {

                            return (
                                <div className='franschize-size-row' key={item.id}>
                                    <div className='franschize-size-row-summ'>{item.size}</div>
                                    <Input value={item.summ} onChange={(e) => onChangeFranchizeSummHandler(item.id, e.target.value)} />
                                </div>
                            );
                        })}
                    </div>
                    :
                    <div className='franschize-size-row no-franchise'>
                        <Input value={currenOffer.summ} onChange={(e) => onChangeSummHandler(e.target.value)} />
                    </div>
                }
            
            <StyledButton style='danger' onClickHandler={() => onRemoveOffer(offer.id, insuranceId)}>
                <Icon name='delete' />
                <div>Удалить оффер</div>
                {/*<MdOutlineCancel size={28} color='red' onClick={() => onRemoveOffer(offer.id, insuranceId)} className='delete-offer-icon' />*/}
            </StyledButton>
        </div>
    );
};

const KaskoOfferItem = ({ insuranceItem, isNewOffer, onRemoveInsurance = () => { }, onAddOffer = () => { }, onChangeOffer = () => { }, onRemoveOffer = () => { } }) => {
    //console.log(insuranceItem?.offers);
    return (
        <div className='insurance-offer'>
            <div className='company-name-kasko'>
                {insuranceItem?.insuranceCompany?.name}
                {isNewOffer &&
                    <Icon name='delete'
                        onClick={() => onRemoveInsurance({ item: insuranceItem, isSet: false })}
                    />
                    //<MdOutlineCancel size={28} color='red' onClick={() => onRemoveInsurance({ item: insuranceItem, isSet: false })} />
                }
            </div>
            <div className='company-offers'>
                {insuranceItem.offers?.map((item) =>
                    <OfferItem
                        key={item.id}
                        insuranceId={insuranceItem.id}
                        offer={item}
                        onChangeOffer={onChangeOffer}
                        onRemoveOffer={onRemoveOffer}
                        isNewOffer={isNewOffer}
                    />
                )}
                <div className='add-offer' onClick={() => onAddOffer(insuranceItem.id)}>
                    <AddOfferIcon />
                    <div>Добавить оффер по страховой</div>
                </div>
            </div>
        </div>
    );
};

export default KaskoOfferItem;
