import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { StyledButton, Search, Icon } from '../../components/UI';
import { Pagination } from '../../components/UI';

import { fetchTicketsWithFilter, deleteTicket } from '../../store/ticketSlice';
import { stageName, ticketStatus, requestTypeName } from '../../additional';
import { FilterContext } from '../../App';

import './TicketsPage.modules.css';
import Select from '../../components/UI';

const TicketsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [filter] = useContext(FilterContext);

    const { list } = useSelector(store => store.tickets);

    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    const onEditHandler = (item) => {
        navigate(`/ticket/${item.id}`, item);
    };

    const onDeleteHandler = (item) => {
        dispatch(deleteTicket(item.id));
    };

    //useEffect(() => {
    //    //Загрузка списка тикетов при первом открытии страницы
    //    //дальше данные будут обновляться через сокеты
    //    dispatch(fetchTicketsWithFilter({ filter: { ...filter.filterValue, searchText: filter.search } }));
    //}, []);

    useEffect(() => {
        //console.log(filter);
        //console.log('FILTER CHANGED',{ filter: { ...filter.filterValue, searchText: filter.search } });
        //Загрузка списка тикетов при первом открытии страницы
        //дальше данные будут обновляться через сокеты
        const interval = setInterval(() => {
            dispatch(fetchTicketsWithFilter({ ...filter.filterValue, searchText: filter.search }));
        }, 1000);

        return () => clearInterval(interval);

    }, [filter.search, filter.filterValue]);

    return (
        <>
            <PageHeader
                name="Тикеты"
                showSearch
                showFilter
            >
                {selectedItem ?
                    <>
                        <StyledButton className={'btn-red'} onClickHandler={() => onDeleteHandler(selectedItem)}><Icon name='delete' />Удалить</StyledButton>
                        <StyledButton className='icon-text' onClickHandler={() => onEditHandler(selectedItem)}><Icon name = 'pencil'/>Редактировать</StyledButton>
                    </>
                    :
                    <>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div>/>
                    </>
                }
            </PageHeader>
            <div className="ticket-table-container">
                <div className="ticket-table-header">
                    <div className='id'>ID</div>
                    <div className='type'>Вид запроса</div>
                    <div className='date-created'>Дата запроса</div>
                    <div className='description'>Описание</div>
                    <div className='status'>Статус</div>
                    <div className='user'>Ответственный</div>
                </div>
                {list && list.map((item) => {
                    const { id, requestTypeId, dateCreated, ticketStage, description, responsible } = item;
                    //console.log(item);
                    return (
                        <div
                            className={"ticket-table-row " + (selectedItem && selectedItem.id === id ? "selected" : "")}
                            onClick={() => onSelectHandler(item)}
                            onDoubleClick={() => onEditHandler(item)}
                            key={id}
                        >
                            <div className='id'>{id}</div>
                            <div className='type'>{requestTypeName(requestTypeId)}</div>
                            <div className='date-created'>{(new Date(dateCreated)).toLocaleDateString("ru-RU")}</div>
                            <div className={'description ' + (!description ? "accent-text" :"")}>{!description ? "Необходимо обработать файлы и заполнить данными!" : description}</div>
                            <div className='status'>{ticketStatus(ticketStage)}</div>
                            {/*<div className='user'>{responsible?.email ? responsible.email : 'Нет'}</div>*/}
                            <div className='user'>{responsible ? (responsible.name?.length > 0 || responsible.surname?.length > 0) ? (responsible.name + " " + responsible.surname) : responsible.email : 'Нет'}</div>
                        </div>
                    );
                })}
                <Pagination
                    //totalRecords={10}
                    pageLimit={25}
                    currentPage={currentPage}
                    pageNeighbours={5}
                    totalPages={100}
                    onPageChanged={setCurrentPage}
                />
            </div>
        </>
    );
};

export default TicketsPage;