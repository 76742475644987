import React from 'react';

import './user.modules.css';

const UserListHeader = () => {
    return (
        <div className='banks-header'>
            <div className='bank-id'>ID</div>
            <div className='bank-name'>ФИО</div>
            <div className='bank-email'>Email</div>
            <div className='bank-name-role'>Роль</div>
            <div className='action'>Кол-во тикетов</div>
        </div>
    );
};

export default UserListHeader;