import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

//import { useTelegram } from "../../hooks/useTelegram";

import { fetchTicket, saveTicketValue, saveTicketCommercialOffer, savePaymentAndDileveryOptions, updateFileData, changeTicketStage, declineOffer, saveClientInsuranceData, compleateWithPayTicket } from '../../store/ticketSlice';
import { fetchDictionaries } from '../../store/dictionarySlice';

import { requestTypeName, clientTicketStatus, ticketOfferStatusName, designOptionTypeName, paymentTypeName, fileNameByFileType } from '../../additional';

//import { stageName, requestTypeName, ticketOfferStatusName, getNextId } from '../../additional';

import PageHeader from '../../components/PageHeader';
import { Spinner, Icon, Button, RadioButton } from '../../components/UI';
import { TicketData, TicketCommercialOffers } from './';
import Upload, { upload } from '../../components/Upload/Upload';

import './TicketPage.modules.css';
import { CheckBox } from '../../components/UI/index';

const ticketPages = [
    {
        id: 1,
        name: "Общая информация",
    },
    {
        id: 2,
        name: "Коммерческие предложения",
    },
    //{
    //    id: 3,
    //    name: "Документы"
    //},
    {
        id: 3,
        name: "Оплата и доставка"
    },
    {
        id: 4,
        name: "Оформление",
    },
    {
        id: 5,
        name: "Задачи",
    },
    {
        id: 6,
        name: "История"
    },
];


const TicketDesignOptionAndPaymentOption = ({ data }) => {

    const [checkedFileTypes, setCheckedFileTypes] = useState([]);
    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const initialFile = {
        refId: null,
        uploaded: false,
        fileName: null,
        fileId: null
    };

    const initialFileData = {
        fileType: null,
        multiple: false,
        required: true,
        //files: [{
        //    ...initialFile
        //}]
    };

    //public PaymentOption? PaymentOption { get; set; } = null;
    //public DeliveryType ? DeliveryType { get; set; } = null;
    //public string ? DeliveryAddress { get; set; } = null;
    //public DateTime ? DileveryDate { get; set; }

    const [paymentAndDileveryOptions, setPaymentAndDileveryOptions] = useState({
        paymentOption: data.paymentOption,
        deliveryType: data.deliveryType,
        deliveryAddress: data.deliveryAddress,
        dileveryDate: data.dileveryDate
    });

    const openInNewTab = url => {
        window.open("api/ticket/file/" + url, '_blank', 'noopener,noreferrer');
    };

    const changedesignOptionTypeHandler = (type) => {
        setPaymentAndDileveryOptions(state => {
            let newState = { ...state };
            newState.deliveryType = type;
            if (type !== 2) {
                newState.dileveryDate = null;
                newState.deliveryAddress = null;
            }
            return newState;
        });

        dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, deliveryType: type }))
    }

    const changePaymentOptionTypeHandler = (type) => {
        setPaymentAndDileveryOptions(state => {
            let newState = { ...state };
            newState.paymentOption = type;

            return newState;
        });

        dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, paymentOption: type }))

    }

    const changeDateHandler = (date) => {
        setPaymentAndDileveryOptions(state => {
            let newState = { ...state };
            newState.dileveryDate = date;

            return newState;
        });

        dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, dileveryDate: date }))

    }

    const changeAddressHandler = (address) => {
        setPaymentAndDileveryOptions(state => {
            let newState = { ...state };
            newState.deliveryAddress = address;

            return newState;
        });

        dispatch(savePaymentAndDileveryOptions({ ...paymentAndDileveryOptions, ticketId: data.id, deliveryAddress: address }))
    }
    //useEffect(() => {
    //    //console.log("PO: ", paymentAndDileveryOptions);
    //    if (data && paymentAndDileveryOptions) {
    //        dispatch(savePaymentAndDileveryOptions({...paymentAndDileveryOptions, ticketId: data.id }))
    //    };

    //}, [paymentAndDileveryOptions, data]);

    const [url, setUrl] = useState();
    const [urls, setUrls] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    const [files, setFiles] = useState(state => {
        let _files = [];
        //console.log(data?.requestTypeId);



        //Паспорт у всех
        _files.push({
            ...initialFileData,
            fileType: 0,
            files: data.files.filter(x => x.fileType === 0).length !== 0 ?
                data.files.filter(x => x.fileType === 0).map(x => {
                    return {
                        refId: null,
                        uploaded: true,
                        fileName: x.fileName,
                        fileId: x.id
                    }
                }) :
                [{ ...initialFile }]

        });

        //Первый дополнительный пасотр пустой но можно будет добавить еще
        _files.push({
            ...initialFileData,
            multiple: true,
            required: false,
            fileType: 1,
            files: data.files.filter(x => x.fileType === 1).length !== 0 ?
                data.files.filter(x => x.fileType === 1).map(x => {
                    return {
                        refId: null,
                        uploaded: true,
                        fileName: x.fileName,
                        fileId: x.id
                    }
                }) :
                [{ ...initialFile }]
            //files: [{
            //    ...initialFile
            //}]
        });

        //Договор страхования прошлого года (для согласования скидки за переход в другую СК)
        _files.push({
            ...initialFileData,
            fileType: 8,
            files: data.files.filter(x => x.fileType === 8).length !== 0 ?
                data.files.filter(x => x.fileType === 8).map(x => {
                    return {
                        refId: null,
                        uploaded: true,
                        fileName: x.fileName,
                        fileId: x.id
                    }
                }) :
                [{ ...initialFile }]
            //files: [{
            //    ...initialFile
            //}]
        });


        //каско и осаго
        if (data?.requestTypeId === 0 || data?.requestTypeId === 1) {

            //ПТС
            // _files.push({
            //     ...initialFileData,
            //     fileType: 10,
            //     //files: [{
            //     //    ...initialFile
            //     //}]
            //     files: data.files.filter(x => x.fileType === 10).length !== 0 ?
            //         data.files.filter(x => x.fileType === 10).map(x => {
            //             return {
            //                 refId: null,
            //                 uploaded: true,
            //                 fileName: x.fileName,
            //                 fileId: x.id
            //             }
            //         }) :
            //         [{ ...initialFile }]
            // });

            //СТС
            _files.push({
                ...initialFileData,
                fileType: 11,
                //files: [{
                //    ...initialFile
                //}]
                files: data.files.filter(x => x.fileType === 11).length !== 0 ?
                    data.files.filter(x => x.fileType === 11).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
            });

            //Водительские удостоверения
            _files.push({
                ...initialFileData,
                multiple: true,
                fileType: 12,
                //files: [{
                //    ...initialFile
                //}]
                files: data.files.filter(x => x.fileType === 12).length !== 0 ?
                    data.files.filter(x => x.fileType === 12).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
            });

            //Только каско
            if (data?.requestTypeId === 0) {
                //Кредитный договор (1.2.3 страницы и последняя страница с подписью и печатью банка).
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 7,
                    files: data.files.filter(x => x.fileType === 7).length !== 0 ?
                        data.files.filter(x => x.fileType === 7).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
            };
            //Тллько осаго
            if (data?.requestTypeId === 1) {

            };
        };
        //Ипотека и ифл
        if (data?.requestTypeId === 2 || data?.requestTypeId === 3) {
            //ЕГРН
            _files.push({
                ...initialFileData,
                fileType: 2,
                files: data.files.filter(x => x.fileType === 2).length !== 0 ?
                    data.files.filter(x => x.fileType === 2).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });
            //договор купли-продажи/мены;
            _files.push({
                ...initialFileData,
                fileType: 3,
                files: data.files.filter(x => x.fileType === 3).length !== 0 ?
                    data.files.filter(x => x.fileType === 3).map(x => {
                        return {
                            refId: null,
                            uploaded: true,
                            fileName: x.fileName,
                            fileId: x.id
                        }
                    }) :
                    [{ ...initialFile }]
                //files: [{
                //    ...initialFile
                //}]
            });



            //Только ипотека
            if (data?.requestTypeId === 3) {
                //Справка, удостоверяющая рыночную стоимость жилья, земли
                _files.push({
                    ...initialFileData,
                    fileType: 6,
                    files: data.files.filter(x => x.fileType === 6).length !== 0 ?
                        data.files.filter(x => x.fileType === 6).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                // Паспорт продавца (все страницы)
                _files.push({
                    ...initialFileData,
                    fileType: 21,
                    files: data.files.filter(x => x.fileType === 21).length !== 0 ?
                        data.files.filter(x => x.fileType === 21).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                // Справки ПД/ПНД
                _files.push({
                    ...initialFileData,
                    fileType: 22,
                    files: data.files.filter(x => x.fileType === 22).length !== 0 ?
                        data.files.filter(x => x.fileType === 22).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });

                // Доверенность на продажу
                _files.push({
                    ...initialFileData,
                    fileType: 23,
                    files: data.files.filter(x => x.fileType === 23).length !== 0 ?
                        data.files.filter(x => x.fileType === 23).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });

                //Кредитный договор (1.2.3 страницы и последняя страница с подписью и печатью банка).
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 7,
                    files: data.files.filter(x => x.fileType === 7).length !== 0 ?
                        data.files.filter(x => x.fileType === 7).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //Отчёт об оценке
                _files.push({
                    ...initialFileData,
                    fileType: 9,
                    files: data.files.filter(x => x.fileType === 9).length !== 0 ?
                        data.files.filter(x => x.fileType === 9).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //свидетельство о праве собственности;
                _files.push({
                    ...initialFileData,
                    fileType: 4,
                    files: data.files.filter(x => x.fileType === 4).length !== 0 ?
                        data.files.filter(x => x.fileType === 4).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
                //договор передачи/дарения;
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 5,
                    files: data.files.filter(x => x.fileType === 5).length !== 0 ?
                        data.files.filter(x => x.fileType === 5).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });

            }
            //Только ифл
            if (data?.requestTypeId === 2) {
                //Паспорт страхователя (стр. 2-3 и стр. с актуальной регистрацией)
                //_files.push({
                //    ...initialFileData,
                //    fileType: 17,
                //    files: data.files.filter(x => x.fileType === 17).length !== 0 ?
                //        data.files.filter(x => x.fileType === 17).map(x => {
                //            return {
                //                refId: null,
                //                uploaded: true,
                //                fileName: x.fileName,
                //                fileId: x.id
                //            }
                //        }) :
                //        [{ ...initialFile }]
                //    //files: [{
                //    //    ...initialFile
                //    //}]
                //});
                //Действующий полис (при наличии)
                _files.push({
                    ...initialFileData,
                    required: false,
                    fileType: 20,
                    files: data.files.filter(x => x.fileType === 18).length !== 0 ?
                        data.files.filter(x => x.fileType === 18).map(x => {
                            return {
                                refId: null,
                                uploaded: true,
                                fileName: x.fileName,
                                fileId: x.id
                            }
                        }) :
                        [{ ...initialFile }]
                    //files: [{
                    //    ...initialFile
                    //}]
                });
            }
        }

        _files.sort((a, b) => {
            return a.fileType - b.fileType;
        });

        return _files;
        //console.log("SORTED", _files);
    });


    const updateFileUrlAndId = (index, item, e) => {
        //console.log("+++", index, item, e);

        setFiles(state => {
            let newState = [...state];
            newState[index].files[0].fileName = e.fileName;
            newState[index].files[0].fileId = e.id;
            newState[index].files[0].uploaded = true;

            //console.log("NEW STATE: ", newState);
            return newState;
        });

        //Сохраним в состояние чтоб при переключении не пропадало
        dispatch(updateFileData(e));

    }

    const uploadedFile = (files) => {
        let uploaded = false;
        files?.forEach(file => {
            //console.log("--- ", uploaded, file.uploaded, uploaded  file.uploaded);
            if (file.uploaded === true) {
                uploaded = true;
                return uploaded;
            }

        });

        //console.log("XXX", uploaded, files)

        return uploaded;
    };

    const uploadRefs = useRef([]);

    //const uploadRefs = useRef([]);
    const uploadRef = useRef();
    //console.log("cur id", uploadRef.current);
    useEffect(() => {
        //console.log("FILES", files);
    }, [files]);

    const isCheckedFileType = (id) => {
        console.log(id, checkedFileTypes.find(x => x.id === id) ? true : false);
        if (checkedFileTypes.find(x => x.id === id))
            return true;
        else
            return false;
    }

    const setCheckedFileTypesHandler = (value) => {
        //console.log("file-id", value.id, checkedFileTypes, checkedFileTypes.find(x => x.id == value.id));

        setCheckedFileTypes(state => {
            if (state.find(x => x.id == value.id)) {
                return state.filter(x => x.id != value.id);
            } else {
                return [...state, value];
            }
        });
    };

    const requestFilesHandler = () => {
        const requestFiles = async () => {
            console.log(`api/ticket/RequestFilesFromClient/${data.client.id}/${data.requestTypeId}/${data.id}`)
            const resp = await fetch(
                `api/ticket/RequestFilesFromClient/${data.client.id}/${data.requestTypeId}/${data.id}`, {
                method: 'POST',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    Authorization: `Bearer ${user.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checkedFileTypes.map(x => x.id))
            });

            if (resp.status === 200) {

            } else {
                throw "Error request files";
            }
        };

        requestFiles()
            .then(() => {
                setCheckedFileTypes([]);
            })
            .catch(e => {

            })
            .finally(() => {
                setDisabledButton(false);
                alert("Файлы запрошены у клиента");
            });
    };

    return (

        // <div className='container-data-primary'>
        <div className='container-data'>
            <div className='text-container'>Выберите вариант оформления полиса, вид доставки а также прикрепите необходимые файлы для продолжения</div>

            {/*<div>Вариант оформления</div>*/}

            <fieldset className="input-radio-group">
                <legend className="input-radio-legend">Вариант оформления:</legend>

                <div className="input-radio">
                    <RadioButton
                        id="designOptionType_0"
                        name="designOptionType"
                        value="0"
                        checked={paymentAndDileveryOptions.deliveryType === 0}
                        onChange={() => changedesignOptionTypeHandler(0)}
                        label={'Дистанционное (оформление полиса в электронном виде с печатью и подписью факсимиле)'}
                    />
                    {/* <label htmlFor="designOptionType_0">В офисе страховой компании</label> */}
                </div>

                {/* <div className="input-radio">
                    <RadioButton
                        id="designOptionType_1"
                        name="designOptionType"
                        value="1"
                        checked={paymentAndDileveryOptions.deliveryType === 1}
                        onChange={() => changedesignOptionTypeHandler(1)}
                        label={'В нашем ближайшем офисе'}
                    />
                </div> */}
                {data?.requestTypeId !== 3 &&
                    <>
                        <div className="input-radio">
                            <RadioButton
                                type="radio"
                                id="designOptionType_2"
                                name="designOptionType"
                                value="2"
                                checked={paymentAndDileveryOptions.deliveryType === 2}
                                onChange={() => changedesignOptionTypeHandler(2)}
                                label={'Доставка в любое удобное время и место (бесплатно)'}
                            />
                        </div>
                    </>}

            </fieldset>

            {paymentAndDileveryOptions.deliveryType === 2 &&
                <>
                    <div className='delivery-container'>
                        <div className='delivery-adres'>
                            <div className='text-delivery'>Адрес доставки</div>
                            <input
                                type="text"
                                //multiple
                                placeholder="Укажите адрес доставки"
                                value={paymentAndDileveryOptions.deliveryAddress ?? ''}
                                onChange={e => changeAddressHandler(e.target.value)}
                                className='input-container-delivery'
                            />
                        </div>
                        <div className='delivery-date-time'>
                            <div className='text-delivery'>Дата и время доставки</div>
                            <DatePicker
                                selected={paymentAndDileveryOptions.dileveryDate ? new Date(paymentAndDileveryOptions.dileveryDate) : null}
                                minDate={new Date()}
                                placeholderText='Выберете дату и время'
                                onChange={changeDateHandler}
                                className='data-picker'
                                dateFormat="dd.MM.yyyy HH:mm"
                                isClearable
                                //timeInputLabel="Время:"
                                timeCaption="Время:"
                                showTimeSelect
                                timeIntervals={10}
                            />
                        </div>
                    </div>
                </>
            }

            <fieldset className="input-radio-group">
                <legend className="input-radio-legend">Вариант оплаты:</legend>

                {/* <div className="input-radio">
                    <RadioButton
                        type="radio"
                        id="paymentType_0"
                        name="paymentType"
                        value="0"
                        checked={paymentAndDileveryOptions.paymentOption === 0}
                        onChange={() => changePaymentOptionTypeHandler(0)}
                        label={'В кассу страховой компании'}
                    />
                </div> */}
                {data?.requestTypeId !== 3 &&
                    <>
                        <div className="input-radio">
                            <RadioButton
                                type="radio"
                                id="paymentType_1"
                                name="paymentType"
                                value="1"
                                checked={paymentAndDileveryOptions.paymentOption === 1}
                                onChange={() => changePaymentOptionTypeHandler(1)}
                                label={'Наличными выездному специалисту'}
                            />
                            {/* <label htmlFor="paymentType_1">Наличными выездному специалисту</label> */}
                        </div>
                        {data?.requestTypeId !== 2 &&
                            <div className="input-radio">
                                <RadioButton
                                    type="radio"
                                    id="paymentType_2"
                                    name="paymentType"
                                    value="2"
                                    checked={paymentAndDileveryOptions.paymentOption === 2}
                                    onChange={() => changePaymentOptionTypeHandler(2)}
                                    label={'По карте выездному специалисту'}
                                />

                            </div>
                        }
                    </>
                }


                <div className="input-radio">
                    <RadioButton
                        type="radio"
                        id="paymentType_3"
                        name="paymentType"
                        value="3"
                        checked={paymentAndDileveryOptions.paymentOption === 3}
                        onChange={() => changePaymentOptionTypeHandler(3)}
                        label={'По выставленному счету от страховой компании. Оплата без комиссии картой любого банка по ссылке от страховой компании или в мобильном банке.'}
                    />
                    {/* <label htmlFor="paymentType_3">По выставленному счету от СК на расчётный счёт страховой компании в любом банке или в онлайн банке, в том числе если выбран вариант доставки.</label> */}
                </div>
            </fieldset>

            <fieldset className="input-radio-group-doc">
                <legend className="input-radio-legend-doc">Документы:</legend>
                {files.map((item, index) => {
                    return (
                        <div className="upload-file" key={index}>
                            <div className="upload-file-row">
                                <CheckBox
                                    id={item.fileType}
                                    value={isCheckedFileType(item.fileType)}
                                    //name={"check_file_type_id_" + item.fileType}
                                    onChange={setCheckedFileTypesHandler}
                                />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Upload
                                        key={index}
                                        ref={(element) => { uploadRefs.current[index] = element }}
                                        onUpload={(e) => updateFileUrlAndId(index, item, e)} //setUrl
                                        fileType={item.fileType}
                                        ticketId={data.id}
                                    >
                                        <div className='upload-box' onClick={() => uploadRefs.current[index].upload()}>

                                            {/* <div style={{ position: 'absolute', width: "100%", height: "100%" }}>
                                            {uploadedFile(item?.files) ?
                                                <div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'green', borderRadius: 3, overflow: 'hidden' }} />
                                                :
                                                <>{item.required && < div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'red', borderRadius: 3, overflow: 'hidden' }} />}</>
                                            }
                                        </div>
                                        <div className='icon-clip'><Icon name="clip" /></div> */}
                                            <div className='icon-upload'>
                                                {uploadedFile(item?.files) === false &&
                                                    <div>
                                                        <Icon name="file-up" />
                                                    </div>
                                                }

                                                {uploadedFile(item?.files) === true &&
                                                    <div className='icon-clip'>
                                                        <Icon name="file-done" />
                                                    </div>
                                                }
                                            </div>

                                            {/* <div>{fileNameByFileType(item.fileType)}</div> */}

                                            {fileNameByFileType(item.fileType)}
                                            <div className='color-asterix'>{item.required && "*"}</div>


                                        </div>

                                    </Upload>
                                    <div className="uploaded-files-container" style={{ border: "none", marginTop: 4, paddingTop: 4, gap: 8 }}>
                                        {data?.files && data.files.filter(x => x.fileType === item.fileType).map((item, index) => {
                                            //console.log(data.files)
                                            return (
                                                <a key={item.id} className='file-description' href={"api/ticket/file/" + item.fileName} target="_self" >
                                                    {index + 1}
                                                </a>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                    //<div key={index}
                    //    ref={(element) => { refs.current[index] = element }}
                    ////Adds the current element to our refs.current array
                    //>{item}</div>
                })}

            </fieldset>

            <div className="files_buttons_container">
                <a className='file-description' href={"/api/additional/DownloadFiles/" + data.id} target="_blank" rel="noopener noreferrer" download >
                    Скачать файлы архивом
                </a>
                {checkedFileTypes.length > 0 &&
                    <Button
                        name={"Запросить файлы " + checkedFileTypes.length + " вид" + (checkedFileTypes.length === 1 ? "а" : "ов") + " у клиента"}
                        onClick={requestFilesHandler}
                        disabled={disabledButton}
                    />
                }
            </div>

        </div>
        // </div>
    );
};

const RegistrationOfDocuments = ({ data }) => {
    console.log(data);

    const dispatch = useDispatch();
    const insuranceFileRef = useRef();
    //const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { insurances } = useSelector(state => state.dictionaries);
    console.log("insurances ", insurances);
    const [declineInfo, setDeclineInfo] = useState("Страховая компания отказала в страховании, выберите другое предложение. \n");

    const [declineInsurance, setDeclineInsurance] = useState(false);
    const [acceptInsurance, setAcceptInsurance] = useState(state => {
        return data.insuranceNumber ? true : false;
    });

    const [insuramceData, setInsuramceData] = useState(state => {
        return {
            insuranceCompanyId: data.selectedOffer?.insuranceCompanyId ?? null,
            insuranceNumber: data.insuranceNumber,
            insuranceDateStart: data.insuranceDateStart,
            insuranceDateEnd: data.insuranceDateEnd,
            insuranceSumm: data.insuranceSumm,
            insuranceFileName: data.insuranceSumm
        };
    });
    //const insuramceData = ;

    const onChangeInsuranceNumber = (value) => {
        setInsuramceData(state => {
            return { ...state, insuranceNumber: value }
        })
    };

    const onChangeInsuranceDateStart = (date) => {
        setInsuramceData(state => {
            let newState = { ...state };
            newState.insuranceDateStart = date;

            return newState
        })
    };

    const onChangeInsuranceDateEnd = (date) => {
        setInsuramceData(state => {
            let newState = { ...state };
            newState.insuranceDateEnd = date;

            return newState
        })
    };

    const onChangeInsuranceSumm = (value) => {
        setInsuramceData(state => {
            let v = parseInt(value);
            if (isNaN(v)) v = "";
            return { ...state, insuranceSumm: v.toString() }
        })
    };

    const onChangeInsuranceFileName = (value) => {
        setInsuramceData(state => {
            return { ...state, insuranceFileName: value }
        })
    };

    useEffect(() => {
        console.log("insuramceData: ", insuramceData);
    }, [insuramceData]);

    const onSaveClientInsurance = () => {
        console.log("Перейти к ожиданию полаты и доставки: ", {
            ticketId: data.id,
            ...insuramceData,
            insuranceDateStart: insuramceData.insuranceDateStart,
            insuranceDateEnd: insuramceData.insuranceDateEnd
        });
        setIsLoading(true);
        dispatch(saveClientInsuranceData({
            ticketId: data.id,
            ...insuramceData,
            insuranceDateStart: insuramceData.insuranceDateStart,
            insuranceDateEnd: insuramceData.insuranceDateEnd
        })).then(() => {
            dispatch(fetchTicket(data.id)).then(() => {
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
    };

    const onPaidHandler = (ticketId) => {
        console.log("PAID", ticketId);
        setIsLoading(true);
        dispatch(compleateWithPayTicket(ticketId)).then(() => {
            dispatch(fetchTicket(ticketId)).then(() => {
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            {data.ticketStage === 14 &&
                <Button
                    name="Отправил документы на оформление"
                    onClick={() => {
                        dispatch(changeTicketStage({ ticketId: data.id, stage: 15 }))
                            .then(() => {

                            })
                    }}
                />
            }
            {data.ticketStage === 15 &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 24 }}>
                    {!acceptInsurance &&
                        <Button
                            name={!declineInsurance ? "Отказ страховой" : "Отменить отказ страховой"}
                            type='danger'
                            onClick={() => {
                                setDeclineInsurance(!declineInsurance);
                            }}
                        />
                    }

                    {!declineInsurance &&
                        <Button
                            name={!acceptInsurance ? "Добавить полис" : "Отменить добавление полиса"}
                            type='secondary'
                            onClick={() => {
                                setAcceptInsurance(!acceptInsurance)
                            }}
                        />
                    }

                </div>
            }
            {declineInsurance &&
                <>
                    <textarea value={declineInfo} rows={10} onChange={(e) => { setDeclineInfo(e.target.value) }} />
                    <Button
                        name="Отказ в оформлении предложения"
                        type='danger'
                        onClick={() => {
                            console.log("Отказ в оформлении предложения");

                            dispatch(declineOffer({ ticketId: data.id, comment: declineInfo })).then(() => {
                                setDeclineInfo("Страховая компания отказала в страховании, выберите другое предложение. \n");
                                setDeclineInsurance(false);
                            });
                        }}
                    />
                </>
            }

            {acceptInsurance &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <div>Страховая компания</div>
                        <div>{insurances?.find(x => x.id === data?.selectedOffer?.insuranceCompanyId)?.name ?? ""}</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <div>Номер страхового полиса</div>
                        <input
                            type="text"
                            //multiple
                            placeholder="Серия и номер полиса"
                            value={insuramceData.insuranceNumber ?? ''}
                            onChange={e => onChangeInsuranceNumber(e.target.value)}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <div>Дата начала действия страхового полиса</div>
                        <DatePicker
                            selected={insuramceData.insuranceDateStart ? new Date(insuramceData.insuranceDateStart) : null}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            dateFormat="dd.MM.yyyy"
                            placeholderText='Начало действия'
                            //    value={insuramceData.insuranceDateStart ?? ''}
                            onChange={onChangeInsuranceDateStart}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <div>Дата окончания действия страхового полиса</div>
                        <DatePicker
                            selected={insuramceData.insuranceDateEnd ? new Date(insuramceData.insuranceDateEnd) : null}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            dateFormat={"dd.MM.yyyy"}
                            placeholderText="Окончание действия"
                            //  value={insuramceData.insuranceDateEnd ?? ''}
                            onChange={onChangeInsuranceDateEnd}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <div>Сумма страхования по полису</div>
                        <input
                            type="text"
                            //multiple
                            placeholder="Сумма полиса"
                            value={insuramceData.insuranceSumm ?? ''}
                            onChange={e => onChangeInsuranceSumm(e.target.value)}
                        />
                    </div>

                    <Upload
                        ref={(element) => { insuranceFileRef.current = element }}
                        onUpload={(e) => onChangeInsuranceFileName(e.fileName)} //setUrl
                        fileType={"ClientInsurance"}
                        ticketId={data.id}
                        uploadUrl="api/additional/UploadAnyFile"
                    >
                        <div
                            style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: "100%", backgroundColor: 'lightgray', padding: 8 }}
                            onClick={() => insuranceFileRef.current.upload()}
                        >
                            <div style={{ position: 'absolute', width: "100%", height: "100%" }}>
                                {insuramceData?.insuranceFileName ?
                                    <div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'green', borderRadius: 3, overflow: 'hidden' }} />
                                    :
                                    <>{true && < div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'red', borderRadius: 3, overflow: 'hidden' }} />}</>
                                }
                            </div>
                            <div className='icon-clip'><Icon name="clip" /></div>
                            Прикрепить файл полиса клиента
                        </div>
                    </Upload>

                    {(data.ticketStage !== 10 && data.ticketStage !== 11 && data.ticketStage !== 13 && data.paid === false) &&
                        <Button
                            name="Отправить полис клиенту"
                            onClick={() => {
                                if (!isLoading) {
                                    onSaveClientInsurance();
                                }
                            }}
                        />
                    }

                </div>

            }
            {(data.ticketStage === 10 || data.ticketStage === 11 || data.ticketStage === 13) &&
                <>
                    <Button
                        name="Оплачено"
                        onClick={() => {
                            if (!isLoading) {
                                onPaidHandler(data.id);
                            }
                        }}
                    />

                    {data.ticketStage === 100 && <div>Оформление азвершено</div>}
                </>
            }
            {data.paid === true &&
                <div>Клиент получил и оплатил полис</div>
            }
        </div>
    )
}

const SubPageComponent = ({ pageId, data = null }) => {
    switch (pageId) {
        case 1:
            return <TicketData data={data} />
        case 2:
            return <TicketCommercialOffers data={data} />
        case 3:
            return <TicketDesignOptionAndPaymentOption data={data} />
        case 4:
            return <RegistrationOfDocuments data={data} />
        default:
            return <>Данный раздел еще в разработке</>
    };
};

const TicketPage = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const { item } = useSelector(store => store.tickets);

    const [currentPageId, setCurrenPageId] = useState(ticketPages[0].id);

    //получить подробные данные о тикете
    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchDictionaries())
            .then(() => {
                dispatch(fetchTicket(id))
                    .then(() => {
                        setIsLoading(false);
                    }
                    )
            }
            ).catch(() => {
                setIsLoading(false)
            });
    }, [id, dispatch]);

    return (
        <>
            <PageHeader
                name="Данные тикета"
            />
            <div className='ticket-page-selector'>
                {ticketPages.map((page) => {
                    return (
                        <div
                            key={page.id}
                            className={'sub-page-name ' + (currentPageId === page.id ? "active" : "")}
                            onClick={() => setCurrenPageId(page.id)}
                        >
                            {page.name}
                        </div>
                    );
                })}
            </div>

            {item && <SubPageComponent pageId={currentPageId} data={item} />}
        </>
    );
};

export default TicketPage;

//{isLoading && <Spinner />}