import React from 'react';

import { toCurrency } from '../../additional';

const TgInsuranseSmallData = ({ data, onClick = () => {} }) => {

    const { insuranceType, insuranceCompany, period, summ, cashBack, number } = data;
    //console.log(data);
    return (
        <div className="friend-line" onClick={onClick}>
            <div className='insurance-small-data-container'>
                <div className='insurance-small-info-line'>
                    <div>Тип страхования</div>
                    <div>{insuranceType}</div>
                </div>
                <div className='insurance-small-info-line'>
                    <div>Компания</div>
                    <div>{insuranceCompany}</div>
                </div>
                {number &&
                    <div className='insurance-small-info-line'>
                        <div>Номер</div>
                        <div>{number}</div>
                    </div>
                }
                <div className='insurance-small-info-line'>
                    <div>Период</div>
                    <div>{period}</div>
                </div>
                <div className='insurance-small-info-line'>
                    <div>Стоимость</div>
                    <div>{toCurrency(summ, 'RUB', 'Ru-ru')}</div>
                </div>
                {cashBack &&
                    <div className='insurance-small-info-line'>
                        <div>Размер кэшбэка</div>
                        <div>{toCurrency(cashBack, 'RUB', 'Ru-ru')}</div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TgInsuranseSmallData;