import React, { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter, Link, useLocation } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import { LoginPage } from './pages';
//import { Layout } from './components/Layout';

import { Layout } from './components';

import './custom.css';

export const filterInitialState = {
    show: false,
    search: null,
    filterValue: {
        userIds: [],
        statuses: [],
        startDate: null,
        endDate: null,
        requestTypeIds: []
    }
};

export const FilterContext = createContext({
    filter: {},
    setFilter: () => { }
});

const App = () => {
    const { pathname } = useLocation();
    const { isAuth } = useSelector(state => state.auth);
    const [filter, setFilter] = useState({ ...filterInitialState });

    //console.log(pathname.startsWith("/tg"), pathname);

    return (
        <>
            <FilterContext.Provider value={[filter, setFilter]}>
                {pathname.startsWith("/tg") ?
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                    :
                    <>
                        {!isAuth ?
                            <LoginPage />
                            :
                            <Layout>
                                <Routes>
                                    {AppRoutes.map((route, index) => {
                                        const { element, ...rest } = route;
                                        return <Route key={index} {...rest} element={element} />;
                                    })}
                                </Routes>
                            </Layout>

                        }
                    </>
                }
            </FilterContext.Provider>
        </>
    );
}

export default App;