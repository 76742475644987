import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete, MdEditDocument, MdToggleOff, MdToggleOn } from 'react-icons/md';
import ToggleSwitch from '../components/UI/Toggle';

import ModalDialog from '../components/Modal';
import { Icon, Spinner, StyledButton } from '../components/UI';

import { checkAuth } from '../store/authSlice';
//import LeftMenu from '../components/LeftMenu/LeftMenu';

const AdminPage = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [bankList, setBankList] = useState([]);
    const [insuranceTypesList, setInsuranceTypesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {

            try {
                setError(null);
                setIsLoading(true);

                const banks = await (
                    await fetch(
                        `api/dictionary/banks`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "69420",
                        }
                    }
                    )
                ).json();

                // set state when the data received
                setBankList(banks);

                const requestTypes = await (
                    await fetch(
                        `api/dictionary/requestTypes`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "69420",
                        }
                    }
                    )
                ).json();

                setInsuranceTypesList(requestTypes);

            } catch (e) {
                setError(e);
            };

            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const bankInitialState = {
        id: null,
        name: '',
        requestTypes: []
    };

    const onAddBankHandler = () => {
        setModalItem({ ...bankInitialState });
        setShowModal(true);
    };

    const noCancelHandler = () => {
        setModalItem(null);
        //setBankList(state => state.filter((bank) => bank.id !== null));
        setShowModal(false);
    };

    const onEditHandler = (id) => {
        let item = { ...bankList.find((item) => item.id === id) };
        item.requestTypes = [...item.requestTypes];

        setModalItem(item);
        setShowModal(true);
    };

    const onDeleteHandler = (id) => {
        const deleteData = async () => {

            await dispatch(checkAuth(user));
            //console.log(modalItem);
            try {
                setError(null);
                setIsLoading(true);

                const bank = await (
                    await fetch(`api/bank/${id}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user.accessToken}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                ).json();

                if (bank.status === "Успешно") {
                    setBankList(state => {
                        let newbankList = [...bankList.filter(item => item.id !== id)];
                        return newbankList;
                    });
                } else {
                    setError(bank);
                }

            } catch (e) {
                setError(e);
            };

            setIsLoading(false);
        };

        deleteData();
    };

    const onSaveHandler = () => {
        const bankId = null;

        const saveData = async () => {

            await dispatch(checkAuth(user));

            try {
                setError(null);
                setIsLoading(true);

                const updatedBank = await (
                    await fetch(`api/bank`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user.accessToken}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: JSON.stringify({ ...modalItem })
                    })
                ).json();

                setBankList(state => {
                    let findedBank = bankList.find((item) => item.id === modalItem.id);
                    let bank = null;

                    if (!findedBank) {
                        bank = { ...modalItem };
                        bank.id = updatedBank.id;
                    } else {
                        bank = { ...findedBank };
                        bank.requestTypes = [...modalItem.requestTypes];
                    }

                    let newbankList = [bank, ...bankList.filter(item => item.id !== modalItem.id)];

                    newbankList.sort(function (a, b) {
                        if (a.name > b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });

                    return newbankList;
                });

            } catch (e) {
                setError(e);
            };

            setShowModal(false);
            setIsLoading(false);
            setModalItem(null);
        };

        saveData();
    };

    const onChangeNameHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, name: value };
            return item;
        });
    };

    const onSetRequestTypeHandler = (requestTypeId) => {

        setModalItem(state => {
            let item = { ...state };

            if (state.requestTypes?.find((i) => i.requestTypeId === requestTypeId)) {
                item.requestTypes = [...state.requestTypes?.filter((i) => i.requestTypeId !== requestTypeId)];
            } else {
                item.requestTypes.push({ ...insuranceTypesList.find(i => i.requestTypeId === requestTypeId) });
            }

            return item;
        });
    };

    return (
        <>
            <ModalDialog
                title="Редактирование/Добавление банка"
                onSave={onSaveHandler}
                onCancel={noCancelHandler}
                showModal={showModal}
                isLoading={isLoading}
                className={"bank-modal"}
            >
                <div>
                    <div className='input-header'> <div className='name-bank'>Название банка:</div>
                        <Input defaultValue={modalItem?.name ?? ""} onChange={(element) => onChangeNameHandler(element.target.value)} />
                    </div>
                </div>
                <div className='type-wrapper-bank'>
                    <div className='wrapper-header-bank'>Виды страхования</div>
                    {insuranceTypesList.map((item, index) => {
                        const { requestTypeId, description } = item;
                        const selectedType = modalItem?.requestTypes?.find((i) => i.requestTypeId === requestTypeId);
                        return (
                            <div className='request-type-selector-bank' key={requestTypeId}>
                                <div>{description}</div>
                                {/* {modalItem?.requestTypes?.find((i) => i.requestTypeId === requestTypeId) ?
                                    <MdToggleOn size={28} color='#EC7236' onClick={() => onSetRequestTypeHandler(requestTypeId)} />
                                    :
                                    <MdToggleOff size={28} color='gray' onClick={() => onSetRequestTypeHandler(requestTypeId)} />
                                } */}
                                <ToggleSwitch
                                        value = {selectedType}
                                        onChange={() => onSetRequestTypeHandler(requestTypeId)}
                                        colorGreen={true}
                                />
                            </div>
                        );
                    })}
                </div>
            </ModalDialog>

            <div className='banks-container' >
                <div className='container-header'>
                    <div className='container-header-name'>Банки</div>
                    {/* {!isLoading && 
                    <div className='container-button'>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div> onClickHandler={onAddBankHandler} />
                    </div>} */}
                    {selectedItem ?
                    <>
                    <div className='container-btn'>
                        <StyledButton 
                            className={'btn-red'} 
                            onClickHandler={() => onDeleteHandler(selectedItem.id)} 
                            name= <div className='icon-text'><Icon name = 'delete'/>Удалить</div> 
                        /> 
                        <StyledButton onClickHandler={() => onEditHandler(selectedItem.id)} name= <div className='icon-text'><Icon name = 'pencil'/>Редактировать</div> />
                    </div>
                    </>
                    :
                    <>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div> onClickHandler={onAddBankHandler}/>
                    </>}
                </div>
                <div className='banks-table'>
                    {isLoading && !modalItem && <Spinner />}
                    <div className='banks-header'>
                        <div className='bank-id'>ID</div>
                        <div className='bank-name'>Название банка</div>
                        {/* <div className='actions'>Действия</div> */}
                    </div>
                    {bankList.length === 0 ?
                        <div className='no-table-data'>Банки еще не добавлены</div>
                        :
                        bankList.map((item, index) => {
                            return (
                                <BanksRow
                                    key={item.id}
                                    item={item}
                                    onDelete={onDeleteHandler}
                                    onEdit={onEditHandler}
                                    onSelectHandler={onSelectHandler}
                                    selectedItem={selectedItem}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};

const BanksRow = ({ item, onDelete, onEdit, selectedItem, onSelectHandler }) => {
    const { id} = item;
    return (
        <div className={"banks-row " + (selectedItem && selectedItem.id === id ? "selected" : "")}
             onClick={() => onSelectHandler(item)} 
             onDoubleClick={() => onEdit(id)}
             key={item.id}>
            <div className='bank-id'>{item.id}</div>
            <div className='bank-name'>
                <div className='name'>{item.name}</div>
                {item.requestTypes?.map((type) => {
                    return (
                        <div className='type' key={type.requestTypeId}>{type.description}</div>
                    );
                })}
            </div>
            {/* <div className='actions'>
                <MdEditDocument size={26} color='green' className='icon-action' onClick={() => onEdit(item.id)} />
                <MdDelete size={26} color='red' className='icon-action' onClick={() => onDelete(item.id)} />
            </div> */}
        </div>
    );
};

export default AdminPage;

