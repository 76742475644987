import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'reactstrap';

import { Spinner, StyledButton, Icon } from '../../components/UI';
import ModalDialog from '../../components/Modal';
import PageHeader from '../../components/PageHeader';

import { checkAuth } from '../../store/authSlice';

import Upload, { upload } from '../../components/Upload/Upload';

const RequestTypeListPage = () => {

    const uploadRef = useRef();

    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [requestTypesList, setRequestTypesList] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.requestTypeId === item.requestTypeId) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {

            try {
                setError(null);
                setIsLoading(true);

                const requestTypes = await (
                    await fetch(
                        `api/dictionary/requestTypes`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "69420",
                        }
                    }
                    )
                ).json();

                setRequestTypesList(requestTypes);

            } catch (e) {
                setError(e);
            };

            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const onChangeNameHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, description: value };
            return item;
        });
    };

    const onChangeFirstDescriptionHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, firstDescription: value };
            return item;
        });
    };

    const onChangeSecondDescriptionHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, secondDescription: value };
            return item;
        });
    };

    const onChangeLinkToInsuranceStatisticHandler = (value) => {
        setModalItem(state => {
            const item = { ...state, linkToInsuranceStatistic: value };
            return item;
        });
    };

    const onEditHandler = (itemId) => {
        //console.log(itemId);
        let item = { ...itemId };
        //console.log(item);
        setModalItem(item);
        setShowModal(true);
    };

    const noCancelHandler = () => {
        setModalItem(null);
        //setBankList(state => state.filter((bank) => bank.id !== null));
        setShowModal(false);
    };

    const onSaveHandler = () => {
        console.log(JSON.stringify({ ...modalItem }));
        const saveToServer = async (item) => {
            try {
                setError(null);
                setIsLoading(true);

                const requestType = await(
                    await fetch(
                        `api/additional/saveRequestTypeInfo`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "ngrok-skip-browser-warning": "69420",
                            },
                            body: JSON.stringify({ ...item }) 
                        }
                    )
                ).json();

                setRequestTypesList(state => {
                    let newState = [...state.filter(x => x.requestTypeId != requestType.requestTypeId)];
                    newState.push(requestType);
                    //let item = newState.find(x => x.requestTypeId === requestType.requestTypeId);
                    //item = { ...requestType };
                   
                    return newState;
                });

            } catch (e) {
                setError(e);
            };
        }
        saveToServer(modalItem)
            .then(() => {
                setShowModal(false);
                setModalItem(null);
                setIsLoading(false);
            })
            .catch(e => {
                console.error(e);
                setIsLoading(false);
                alert("Ошибка сохранения данных")
            });
    };

    useEffect(() => {
        console.log('requestTypeList', requestTypesList);
    }, [requestTypesList]);

    const fileTypeByRequestType = (requestType) => {
        console.log(requestType);
        switch (requestType) {
            case 0:
                return 14;
            case 1:
                return 13;
            case 2:
                return 15;
            case 3:
                return 16;
        };
    };

    const updateFileName = (modalItem, e) => {
        console.log(modalItem, e);
        setModalItem(state => {
            const item = { ...state, fileNameInsuranceDescription: e.fileName };
            return item;
        });
        setRequestTypesList(state => {
            let newState = [...state];
            let item = newState.find(x => x.requestTypeId === modalItem.requestTypeId);
            item.fileNameInsuranceDescription = e.fileName;

            return newState;
        });
    };

    return (
        <>
            <PageHeader
                name="Виды страхования"
            >
                {selectedItem &&
                    <>
                        <StyledButton name="Редактровать" onClickHandler={() => onEditHandler(selectedItem)} />
                    </>
                }
            </PageHeader>

            <ModalDialog
                title="Редактирование вида страхования"
                onSave={onSaveHandler}
                onCancel={noCancelHandler}
                showModal={showModal}
                isLoading={isLoading}
            >
                {/*{console.log(modalItem)}*/}
                <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                    <div className='input-header'>Наименование: <Input disabled defaultValue={modalItem?.description ?? ""} onChange={(element) => onChangeNameHandler(element.target.value)} /></div>
                    <div className='input-header'>Ссылка на статистику: <Input defaultValue={modalItem?.linkToInsuranceStatistic ?? ""} onChange={(element) => onChangeLinkToInsuranceStatisticHandler(element.target.value)} /></div>
                    <Upload
                        key={modalItem?.id}
                        ref={(element) => { uploadRef.current = element }}
                        onUpload={(e) => updateFileName(modalItem, e)} //setUrl
                        //fileType={console.log(modalItem?.requestTypeId)}
                        fileType={fileTypeByRequestType(modalItem?.requestTypeId)}
                        //ticketId={data.id}
                    >
                        <div
                            style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: "100%" }}
                            onClick={() => uploadRef.current.upload()}
                        >
                            <div style={{ position: 'absolute', width: "100%", height: "100%" }}>
                                {modalItem?.fileNameInsuranceDescription &&
                                    <div style={{ position: 'absolute', left: 1, top: 3, width: 6, height: 6, backgroundColor: 'green', borderRadius: 3, overflow: 'hidden' }} />
                                }
                            </div>
                            <div className='icon-clip'><Icon name="clip" /></div>

                            <div>Полезная информация о страховании</div>
                        </div>
                    </Upload>
                    <div>
                        <p><label htmlFor="fistDescription">Верхний комментарий:</label></p>
                        <textarea id="fistDescription" name="fistDescription" rows="4" cols="60" value={modalItem?.firstDescription ?? ""} onChange={(e) => onChangeFirstDescriptionHandler(e.target.value)} />
                    </div>
                    <div>
                        <p><label htmlFor="secondDescription">Нижний комментарий:</label></p>
                        <textarea id="secondDescription" name="secondDescription" rows="4" cols="60" value={modalItem?.secondDescription ?? ""} onChange={(e) => onChangeSecondDescriptionHandler(e.target.value)} />
                    </div>
                </div>
            </ModalDialog>

            <div className="ticket-table-container">
                <div className="ticket-table-header">
                    <div className='id'>ID</div>
                    <div className='type'>Вид страхования</div>
                    <div className='date-created'>Ссылка на статистику</div>
                    <div className='description'>Файл с информацией</div>
                </div>

                {requestTypesList.map(item => {
                    const { requestTypeId, description, linkToInsuranceStatistic, fileNameInsuranceDescription } = item;
                    return (
                        <div
                            className={"ticket-table-row " + (selectedItem && selectedItem.requestTypeId === requestTypeId ? "selected" : "")}
                            onClick={() => onSelectHandler(item)}
                            onDoubleClick={() => onEditHandler(item)}
                            key={requestTypeId}
                        >
                            <div className='id'>{requestTypeId}</div>
                            <div className='type'>{description}</div>
                            <div className='date-created'>{linkToInsuranceStatistic ? "Есть" : "Нет" }</div>
                            <div className='description'>{fileNameInsuranceDescription ? "Прикреплен" : "Нет"}</div>
                        </div>
                    );
                })}

            </div>
        </>
    );
};

export default RequestTypeListPage;