import React from 'react';
import TgHeader from '../../components/TgHeader';
import { requestTypeName, clientTicketStatus, ticketOfferStatusName, designOptionTypeName, paymentTypeName, fileNameByFileType, toCurrency } from '../../additional';
import { Icon, StyledButton, Button, CheckBox, RadioButton, Input } from '../../components/UI';
import Upload, { upload } from '../../components/Upload/Upload';

const NewPolis = () => {
    //const { tg } = useTelegram();
    //const [searchParams] = useSearchParams();
    //const clientId = searchParams.get("userId");
    //const requestType = searchParams.get("requestType");

    //const dataGjgfsFghe83gGjdW
    return (
        <>
            <div className='form-polis'>
                <TgHeader name={"Новый полис"} type={requestTypeName(1)} />
                <div className="new-polis-description">
                    <div className='name-text-polis'>
                        Выберите действие
                    </div>
                    <div className='text-polis'>Если у Вас есть ранее оформленный полис, вы можете создать заявку на новый полис просто прикрепив его кнопкой <b>Прикрепить файл</b>, после чего он будет обработан менеджером.</div>
                    <div className='text-polis-second'>Если вы хотите заполнить все данные самомтоятельно то выберите <b>Заполнить данные</b>.</div>
                    <div className='container-button-polis'>
                        <Button tgFormWhite={true} name=<div className='icon-text-polis'><Icon name = "clip"/>Прикрепить полис</div> onClick={() => { } } />
                        <Button tgFormBlue={true} name=<div className='icon-text-polis'><Icon name = 'arrow-right'/>Заполнить данные</div> onClick={() => { } } />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewPolis;