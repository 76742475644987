import React, { useState, useEffect } from 'react';
import { ticketOfferStatusName } from '../../additional';
import { StyledButton, Input, CheckBox, Icon } from '../../components/UI';
import { useSelector } from 'react-redux';
import { toCurrency } from '../../additional';

const IflOfferItemSaved = ({ insuranceItem, selectedOffer }) => {
    const { insurances } = useSelector(state => state.dictionaries)

    console.log("item: ", insuranceItem);

    const [currenOffer, setCurrentOffer] = useState({ insuranceItem });

    const isSelectedOffer = (offer) => {
        //console.log(offer);
        //console.log(offer.ticketId,
        //    offer.insuranceCompanyId,
        //    //offer.commercialOfferId,
        //    offer.offerId,
        //    offer.summ,
        //    offer.franchizeOfferId,
        //    offer.franchizeSumm);

        //console.log(selectedOffer.ticketId,
        //    selectedOffer.insuranceCompanyId,
        //    //selectedOffer.commercialOfferId,
        //    selectedOffer.offerId,
        //    selectedOffer.summ,
        //    selectedOffer.franchizeOfferId,
        //    (selectedOffer.franchizeSumm ?? null));

        //console.log(offer.ticketId === selectedOffer.ticketId,
        //    offer.insuranceCompanyId === selectedOffer.insuranceCompanyId,
        //    //offer.commercialOfferId === selectedOffer.commercialOfferId,
        //    offer.offerId === selectedOffer.offerId,
        //    offer.summ === selectedOffer.summ,
        //    offer.franchizeOfferId === selectedOffer.franchizeOfferId,
        //    offer.franchizeSumm === (selectedOffer.franchizeSumm ?? null));

        if (!selectedOffer || selectedOffer === null)
            return false;

        if (
            //offer.id === selectedInsurance.ticketId &&
            offer.ticketId === selectedOffer.ticketId &&
            offer.insuranceCompanyId === selectedOffer.insuranceCompanyId &&
            //offer.commercialOfferId === selectedOffer.commercialOfferId &&
            offer.offerId === selectedOffer.offerId &&
            offer.summ === selectedOffer.summ
            //offer.franchizeOfferId === selectedOffer.franchizeOfferId &&
            //offer.franchizeSumm === (selectedOffer.franchizeSumm ?? null)
        )
            return true;
        else
            return false;

    }

    return (
        <div className='saved-offer-item-container'>

            {insuranceItem?.iflInsuranceCompanyOffers && insuranceItem?.iflInsuranceCompanyOffers.map(offer => {


                //console.log(isSelectedOffer({
                //    //id: data.id,
                //    ticketId: insuranceItem.ticketId,
                //    insuranceCompanyId: offer.insuranceCompanyId,
                //    //commercialOfferId: offer.id,
                //    offerId: offer.id,
                //    summ: offer.summ === 0 ? null : offer.summ,//Если франшиза то null
                //}));

                const so = isSelectedOffer(insuranceItem ? {
                    //id: data.id,
                    ticketId: insuranceItem.ticketId,
                    insuranceCompanyId: offer.insuranceCompanyId,
                    //commercialOfferId: offer.id,
                    offerId: offer.id,
                    summ: offer.summ === 0 ? null : offer.summ,//Если франшиза то null
                } : false);

                return (
                    <div className={'company-name middle-ifl ' + (so === true ? 'selected' : '') + (offer.declined === true ? 'declined' : '')}>
                         {/* <div>  {so === true ? <div className='icon-container-offer'><Icon name = 'check'/></div> : <div className='red-icon-container'><Icon name = "x-close"/></div>} </div> */}
                         {so === true  && offer.declined === false ? <div className='icon-container-offer'><Icon name = 'check'/></div> : ""}
                         {so === false  && offer.declined === true ? <div className='red-icon-container'><Icon name = "x-close"/></div> : ""}
                        <div className="company-name-row">
                            {insurances.find(x => x.id === offer.insuranceCompanyId)?.name}
                            <div className='company-name-summ'>
                            {toCurrency(offer.summ, 'RUB', 'Ru-ru')}
                                <Icon name="chevron-up" />
                            </div>
                        </div>
                        <div className="saved-offer-header">
                            <div className='saved-offer-results'>
                                <div>Дата создания: {new Date(insuranceItem.offerDate).toLocaleString()}</div>
                                <div>Дата изменения статуса: {new Date(insuranceItem.statusChangeDate).toLocaleString()}</div>
                                <div>Статус оффера: {ticketOfferStatusName(insuranceItem.commercialOfferStatus)}</div>
                            </div>

                        </div>
                        <div className='container-comentary'>
                            <p><label htmlFor="fistDescription">Основной комментарий:</label></p>
                            <textarea className='comentary-text-area' id="fistDescription" name="fistDescription" rows="4" cols="60" defaultValue={insuranceItem.firstDescription} onChange={() => { }} />
                        </div>
                        <div className='ifl-offer-ifl'>
                            {offer.apartmentInsuranceAmount &&
                                <div className='offer-item-kasko'>
                                    <div className="full-kasko-header">Квартира</div>
                                    {offer.apartmentInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Конструктив  </div> 
                                            {toCurrency(offer.apartmentInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.apartmentInsuranceAmount.finishingSumm &&
                                        <div className="item-group-ifl">
                                            <div>Отделка </div> 
                                            {toCurrency(offer.apartmentInsuranceAmount.finishingSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.apartmentInsuranceAmount.movablePropertySumm &&
                                        <div className="item-group-ifl">
                                            <div>Движимое имущество </div> 
                                            {toCurrency(offer.apartmentInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                </div>
                            }
                            {offer.houseInsuranceAmount &&
                                <div className='offer-item-kasko'>
                                    <div className="full-kasko-header">Дом</div>
                                    {offer.houseInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Конструктив  </div> 
                                            {/* <div>{offer.houseInsuranceAmount.constructiveSumm}</div> */}
                                            {toCurrency(offer.houseInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.houseInsuranceAmount.finishingSumm &&
                                        <div className="item-group-ifl">
                                            <div>Отделка </div> 
                                            {toCurrency(offer.houseInsuranceAmount.finishingSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.houseInsuranceAmount.movablePropertySumm &&
                                        <div className="item-group-ifl">
                                            <div>Движимое имущество  </div> 
                                            {toCurrency(offer.houseInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                </div>
                            }
                            {offer.bathInsuranceAmount &&
                                <div className='offer-item-kasko'>
                                    <div className="full-kasko-header">Баня</div>
                                    {offer.bathInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Конструктив  </div> 
                                            {toCurrency(offer.bathInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.bathInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Движимое имущество </div> 
                                            {toCurrency(offer.bathInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                </div>
                            }
                            {offer.garageInsuranceAmount &&
                                <div className='offer-item-kasko'>
                                    <div className="full-kasko-header">Гараж</div>
                                    {offer.garageInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Конструктив  </div> 
                                            {toCurrency(offer.garageInsuranceAmount.constructiveSumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                    {offer.garageInsuranceAmount.constructiveSumm &&
                                        <div className="item-group-ifl">
                                            <div>Движимое имущество </div> 
                                            {toCurrency(offer.garageInsuranceAmount.movablePropertySumm, 'RUB', 'Ru-ru')}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className='container-comentary'>
                            <p><label htmlFor="secondDescription">Дополнительный комментарий:</label></p>
                            <textarea className='comentary-text-area' id="secondDescription" name="secondDescription" rows="4" cols="60" defaultValue={insuranceItem.secondDescription} />
                        </div>
                    </div>
                )
            })}

        </div>
    );
};

export default IflOfferItemSaved;