import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import PageHeader from '../../components/PageHeader';
import { RegionListHeader, RegionListItem, RegionItemModal } from './';
import { Spinner, StyledButton, Icon } from '../../components/UI';
import { fetchRegions, addEditRegion, deleteRegion } from '../../store/dictionarySlice';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';

const RegionsPage = () => {
    const dispatch = useDispatch();
    const { regions, isLoading, error } = useSelector(state => state.dictionaries);
    const [showModal, setShowModal] = useState(false);
    const [regionForEdit, setRegionForEdit] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemForDelete, setItemForDelete] = useState(null); //{entityId: 12}
    const [isAdmin, setIsAdmin] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (region) => {
        if (selectedItem && selectedItem === region) {
            setSelectedItem(null);
        } else {
            setSelectedItem(region);
        };
    };

    //useEffect(() => {
    //    //console.log(user);
    //    if (user) {
    //        const { accessToken } = user;
    //        const decoded = jwt_decode(accessToken);
    //        const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? 0 : 1;
    //        setIsAdmin(parseInt(role));
    //    }
    //}, [user]);


    useEffect(() => {
        dispatch(fetchRegions());
    }, []);

    const onAddRegionHandler = (region) => {
        setRegionForEdit(null);
        setShowModal(true);
    };

    const onShowDeleteModalHandler = (id) => {
        setItemForDelete({ entityName: regions.find(p => p.id === id).name, entityId: id })
        setShowDeleteModal(true);
    }

    const onDeleteHandler = (request) => {
        if (request?.answer === true)
            dispatch(deleteRegion(request?.entityId));
    };

    const onEditHandler = (regionId) => {
        const selectedRegion = regions.find(region => region.id === regionId);
        setRegionForEdit({ ...selectedRegion });
        setShowModal(true);
    };

    const onSaveChangesHandler = (regionItem) => {
        dispatch(addEditRegion(regionItem));
        setRegionForEdit(null);
        setSelectedItem(null);
    };

    return (
        <>
            <div className='banks-container'>
                <div className='container-header'>
                    <div className='container-header-name'>Справочник регионов</div>
                    {/* {!isLoading && <div className='container-button'><StyledButton name=<div className='plus-add-name '><Icon name = "plus"/>Добавить</div> onClickHandler={onAddUserHandler} /></div>} */}
                    {selectedItem ?
                    <>
                    <div className='container-btn'>
                        <StyledButton className={'btn-red'} name= <div className='icon-text'><Icon name = 'delete'/>Удалить</div> onClickHandler={() => onShowDeleteModalHandler(selectedItem)}/>
                        <StyledButton name= <div className='icon-text'><Icon name = 'pencil'/>Редактировать</div> onClickHandler={() => onEditHandler(selectedItem)} />
                        </div>
                    </>
                    
                    :
                    <>
                        <StyledButton name= <div className='plus-add-name'><Icon name = 'plus'/>Добавить</div>onClickHandler={onAddRegionHandler}/>
                    </>
                }
                </div>
                <div className='banks-table'>
                    {isLoading && <Spinner />}

                    <RegionListHeader />

                    {regions.length > 0 ?
                        regions.map(region => {
                            const { id } = region;

                            return (
                                <RegionListItem
                                    key={id}
                                    region={region}
                                    selectedItem={selectedItem}
                                    //setSelectedItem={setSelectedItem}
                                    onArchiveHandler={onShowDeleteModalHandler}
                                    onEditHandler={onEditHandler}
                                    //onClick={() => onSelectHandler(selectedItem)}
                                    onSelectHandler={onSelectHandler}
                                />
                            );
                        })
                        :
                        <div className='no-items'>Список пока пуст.</div>
                    }
                </div>
            </div>
            <RegionItemModal
                setShowModal={setShowModal}
                showModal={showModal}
                region={regionForEdit}
                onSaveChangesHandler={onSaveChangesHandler}
            />
            <ConfirmDeleteModal
                entityName={'регион "' + itemForDelete?.entityName + '"'}
                entityId={itemForDelete?.entityId}
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                onDeleteHandler={onDeleteHandler}
            />
        </>
    );
};

export default RegionsPage;

