import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useTelegram } from "../../hooks/useTelegram";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { requestTypeName, ticketStatus, requestTypeNameMenu, requstTypeIdByName } from '../../additional';

import { fetchTgUserTicketsWithFilter } from '../../store/ticketSlice';
import { fetchInsurancesDictionary, fetchRequestTypesDictionary } from '../../store/dictionarySlice';

import TgHeader from '../../components/TgHeader';

import TgInsuranseSmallData from './TgInsuranseSmallData';
import TgInsuranceRequestSmallData from './TgInsuranceRequestSmallData';


const MyInsurances = ({ insuranceType, status }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const tg_user_id = params?.id;
    const _requestTypeName = params?.type;
    const _requestTypeId = requstTypeIdByName(_requestTypeName);

    console.log("_requestTypeId: ", _requestTypeName, _requestTypeId);

    const location = useLocation();
    const previousPage = location.state?.previousPage;

    //console.log("previousPage ",previousPage);

    const { tg } = useTelegram();
    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();
    const { list } = useSelector(state => state.tickets);

    const { insurances, requestTypes } = useSelector(store => store.dictionaries);

    const myInsurances = insuranceType || insuranceType === 0 ? list.filter(insurance => insurance.requestTypeId === insuranceType) : list;
    
    //console.log(insuranceType ? true : false, insuranceType, myInsurances);
    const onClickBackButtonHandler = () => {
        //if (previousPage)
        //    navigate(previousPage);
        navigate(-1);
    };

    useEffect(() => {
        tg.ready();

        //if (insuranceType || insuranceType === 0) {

        //} else {
            if (previousPage) {
                tg.BackButton.show();

                tg.onEvent('backButtonClicked', onClickBackButtonHandler);
                return () => {
                    tg.offEvent('backButtonClicked')
                }
            }
        //}

    }, [tg, previousPage]);

    useEffect(() => {
        console.log("requestStatus: status ?? status: ", status, insuranceType);
        dispatch(fetchTgUserTicketsWithFilter({ tg_user_id, filter: { requestTypeId: _requestTypeId, requestStatus: status ?? status } }))
        dispatch(fetchInsurancesDictionary());
    }, []);

    const onClickTgInsurance = (ticketId) => {
        //navigate(`/tg/Profile/${tg_user_id}`);
        console.log(`/tg/Profile/MyInsurances/${tg_user_id}/`);
        navigate(`/tg/Profile/MyInsurances/${tg_user_id}/${ticketId}`, { state: { previousPage: `/tg/Profile/insurances/${tg_user_id}/` } });
    };

    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <TgHeader name={"Мои " + (status ? "полисы" : "заявки")} type={requestTypeName(insuranceType) } />
            <div className='friend-line'>
                <div>Кол-во {status ? "полисов" : "заявок"}</div>
                <div>{myInsurances?.length}</div>
            </div>
            {myInsurances?.map(insurance => {
                //console.log("insurance.isCompleted: ", insurance.isCompleted);
                //console.log(insurance);
                //const FormComponent = insurance.type === 1 ? <TgInsuranseSmallData /> : <TgInsuranceRequestSmallData />;
                if (insurance.paid === true) {
                    return (
                        <div className='small-data-tg'>
                        <TgInsuranseSmallData
                            data={{
                                insuranceType: requestTypeName(insurance?.requestTypeId),
                                insuranceCompany: insurances?.find(x => x.id === insurance?.selectedOffer?.insuranceCompanyId)?.name ?? "",
                                period: new Date(insurance?.insuranceDateStart).toLocaleDateString('ru-RU') + " - " + new Date(insurance?.insuranceDateEnd).toLocaleDateString('ru-RU'),
                                summ: insurance?.insuranceSumm,
                                number: insurance?.insuranceNumber,
                                fileName: insurance?.insuranceFileName
                            }}
                            key={insurance.id}
                            onClick={() => onClickTgInsurance(insurance.id)}
                        />
                        </div>
                    );
                } else {
                    return (
                        <TgInsuranceRequestSmallData data={insurance} key={insurance?.id} onClick={() => onClickTgInsurance(insurance?.id)} />
                    );
                }
            })}
        </div>
    );
};

export default MyInsurances;
