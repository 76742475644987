import React, { useState, useContext, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { StyledButton, Search } from '../UI';
import Filter from '../Filter';

import { ReactComponent as FilterIcon } from '../Filter/filter.svg';

import { FilterContext } from '../../App';

import './PageHeader.modules.css';

const PageHeader = ({ name, isLoading = false, showSearch, showFilter, isVisible = false, children = null, path = [] }) => {

    const [filter, setFilter] = useContext(FilterContext);
    const [hasFilter, setHasFilter] = useState(false);

    useEffect(() => {
        let isSet = false;
        
        Object.keys(filter.filterValue).every(key => {
            //console.log(Array.isArray(filter.filterValue[key]), filter.filterValue[key].length > 0, );
            if ((Array.isArray(filter.filterValue[key]) && filter.filterValue[key].length > 0) || (!Array.isArray(filter.filterValue[key]) && filter.filterValue[key] !== null)) {
                isSet = true;
                return false;
            };

            return true;
        });

        if (hasFilter !== isSet)
            setHasFilter(isSet);

    }, [filter]);
    
    const showHideFilterHandler = () => {
        setFilter(state => {
            return { ...state, show: !state.show }
        });
    };

    return (
        <div className='header-container'>
            <div className='header-first'>
                <div>
                    <div className="left-header">
                        <div className='page-name'>{name}</div>
                        {showSearch &&
                            <Search />
                        }
                        {showFilter &&
                            <div className={'filterButton ' + (filter.show ? 'expanded' : '')} onClick={showHideFilterHandler}>
                                <FilterIcon />
                                {hasFilter && <div className='filter-is-set' />}
                            </div>
                            //<StyledButton name="Фильтр" onClickHandler={showHideFilterHandler} />
                        }
                    </div>
                    <div className='sub-page-name'>
                        {path.length > 0 &&
                            path.map((item) => {
                                return (
                                    <div>
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='header-buttons'>
                    {children}
                    {isLoading &&
                        <Spinner
                            color="success"
                            size="sm"
                        />
                    }
                </div>
            </div>
            <div className='headerSecond'>
                {filter?.show && <Filter />}
            </div>       
        </div>
    );

    //return (
    //    <Row className="page-header">
    //        <Col sm={3} className="page-header-name">
    //            {name}
    //        </Col>
    //        <Col>
    //            {children}
    //        </Col>
    //        <Col sm={3} className="page-header-button">
    //            {isLoading &&
    //                <Spinner
    //                    color="success"
    //                    size="sm"
    //                />
    //            }
    //            {(onClickHandler && !isLoading && isVisible) && <StyledButton onClickHandler={onClickHandler}>Добавить</StyledButton>}
    //        </Col>
    //    </Row>
    //);
};

export default PageHeader;
