import TicketData from "./TicketData";
import TicketPage from "./TicketPage";
import TicketDataItem from './TicketDataItem';
import TicketCommercialOffers from './TicketCommercialOffers';
import KaskoOfferItem from './KaskoOfferItem';
import OsagoOfferItem from './OsagoOfferItem';
import IpotekaOfferItem from './IpotekaOfferItem';
import IflOfferItem from './IflOfferItem';
import InsuranceOfferItem from './InsuranceOfferItem';
/*import InsuranceOfferItemSaved from './InsuranceOfferItemSaved';
*/import KaskoOfferItemSaved from './KaskoOfferItemSaved';
import OsagoOfferItemSaved from './OsagoOfferItemSaved';
import IpotekaOfferItemSaved from './IpotekaOfferItemSaved';
import IflOfferItemSaved from './IflOfferItemSaved';
import Note from './Note';

export default TicketPage;

export {
    TicketData,
    TicketDataItem,
    TicketCommercialOffers,
    KaskoOfferItem,
    OsagoOfferItem,
    IpotekaOfferItem,
    IflOfferItem,
    InsuranceOfferItem,
/*    InsuranceOfferItemSaved,
*/    KaskoOfferItemSaved,
    OsagoOfferItemSaved,
    IpotekaOfferItemSaved,
    IflOfferItemSaved,
    Note
};